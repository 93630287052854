import React from 'react'
import { Spin } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import './style.css'

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

const BtnLoader = () => {
    return (
        <div>
            <Spin indicator={antIcon} />
        </div>
    )
}

export default BtnLoader