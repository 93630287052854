import React, { useState, useRef, useEffect } from "react";
import { Row, Col } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import "antd/dist/antd.css";
import { Input, Button, message, Checkbox, Menu, Dropdown } from "antd";
import { styles } from "../../styles";
import { CloseOutlined, DownOutlined } from "@ant-design/icons";

import { searchSkills, getConsultant, listJobStatuses, getS3Url } from "../../graphql/queries";
import {
    createConsultant as CreateConsultant,
    updateConsultant, createJobStatus, updateJobStatus, createUnverifiedUsers, updateUnverifiedUsers, createMessages
} from "../../graphql/mutations";
import { API, graphqlOperation, Auth, Storage } from "aws-amplify";
import history from "../../config/history";
import BtnLoader from "../BtnLoader"
import InputMask from 'react-input-mask';
import './style.css'

import axios from 'axios'
// import JobInfo from "./jobinfo";
import queryString from 'query-string'
/* global google */

const initialData = {
    name: "",
    email: "",
    phone: "",
    desiredPerHour: "",
    contractEndDate: "",
    lastProjectRandR: "",
    skills: [],
    currentRate: 0,
    owningCompany: "",
    willingRelocate: false,
    willingTravelUs: false,
    cvFileName: "notset",
    stringSkills: "",
    workAuthorization: "",
    allowSms: false,
    allowEmail: false,
    isApplyAsRecruiter: false,
    recruiterEmail: "",
    recruiterName: "",
    recruiterCompanyName: ""
};

const ProfileForm = ({ setRender, jobData, isWithoutLogin, submit }) => {
    const [candidateData, setCandidateData] = useState(initialData);
    const [searchedLocationText, setSearchedLctnText] = useState("");
    const [searchedSkill, setSearchSkill] = useState("");
    const [searchedSkillsResults, setsearchedSkillsResults] = useState([]);
    const [newSkills, setNewSkills] = useState();
    const [selectedFile, setSelectedFile] = useState(null);
    const [newlyAddedSkills, setNewlyAddedSkill] = useState([]);
    const [toUpdate, setToUpdate] = useState(true);
    const [locations, setLocations] = useState([]);
    const [isCaptchaChecked, setCaptchaChecked] = useState(false);
    const [isApplyDisabled, setApplyDisable] = useState(false)

    let fileInput = useRef(null);

    let autocomplete;

    useEffect(() => {
        if (!isWithoutLogin) {
            getCandidateData();
        }
        getLocation();
    }, []);

    const loadSessionData = async () => {
        const userData = await Auth.currentAuthenticatedUser({
            bypassCache: false,
        });
        return userData.attributes["email"];
    };

    const getLocation = async () => {
        localStorage.removeItem("locationsCandidate");
        autocomplete = new google.maps.places.Autocomplete(
            document.getElementById("autocompletecandidate"),
            { types: ["(regions)"] }
        );

        autocomplete.addListener("place_changed", handlePlaceChanged);
    };
    const handlePlaceChanged = () => {
        const locationData = autocomplete.getPlace().formatted_address;
        setSearchedLctnText(locationData);
        let currentLocations;
        if (localStorage.getItem("locationsCandidate")) {
            let locations = JSON.parse(localStorage.getItem("locationsCandidate"));
            localStorage.setItem(
                "locationsCandidate",
                JSON.stringify({ locations: [...locations.locations, locationData] })
            );
            currentLocations = locations.locations;
            // localStorage.removeItem("locations");
        } else {
            // console.log(1234)
            localStorage.setItem(
                "locationsCandidate",
                JSON.stringify({ locations: [locationData] })
            );
            currentLocations = [];
        }
        currentLocations.push(locationData);
        setLocations(currentLocations);
    };

    const searchLocation = async (e) => {
        const value = e.target.value;
        setSearchedLctnText(value);
    };

    const searchSkill = async (e) => {
        const value = e.target.value;
        setSearchSkill(value);
        if (value.trim()) {
            let skills;
            if (isWithoutLogin) {
                skills = await API.graphql({
                    query: searchSkills,
                    variables: {
                        filter: {
                            skillName: {
                                regexp: value + ".*",
                            },
                        },
                    },
                    authMode: 'AWS_IAM'
                });
            } else {
                skills = await API.graphql(
                    graphqlOperation(searchSkills, {
                        filter: {
                            skillName: {
                                regexp: value + ".*",
                            },
                        },
                    })
                );
            }
            const items = skills.data.searchSkillss.items;
            // console.log("items", items);
            if (items.length === 0) {
                // setsearchedSkillsResults([{ skillName: value }]);
                // setNewSkills(value);
            } else {
                setsearchedSkillsResults([...candidateData.skills, ...items]);
            }
        }
    };

    const onCheckEmail = async (email) => {
        if (isWithoutLogin && candidateData.email && !/^[a-zA-Z0-9.+-_]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(candidateData.email))
            return message.error("Candidate email must be valid");
    }

    const uploadResume = async (fileName, cvFileName) => {
        return new Promise(async (resolve, reject) => {
            try {
                Auth.currentSession().then(async (res) => {
                    console.log(`myAccessToken: res`, res.idToken.jwtToken)

                    const s3URL = window.location.host === ("www.contractjobs.tech" || "contractjobs.tech") ? "amplify-jobs-appf3a77939fb3641208c6c8bbd870d692app-test" : "amplify-jobs-appf3a77939fb3641208c6c8bbd870d692app-stest"
                    try {
                        const response = await axios.post('https://axzttk5ev1.execute-api.us-east-1.amazonaws.com/Prod/resume', JSON.stringify({
                            token: res.idToken.jwtToken, fileName, fileType: selectedFile.type, s3URL
                        }), {
                            headers: {
                                'x-api-key': 'Vm1qMlR8Y86jqM51XfCdU8SNFW1CiBYz8aExY78w'
                            }
                        })
                        console.log('response', response)
                        if (response.status === 200) {
                            const data = response.data
                            const r = await axios.put(data.url, selectedFile, {
                                headers: {
                                    'Content-Type': selectedFile.type,
                                },
                            });
                            console.log('s', r)
                            resolve(cvFileName)
                        }
                        else {
                            resolve(null)
                        }
                    }
                    catch (e) {

                    }
                })
            }
            catch (e) {
                reject("Could not create the candidate.")
            }
        })
    }

    const createConsultant = async () => {
        let email;
        if (!isWithoutLogin)
            email = await loadSessionData();

        if (isWithoutLogin && candidateData.email === "")
            return message.error("Candidate email can't be empty");
        if (isWithoutLogin && candidateData.email && !/^[a-zA-Z0-9.+-_]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(candidateData.email))
            return message.error("Candidate email must be valid");
        if (candidateData.name === "")
            return message.error("Candidate name can't be empty");
        if (candidateData.phone.length < 10)
            return message.error("Phone No. must be 10 digits long.");
        if (candidateData.desiredPerHour < 1 || candidateData.desiredPerHour > 250)
            return message.error("Desired rate should be between $1 and $250");
        if (candidateData.contractEndDate === "")
            return message.error("Please set a contract end Date");
        if (candidateData.skills.length === 0)
            return message.error("Please enter atleast one skill");
        if (candidateData.cvFileName === 'notset')
            return message.error("Please upload your CV");
        if (candidateData.workAuthorization === '')
            return message.error("Please select the work authorization");
        if (!isCaptchaChecked && isWithoutLogin)
            return message.error("Please check captcha");
        if (candidateData.isApplyAsRecruiter && !candidateData.recruiterEmail)
            return message.error("Recruiter email can't be empty");
        if (candidateData.isApplyAsRecruiter && candidateData.recruiterEmail && !/^[a-zA-Z0-9.+-_]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(candidateData.recruiterEmail))
            return message.error("Recruiter email must be valid");
        if (candidateData.isApplyAsRecruiter && !candidateData.recruiterName)
            return message.error("Recruiter name can't be empty");
        if (candidateData.isApplyAsRecruiter && !candidateData.recruiterCompanyName)
            return message.error("Recruiter company can't be empty");

        candidateData.stringSkills = candidateData.skills.toString();
        candidateData.desiredPerHour = parseInt(candidateData.desiredPerHour);
        candidateData.currentRate = parseInt(candidateData.currentRate);
        candidateData.locations = locations;
        candidateData.locationsString = locations.toString()
        candidateData.phone = candidateData.phone.replaceAll(" ", "")
        // console.log(candidateData);

        if (candidateData.locations.length === 0) {
            return message.error("Please enter atleast one location");
        }

        candidateData.id = email;
        setApplyDisable(true);
        try {
            const parsed = queryString.parse(window.location.search);
            if (isWithoutLogin) {
                let fileName;
                candidateData.cvFileName = selectedFile.name;
                candidateData.status = 'pending';

                candidateData.jobId = parsed.id;
                const data = await API.graphql({
                    query: createUnverifiedUsers,
                    variables: { input: candidateData },
                    authMode: 'AWS_IAM'
                });
                if (selectedFile) {
                    const s3URL = window.location.host === ("www.contractjobs.tech" || "contractjobs.tech") ? "amplify-jobs-appf3a77939fb3641208c6c8bbd870d692app-test" : "amplify-jobs-appf3a77939fb3641208c6c8bbd870d692app-stest"
                    fileName = data.data.createUnverifiedUsers.id + "/" + selectedFile.name;
                    const s3data = await API.graphql({
                        query: getS3Url,
                        variables: {
                            fileName, fileType: selectedFile.type, s3URL
                        },
                        authMode: 'AWS_IAM'
                    });
                    const r = await axios.put(s3data.data.getS3Url.s3URL, selectedFile, {
                        headers: {
                            'Content-Type': selectedFile.type
                        },
                    });
                    await API.graphql({
                        query: updateUnverifiedUsers,
                        variables: { input: { id: data.data.createUnverifiedUsers.id, status: 'unverified' } },
                        authMode: 'AWS_IAM'
                    });
                }
                // history.push('/jobdetails-success')
                window.gtag("event", "job_details_resume_submit", {
                    jobTitle: jobData.jobTitle,
                    candidateName: candidateData.name,
                    candidateEmail: candidateData.email
                })
                submit(candidateData.isApplyAsRecruiter);
                window.scroll(0, 0)
            }
            else if (toUpdate) {
                debugger
                let fileName, cvFileName;
                if (candidateData && candidateData.appliedCompany && candidateData.appliedCompany.length) {
                    candidateData.appliedCompany.push(jobData.companyName)
                } else {
                    candidateData.appliedCompany = [jobData.companyName]
                }

                if (candidateData && candidateData.appliedJob && candidateData.appliedJob.length) {
                    candidateData.appliedJob.push(parsed.id)
                } else {
                    candidateData.appliedJob = [parsed.id]
                }

                delete candidateData.isApplyAsRecruiter;
                delete candidateData.recruiterEmail;
                delete candidateData.recruiterName;
                delete candidateData.recruiterCompanyName;
                
                if (selectedFile) {
                    console.log('selectedFile', selectedFile)
                    fileName = email + "/" + selectedFile.name;
                    cvFileName = await uploadResume(fileName, fileName)
                }
                if (cvFileName) {
                    candidateData.cvFileName = cvFileName;
                }
                // if (selectedFile) {
                //     // console.log("selectedFile", selectedFile);
                //     fileName = email + "/" + selectedFile.name;
                //     await Storage.put(fileName, selectedFile, {
                //         contentType: "application/vnd.openxmlformats-officedocument.wordprocessing",
                //     });
                // }
                // candidateData.cvFileName = fileName;

                const data = await API.graphql(
                    graphqlOperation(updateConsultant, { input: candidateData })
                );

                if (data.data) {
                    if (fileInput && fileInput.value) {
                        fileInput.value = "";
                    }
                    setsearchedSkillsResults([]);
                    message.success("Profile saved.");
                    // setSearchedLctnText("");
                    // setSearchSkill("");
                    // setNewSkills([]);
                    // localStorage.removeItem("locationsCandidate");
                    // setCandidateData(initialData);
                    // setLocations([]);
                    applyJob()
                } else {
                    setApplyDisable(false);
                    message.error(data.errors[0].message);
                }
            } else {
                // if (selectedFile) {
                //     fileName = email + "/" + selectedFile.name;
                //     await Storage.put(fileName, selectedFile, {
                //         contentType: "application/vnd.openxmlformats-officedocument.wordprocessing",
                //     });
                // }

                // if (fileName) {
                //     candidateData.cvFileName = fileName;
                // }
                let fileName, cvFileName;
                delete candidateData.isApplyAsRecruiter;
                delete candidateData.recruiterEmail;
                delete candidateData.recruiterName;
                delete candidateData.recruiterCompanyName;
                if (selectedFile) {
                    console.log('selectedFile', selectedFile)
                    fileName = email + "/" + selectedFile.name;
                    cvFileName = await uploadResume(fileName, fileName)
                }
                if (cvFileName) {
                    candidateData.cvFileName = cvFileName;
                }

                const data = await API.graphql(
                    graphqlOperation(CreateConsultant, { input: candidateData })
                );
                // console.log("data", data);
                if (data.data) {



                    if (fileInput) {
                        if (fileInput.value) {
                            fileInput.value = "";
                        }
                    }
                    // setsearchedSkillsResults([]);
                    message.success("Profile saved.");
                    // setSearchedLctnText("");
                    // setSearchSkill("");
                    // setNewSkills([]);
                    // localStorage.removeItem("locationsCandidate");
                    // setCandidateData(initialData);
                    // setLocations([]);
                    applyJob()
                } else {
                    setApplyDisable(false);
                    message.error(data.errors[0].message);
                }
            }
        } catch (err) {
            setApplyDisable(false);
            console.log("error: ", err);
        }
    };


    const handleCheckbox = (e) => {
        setCandidateData({ ...candidateData, [e.target.id]: e.target.checked });
    };

    const addToSelectedSkill = (skill) => {
        // console.log('skill', skill)
        if (skill) {
            let currentSkills = candidateData.skills;
            const checkIfExists = currentSkills.filter((el) => el === skill);
            // console.log('checkIfExists', checkIfExists, 'skill', skill)
            if (checkIfExists.length === 0) {
                currentSkills.push(skill);
                setCandidateData({ ...candidateData, skills: currentSkills });
                if (newSkills === skill) {
                    const checkSkill = newlyAddedSkills.filter((data) => data === skill);
                    if (checkSkill.length === 0) {
                        setNewlyAddedSkill([...newlyAddedSkills, skill]);
                    }
                }
            }
        }
    };

    const removeSkill = (skill) => {
        let currentSkills = candidateData.skills;
        let updatedSkills = currentSkills.filter((el) => el !== skill);
        setCandidateData({ ...candidateData, skills: updatedSkills });
    };

    const removeLocation = (location) => {
        let currentLocations = candidateData.locations;
        if (localStorage.getItem("locationsCandidate")) {
            // console.log("data", localStorage.getItem("locationsCandidate"));
            let lsItems = JSON.parse(localStorage.getItem("locationsCandidate"));
            currentLocations = lsItems.locations;
        }
        let updatedLocations = currentLocations.filter((el) => el !== location);
        if (localStorage.getItem("locationsCandidate")) {
            localStorage.setItem(
                "locationsCandidate",
                JSON.stringify({ locations: updatedLocations })
            );
        }
        setLocations(updatedLocations);
    };

    const applyJob = async () => {
        try {
            const parsed = queryString.parse(window.location.search);
            if (parsed.id) {
                const email = await loadSessionData();
                const jobStatus = await API.graphql(graphqlOperation(listJobStatuses, {
                    filter: {
                        and: [{
                            candidateId: {
                                eq: email,
                            }
                        },
                        {
                            jobId: {
                                eq: parsed.id,
                            }
                        }]
                    },
                }))
                // console.log('jobStatus', jobStatus)
                setApplyDisable(false)
                if (jobStatus.data.listJobStatuses) {
                    if (jobStatus.data.listJobStatuses.items.length === 0) {
                        await setApplication(parsed.id, email)
                    }
                }
                else {
                    await setApplication(parsed.id, email)
                }
            }
        }
        catch (e) {
            setApplyDisable(false)
            message.error("Cannot apply for this job.");
        }
    }


    const setApplication = async (jobId, email) => {
        let today = new Date();
        let year = today.getFullYear();
        let status = 'Match';
        let reasons = [];
        try {
            const data = await API.graphql(graphqlOperation(createJobStatus, {
                input: {
                    jobId,
                    candidateId: email,
                    status,
                    reasons: reasons,
                    owningCompany: jobData.companyName,
                    applied: 'yes',
                    contacted: 'no'
                }
            }))
            // console.log('data12', data)
            if (data.data.createJobStatus) {
                message.success("You have successfully applied for this job.");
                setRender(true)
            }
        }
        catch (e) {

        }
    }


    const updateApplication = async (jobId, email, id) => {
        try {
            const data = await API.graphql(graphqlOperation(updateJobStatus, {
                input: {
                    id,
                    jobId,
                    candidateId: email,
                    status: "Applied",
                    reasons: [],
                    owningCompany: jobData.companyName
                }
            }))
            // console.log('data12', data)
            if (data.data.updateJobStatus) {
                message.success("You have successfully applied for this job.");
                setRender(true)
            }
        }
        catch (e) {

        }
    }

    const uploadCV = async (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        setCandidateData({ ...candidateData, cvFileName: file.name });
    };

    const onClick = (data) => {
        setCandidateData({ ...candidateData, workAuthorization: data.key });
    };
    const onChange = (e) => {
        setCandidateData({ ...candidateData, [e.target.name]: e.target.value });
    };

    const onChangeCaptcha = (e) => {
        setCaptchaChecked(true)
    }

    const getCandidateData = async () => {
        const email = await loadSessionData();
        const candidate = await API.graphql(
            graphqlOperation(getConsultant, { id: email })
        );
        // console.log('candidate.data.getConsultant', candidate.data.getConsultant)
        if (candidate.data.getConsultant) {
            setCandidateData({ ...candidate.data.getConsultant });
            setLocations(candidate.data.getConsultant.locations);
            localStorage.setItem(
                "locationsCandidate",
                JSON.stringify({ locations: [...candidate.data.getConsultant.locations] })
            );
        } else {
            setCandidateData({ ...candidateData, email });
            setToUpdate(false);
        }
    };

    const menu = (
        <Menu onClick={onClick}>
            <Menu.Item key="US Citizen">US Citizen</Menu.Item>
            <Menu.Item key="Permanent Resident">Permanent Resident</Menu.Item>
            <Menu.Item key="H1B">H1B</Menu.Item>
            <Menu.Item key="EAD">EAD</Menu.Item>
            <Menu.Item key="TN Permit">TN Permit</Menu.Item>
            <Menu.Item key="OPT/CPT">OPT/CPT</Menu.Item>
            <Menu.Item key="Other">Other</Menu.Item>
        </Menu>
    );

    return (
        <React.Fragment>

            <Col
                lg={{ span: 10 }}
                md={{ span: 10 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}

            >
                <div>
                    <h2 className="mb-3" >Apply For This Job</h2>
                </div>
                <div className="apply-info">

                    <div className=" cui-4">
                        <div className="">
                            {!isWithoutLogin && <div className="pace em-23">
                                <label className="title-name ">
                                    Candidate Email *
                                </label>
                                <span>{candidateData.email}</span>
                            </div>}
                            {isWithoutLogin && <div className="pace">
                                <label className="title-name ">
                                    Candidate Email *
                                </label>
                                <Input
                                    onChange={onChange}
                                    value={candidateData.email}
                                    placeholder="Candidate Email"
                                    name="email"
                                    onBlur={() => onCheckEmail(candidateData.email)}
                                    style={styles.input}
                                />
                            </div>}
                            <div className="pace">
                                <label className="title-name ">
                                    Name *
                                </label>
                                <Input
                                    onChange={onChange}
                                    value={candidateData.name}
                                    placeholder="Candidate Name"
                                    name="name"
                                    style={styles.input}
                                />
                            </div>

                            <div className="pace">
                                <label className="title-name ">
                                    Phone *
                                </label>
                                <InputMask
                                    mask='999 999 9999'
                                    value={candidateData.phone}
                                    placeholder="Consultant Phone"
                                    name="phone"
                                    className="mask-phone"
                                    onChange={onChange}>
                                </InputMask>
                                {/* <Input
                                    type="tel"
                                    onChange={onChange}
                                    value={candidateData.phone}
                                    placeholder="Consultant Phone"
                                    name="phone"
                                    mask="99/99/9999"
                                    style={styles.input}
                                /> */}
                            </div>
                        </div>
                        <div className="pace">

                            <label className="title-name ">
                                Desired $ Rate / Hr*
                            </label>
                            <div className="cons-desired">
                                <Input
                                    onChange={onChange}
                                    value={candidateData.desiredPerHour}
                                    placeholder="Candidate Desired $ Rate / Hr (Max)"
                                    name="desiredPerHour"
                                    style={styles.input}
                                    type="number"
                                />
                            </div>
                        </div>
                        <div className="pace">
                            <label className="title-name ">
                                Current Contract End Date:
                            </label>
                            <Input
                                onChange={onChange}
                                value={candidateData.contractEndDate}
                                name="contractEndDate"
                                style={styles.input}
                                type="date"
                            />
                        </div>

                        <div className="pace count">
                            <label className="title-name ">Skills *</label>
                            <div className="my-input">
                                <Input
                                    onKeyDown={(e) => {
                                        if (e.key === "Tab") {
                                            e.preventDefault();
                                            addToSelectedSkill(searchedSkill);
                                            setSearchSkill('');
                                            setTimeout(() => {
                                                setsearchedSkillsResults([]);
                                            }, 500);
                                        }
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            console.log(searchedSkill);
                                            addToSelectedSkill(searchedSkill);
                                            setSearchSkill('');
                                            setTimeout(() => {
                                                setsearchedSkillsResults([]);
                                            }, 500);
                                        }
                                    }}
                                    onChange={searchSkill}
                                    value={searchedSkill}
                                    placeholder="Skills"
                                    name="skills"
                                    style={styles.input}
                                    autoComplete="off"
                                    onBlur={() => {
                                        setTimeout(() => {
                                            setsearchedSkillsResults([]);
                                        }, 500);
                                        if (searchedSkill !== '') {
                                            addToSelectedSkill(searchedSkill);
                                            setSearchSkill('');
                                        }
                                    }}
                                />
                                {searchedSkillsResults.length > 0 && (
                                    <ul className="list">
                                        {searchedSkillsResults.map((skill, i) => {
                                            return skill.skillName !== "" && skill !== "custom" ? <li
                                                key={i}
                                                onClick={() =>
                                                    addToSelectedSkill(skill.skillName)
                                                }
                                            >
                                                {skill.skillName}
                                            </li>
                                                : null
                                        })}
                                    </ul>
                                )}
                                <div className="c-box">
                                    {candidateData.skills.map((skill, i) => (
                                        <span className="tag-cu" key={i}>
                                            {skill}
                                            <CloseOutlined
                                                style={{ cursor: "pointer", marginLeft: 5 }}
                                                onClick={() => removeSkill(skill)}
                                            />
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="pace count">
                            <label className="title-name ">Location *</label>
                            <div className="my-input">
                                <Input
                                    onChange={searchLocation}
                                    placeholder="Location"
                                    name="searchedLocationText"
                                    style={styles.input}
                                    value={searchedLocationText}
                                    id="autocompletecandidate"
                                />
                                <div className="c-box">
                                    {locations.map((location, i) => (
                                        <span className="tag-cu" key={i}>
                                            {location}
                                            <CloseOutlined
                                                style={{ cursor: "pointer", marginLeft: 5 }}
                                                onClick={() => removeLocation(location)}
                                            />
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <Checkbox
                                id="willingRelocate"
                                onChange={(e) => handleCheckbox(e)}
                                checked={candidateData.willingRelocate}
                            >
                                Willing to relocate
                            </Checkbox>
                            <Checkbox
                                id="willingTravelUs"
                                onChange={(e) => handleCheckbox(e)}
                                checked={candidateData.willingTravelUs}
                            >
                                Willing to travel within United States
                            </Checkbox>
                        </div>

                        <div className=" resume-33 pace ">
                            <label className="title-name">CV</label>

                            <label className="d5-name" htmlFor="f7e"></label>

                            <input
                                type="file"
                                // accept="application/vnd.openxmlformats-officedocument.wordprocessing"
                                onChange={(evt) => uploadCV(evt)}
                                ref={(ref) => (fileInput = ref)}
                            />

                        </div>
                        {/* <div className=" resume-33 pace ">
                            <label className="title-name">Current File</label>
                            <span>{candidateData.cvFileName ? candidateData.cvFileName.split('/')[1] : ''}</span>
                        </div> */}
                        {/* </Flags> */}
                        <div className="w-12 pace ">
                            <label className="title-name">
                                {" "}
                                Work Authorization
                            </label>
                            <span>Please select your type of work authorization</span>
                            <div className="wrk-45">
                                <Dropdown overlay={menu}>
                                    <a
                                        className="ant-dropdown-link"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        {candidateData.workAuthorization
                                            ? candidateData.workAuthorization
                                            : "Select Work Authorization"}
                                        <DownOutlined />
                                    </a>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="pre-45 pace">
                            <label className="title-name">Communication Preferences:</label>
                            <span className="prefer">
                                Please select your preferences
                            </span>
                            <div className="will">
                                <Checkbox id="allowSms" onChange={(e) => handleCheckbox(e)} checked={candidateData.allowSms}>Allow recruiters to SMS me
                                </Checkbox>
                                <Checkbox id="allowEmail" onChange={(e) => handleCheckbox(e)} checked={candidateData.allowEmail}>Allow recruiters to Email me
                                </Checkbox>
                            </div>
                        </div>
                        <div className="pre-45 pace">
                            <div className="will">

                                <Checkbox id="isApplyAsRecruiter" onChange={(e) => handleCheckbox(e)} checked={candidateData.isApplyAsRecruiter}>Applying as recruiter? Check here to provide your details for future opportunities
                                </Checkbox>
                            </div>
                        </div>
                        {candidateData.isApplyAsRecruiter && <React.Fragment>
                            <div className="pace">
                                <label className="title-name ">
                                    Recruiter Email
                                </label>
                                <Input
                                    onChange={onChange}
                                    value={candidateData.recruiterEmail}
                                    name="recruiterEmail"
                                    style={styles.input}
                                    type="email"
                                    placeholder="Recruiter Email"
                                />
                            </div>
                            <div className="pace">
                                <label className="title-name ">
                                    Recruiter Name
                                </label>
                                <Input
                                    onChange={onChange}
                                    value={candidateData.recruiterName}
                                    name="recruiterName"
                                    style={styles.input}
                                    type="text"
                                    placeholder="Recruiter Name"
                                />
                            </div>
                            <div className="pace">
                                <label className="title-name ">
                                    Recruiter Company
                                </label>
                                <Input
                                    onChange={onChange}
                                    value={candidateData.recruiterCompanyName}
                                    name="recruiterCompanyName"
                                    style={styles.input}
                                    type="text"
                                    placeholder="Recruiter Company"
                                />
                            </div>
                        </React.Fragment>}

                        {isWithoutLogin && <ReCAPTCHA
                            sitekey="6LfllkceAAAAAISjHNavFOKutR5POA1GmxMmAoL9"
                            onChange={onChangeCaptcha}
                        />}

                        <div style={styles.submit_algn_btn}>
                            {/* <Button className="submit-btn" type="primary"></Button> */}
                            <Button
                                className="concan-btn"
                                type="primary"
                                disabled={isApplyDisabled}
                                onClick={createConsultant}
                            >
                                {isApplyDisabled && <BtnLoader></BtnLoader>}
                                {!isApplyDisabled && <span>Submit</span>}
                            </Button>
                        </div>
                    </div>
                </div>
            </Col>
        </React.Fragment>
    );
};

export default ProfileForm;
