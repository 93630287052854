import React, { useState, useRef, useEffect } from "react";
import { Row, Col } from "antd";
import "antd/dist/antd.css";
import { Input, Button, message, Checkbox, Menu, Dropdown } from "antd";
import { styles } from "../../styles";
import { CloseOutlined } from "@ant-design/icons";
import { DownOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import Light from '../../assets/702814.svg'
import { searchSkills, getConsultant } from "../../graphql/queries";
import {
  createConsultant as CreateConsultant,
  updateConsultant, createConsultantLocation
} from "../../graphql/mutations";
import { API, graphqlOperation, Auth, Storage } from "aws-amplify";
import protectedRoute from "../../config/ProtectedRoute";
import "./style.css";
import history from "../../config/history";
import axios from 'axios'
import { geocodeByAddress } from "react-places-autocomplete";
/* global google */
import useDocumentTitle from "../../config/useDocumentTitle"

const initialData = {
  name: "",
  email: "",
  phone: "",
  desiredPerHour: "",
  contractEndDate: "",
  lastProjectRandR: "",
  skills: [],
  currentRate: 0,
  owningCompany: "CloudHires",
  willingRelocate: false,
  willingTravelUs: false,
  cvFileName: "notset",
  stringSkills: "",
  workAuthorization: "",
  allowSms: false,
  allowEmail: false
};

const EditProfile = () => {
  useDocumentTitle("Edit Profile")
  const [candidateData, setCandidateData] = useState(initialData);
  const [searchedLocationText, setSearchedLctnText] = useState("");
  const [searchedSkill, setSearchSkill] = useState("");
  const [searchedSkillsResults, setsearchedSkillsResults] = useState([]);
  const [newSkills, setNewSkills] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [newlyAddedSkills, setNewlyAddedSkill] = useState([]);
  const [toUpdate, setToUpdate] = useState(true);
  const [locations, setLocations] = useState([]);

  let fileInput = useRef(null);

  let autocomplete;

  useEffect(() => {
    getCandidateData();
    getLocation();
  }, []);

  const loadSessionData = async () => {
    const userData = await Auth.currentAuthenticatedUser({
      bypassCache: false,
    });
    return userData.attributes["email"];
  };

  const getLocation = async () => {
    localStorage.removeItem("locationsCandidate");
    autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocompletecandidate"),
      { types: ["(regions)"] }
    );

    autocomplete.addListener("place_changed", handlePlaceChanged);
  };
  const handlePlaceChanged = () => {
    const locationData = autocomplete.getPlace().formatted_address;
    setSearchedLctnText(locationData);
    let currentLocations;
    if (localStorage.getItem("locationsCandidate")) {
      let locations = JSON.parse(localStorage.getItem("locationsCandidate"));
      localStorage.setItem(
        "locationsCandidate",
        JSON.stringify({ locations: [...locations.locations, locationData] })
      );
      currentLocations = locations.locations;
      // localStorage.removeItem("locations");
    } else {
      // console.log(1234)
      localStorage.setItem(
        "locationsCandidate",
        JSON.stringify({ locations: [locationData] })
      );
      currentLocations = [];
    }
    currentLocations.push(locationData);
    setLocations(currentLocations);
  };

  const searchLocation = async (e) => {
    const value = e.target.value;
    setSearchedLctnText(value);
  };

  const searchSkill = async (e) => {

    const value = e.target.value;
    setSearchSkill(value);
    if (value.trim()) {
      const skills = await API.graphql(
        graphqlOperation(searchSkills, {
          filter: {
            skillName: {
              regexp: value.toLowerCase() + ".*",
            },
          },
        })
      );
      const items = skills.data.searchSkills.items;
      // console.log("items", items);
      if (items.length === 0) {
        // setsearchedSkillsResults([{ skillName: value }]);
        // setNewSkills(value);
      } else {
        setsearchedSkillsResults(items);
      }
    }
  };

  const createConsultant = async () => {

    console.log("Candidate data HERE", candidateData);

    const email = await loadSessionData();

    if(candidateData.name==="")
      return message.error("Candidate name can't be empty");
    if (candidateData.phone.length < 10)
      return message.error("Phone No. must be 10 digits long.");
    if (candidateData.desiredPerHour < 1 || candidateData.desiredPerHour > 250)
      return message.error("Desired rate should be between $1 and $250");
    if (candidateData.contractEndDate === "")
      return message.error("Please set a contract end Date");
    if(candidateData.skills.length === 0)
        return message.error("Please enter atleast one skill");
    if(candidateData.cvFileName === 'notset')
      return message.error("Please upload your CV");
    if(candidateData.workAuthorization === '')
      return message.error("Please select the work authorization");
    if(candidateData.owningCompany === '')
      return message.error("Please enter the staffing company");
    

    candidateData.stringSkills = candidateData.skills.toString();
    candidateData.desiredPerHour = parseInt(candidateData.desiredPerHour);
    candidateData.currentRate = parseInt(candidateData.currentRate);
    candidateData.locations = locations;
    candidateData.locationsString = locations.toString()
    candidateData.endDate = new Date(candidateData.contractEndDate).toISOString()
    // console.log(candidateData);

    if(candidateData.locations.length === 0)
    {
        return message.error("Please enter atleast one location");
    }

    candidateData.id = email;
    try {
      if (toUpdate) {
        let fileName;
        let cvFileName;
        if (selectedFile) {
          console.log('selectedFile', selectedFile)
          fileName = selectedFile.name;
          cvFileName = await uploadResume(fileName, email + "/" + selectedFile.name)
        }
        if (cvFileName) {
          candidateData.cvFileName = cvFileName;
        }

        const data = await API.graphql(
          graphqlOperation(updateConsultant, { input: candidateData })
        );

        if (data.data) {

          try {
            if (candidateData.cvFileName) {
              // await setResumeSkills(data.data.updateConsultant.cvFileName, email)
            }
          }
          catch (e) {
            console.log('e', e)
            message.error(e)
          }

          if (candidateData.locations) {
            try {
              const { locations } = candidateData;
              console.log('locations', locations);
              for (let i = 0; i < locations.length; i += 1) {
                const obj = {};
                const res = await geocodeByAddress(locations[i]);
                console.log(`response of ${locations[i]}`, res[0].geometry.location.lat());
                console.log(`response of ${locations[i]}`, res[0].geometry.location.lng());
                obj.lat =  res[0].geometry.location.lat();
                obj.lon =  res[0].geometry.location.lng();
                const input = {};
                // input.id = consultant.email
                const OwningCompany = await loadSessionData();
                input.email = candidateData.email
                input.location = obj;
                input.owningCompany = OwningCompany
                const response = await API.graphql(graphqlOperation(createConsultantLocation, { input }));
                console.log('response of location', response);
              }
            } catch (e) {
              console.log('error while mutating location', e);
            }
          }


          if (fileInput.value) {
            fileInput.value = "";
          }
          setsearchedSkillsResults([]);
          message.success("Candidate successfully edited.");
          setSearchedLctnText("");
          setSearchSkill("");
          setNewSkills([]);
          localStorage.removeItem("locationsCandidate");
          setCandidateData(initialData);
          setLocations([]);
          history.push("/profile");
        } else {
          message.error(data.errors[0].message);
        }
      } else {
        let fileName;
        let cvFileName;
        if (selectedFile) {
          fileName = selectedFile.name;
          cvFileName = await uploadResume(fileName, email + "/" + selectedFile.name)
        }

        if (cvFileName) {
          candidateData.cvFileName = cvFileName;
        }

        const data = await API.graphql(
          graphqlOperation(CreateConsultant, { input: candidateData })
        );
        // console.log("data", data);
        if (data.data) {

          try {
            if (candidateData.cvFileName) {
              // await setResumeSkills(data.data.createConsultant.cvFileName, email)
            }
          }
          catch (e) {
            console.log('e', e)
            message.error(e)
          }

          if (candidateData.locations) {
            try {
              const { locations } = candidateData;
              console.log('locations', locations);
              for (let i = 0; i < locations.length; i += 1) {
                const obj = {};
                const res = await geocodeByAddress(locations[i]);
                console.log(`response of ${locations[i]}`, res[0].geometry.location.lat());
                console.log(`response of ${locations[i]}`, res[0].geometry.location.lng());
                obj.lat =  res[0].geometry.location.lat();
                obj.lon =  res[0].geometry.location.lng();
                const input = {};
                // input.id = consultant.email
                const OwningCompany = await loadSessionData();
                input.email = candidateData.email
                input.location = obj;
                input.owningCompany = OwningCompany
                const response = await API.graphql(graphqlOperation(createConsultantLocation, { input }));
                console.log('response of location', response);
              }
            } catch (e) {
              console.log('error while mutating location', e);
            }
          }

          if (fileInput) {
            if (fileInput.value) {
              fileInput.value = "";
            }
          }
          setsearchedSkillsResults([]);
          message.success("Candidate successfully edited.");
          setSearchedLctnText("");
          setSearchSkill("");
          setNewSkills([]);
          localStorage.removeItem("locationsCandidate");
          setCandidateData(initialData);
          setLocations([]);
          history.push("/profile");
        } else {
          message.error(data.errors[0].message);
        }
      }
    } catch (err) {
      console.log("error: ", err);
    }
  };


  const setResumeSkills = async (resumeName, id) => {
    const s3URL = window.location.host === ("www.contractjobs.tech" || "contractjobs.tech") ? "amplify-jobs-appf3a77939fb3641208c6c8bbd870d692app-test" : "amplify-jobs-appf3a77939fb3641208c6c8bbd870d692app-stest"
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(`https://t9t0p3btn7.execute-api.us-east-1.amazonaws.com/Prod/resumeskills?b=${s3URL}&q=public/${resumeName}`, {
          headers: {
            'x-api-key': 'Vm1qMlR8Y86jqM51XfCdU8SNFW1CiBYz8aExY78w'
          }
        })
        // console.log('response', response)
        if (response.status === 200) {
          resolve(true)
          if (response.data.message !== "Skills not found") {
            // console.log('here1234')
            let skills = response.data.message.toString()
            const data = await API.graphql(graphqlOperation(updateConsultant, { input: { resumeSkills: skills, id } }))
            // console.log('data12 skills', data)
          }
        }
      }
      catch (e) {
        reject("Could not create the candidate.")
      }
    })
  }



  const uploadResume = async (fileName,cvFileName) => {
    return new Promise(async (resolve, reject) => {
      try {
        Auth.currentSession().then(async (res) => {
          console.log(`myAccessToken: res`, res.idToken.jwtToken)

          const s3URL = window.location.host === ("www.contractjobs.tech" || "contractjobs.tech") ? "amplify-jobs-appf3a77939fb3641208c6c8bbd870d692app-test" : "amplify-jobs-appf3a77939fb3641208c6c8bbd870d692app-stest"
          try {
            const response = await axios.post('https://axzttk5ev1.execute-api.us-east-1.amazonaws.com/Prod/resume', JSON.stringify({
              token: res.idToken.jwtToken, fileName, fileType: selectedFile.type, s3URL
            }), {
              headers: {
                'x-api-key': 'Vm1qMlR8Y86jqM51XfCdU8SNFW1CiBYz8aExY78w'
              }
            })
            console.log('response', response)
            if (response.status === 200) {
              const data = response.data
              const r = await axios.put(data.url, selectedFile, {
                headers: {
                  'Content-Type': selectedFile.type,
                },
              });
              console.log('s', r)
              resolve(cvFileName)
            }
            else {
              resolve(null)
            }
          }
          catch (e) {

          }
        })
      }
      catch (e) {
        reject("Could not create the candidate.")
      }
    })
  }

  const handleCheckbox = (e) => {
    setCandidateData({ ...candidateData, [e.target.id]: e.target.checked });
  };

  const addToSelectedSkill = (skill) => {
    // console.log('skill', skill)
    if (skill) {
      let currentSkills = candidateData.skills;
      const checkIfExists = currentSkills.filter((el) => el === skill);
      // console.log('checkIfExists', checkIfExists, 'skill', skill)
      if (checkIfExists.length === 0) {
        currentSkills.push(skill);
        setCandidateData({ ...candidateData, skills: currentSkills });
        if (newSkills === skill) {
          const checkSkill = newlyAddedSkills.filter((data) => data === skill);
          if (checkSkill.length === 0) {
            setNewlyAddedSkill([...newlyAddedSkills, skill]);
          }
        }
      }
    }
  };

  const removeSkill = (skill) => {
    let currentSkills = candidateData.skills;
    let updatedSkills = currentSkills.filter((el) => el !== skill);
    setCandidateData({ ...candidateData, skills: updatedSkills });
  };

  const removeLocation = (location) => {
    let currentLocations = candidateData.locations;
    if (localStorage.getItem("locationsCandidate")) {
      // console.log("data", localStorage.getItem("locationsCandidate"));
      let lsItems = JSON.parse(localStorage.getItem("locationsCandidate"));
      currentLocations = lsItems.locations;
    }
    let updatedLocations = currentLocations.filter((el) => el !== location);
    if (localStorage.getItem("locationsCandidate")) {
      localStorage.setItem(
        "locationsCandidate",
        JSON.stringify({ locations: updatedLocations })
      );
    }
    setLocations(updatedLocations);
  };

  const uploadCV = async (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setCandidateData({ ...candidateData, cvFileName: file.name });
  };

  const onClick = (data) => {
    setCandidateData({ ...candidateData, workAuthorization: data.key });
  };
  const onChange = (e) => {
    setCandidateData({ ...candidateData, [e.target.name]: e.target.value });
  };

  const getCandidateData = async () => {
    const email = await loadSessionData();
    const candidate = await API.graphql(
      graphqlOperation(getConsultant, { id: email })
    );
    // console.log('candidate.data.getConsultant', candidate.data.getConsultant)
    if (candidate.data.getConsultant) {
      setCandidateData({ ...candidate.data.getConsultant });
      setLocations(candidate.data.getConsultant.locations);
      localStorage.setItem(
        "locationsCandidate",
        JSON.stringify({ locations: [...candidate.data.getConsultant.locations] })
      );
    } else {
      setCandidateData({ ...candidateData, email });
      setToUpdate(false);
    }
  };

  const menu = (
    <Menu onClick={onClick}>
      <Menu.Item key="US Citizen">US Citizen</Menu.Item>
      <Menu.Item key="Permanent Resident">Permanent Resident</Menu.Item>
      <Menu.Item key="H1B">H1B</Menu.Item>
      <Menu.Item key="EAD">EAD</Menu.Item>
      <Menu.Item key="TN Permit">TN Permit</Menu.Item>
      <Menu.Item key="OPT/CPT">OPT/CPT</Menu.Item>
      <Menu.Item key="Other">Other</Menu.Item>
    </Menu>
  );

  return (
    <React.Fragment>
      <div className="contract-12 Postjob search-121">
        <div style={styles.container}>
          <div style={styles.edcontainer}>
            <div className="contractor">
              <Breadcrumb className="set-space" separator=">">
                <Breadcrumb.Item className="pointer" onClick={() => history.push('/')}>Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/editprofile" onClick={() => history.push('/editprofile')}>Edit Profile</Breadcrumb.Item>
              </Breadcrumb>
              <h2>My Profile</h2>
            </div>
            <Row gutter={[64, 64]} className="dh41">
              <Col
                lg={{ span: 16 }}
                md={{ span: 16 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="candidate-form">
                  <div className="pace em-23">
                    <label className="title-name ">
                      Candidate Email *
                    </label>
                    <span>{candidateData.email}</span>
                  </div>
                  <div className="pace">
                    <label className="title-name ">
                      Name *
                    </label>
                    <Input
                      onChange={onChange}
                      value={candidateData.name}
                      placeholder="Candidate Name"
                      name="name"
                      style={styles.input}
                    />
                  </div>



                  <div className="pace">
                    <label className="title-name ">
                      Phone *
                    </label>
                    <Input
                      onChange={onChange}
                      value={candidateData.phone}
                      placeholder="Consultant Phone"
                      name="phone"
                      style={styles.input}
                    />
                  </div>
                  <div className="pace">

                    <label className="title-name ">
                      Desired $ Rate / Hr*
                    </label>
                    <div className="cons-desired">
                      <Input
                        onChange={onChange}
                        value={candidateData.desiredPerHour}
                        placeholder="Candidate Desired $ Rate / Hr (Max)"
                        name="desiredPerHour"
                        style={styles.input}
                        type="number"
                      />
                    </div>
                  </div>
                  <div className="pace">
                    <label className="title-name ">
                      Current Contract End Date:
                    </label>
                    <Input
                      onChange={onChange}
                      value={candidateData.contractEndDate}
                      name="contractEndDate"
                      style={styles.input}
                      type="date"
                    />
                  </div>

                  <div className="pace count">
                    <label className="title-name ">Skills *</label>
                    <div className="my-input">
                      <Input
                        onFocus={
                          ()=>{console.log("on Fous")}
                        }
                        onChange={searchSkill}
                        onKeyDown={(e) => { 
                          if (e.key === "Tab") {
                            e.preventDefault();
                            addToSelectedSkill(searchedSkill);
                            setSearchSkill('');
                            setTimeout(() => {
                            setsearchedSkillsResults([]);
                            }, 500);
                          } 
                        }}
                        onKeyPress={(e)=>{
                          if(e.key==="Enter"){
                            console.log(searchedSkill);
                            addToSelectedSkill(searchedSkill);
                            setSearchSkill('');
                            setTimeout(() => {
                            setsearchedSkillsResults([]);
                            }, 500);
                          }
                        }}
                        value={searchedSkill}
                        placeholder="Skills"
                        name="skills"
                        style={styles.input}
                        autoComplete="off"
                        onBlur={() => {
                          setTimeout(() => {
                            setsearchedSkillsResults([]);
                          }, 500);
                          if(searchedSkill!==''){
                            addToSelectedSkill(searchedSkill);
                            setSearchSkill('');
                          }
                        }}
                      />
                      {searchedSkillsResults.length > 0 && (
                        <ul className="list">
                          {searchedSkillsResults.map((skill, i) => (
                            <li
                              key={i}
                              onClick={() =>{
                                addToSelectedSkill(skill.skillName)
                                setSearchSkill('')
                                }
                              }
                            >
                             {skill.skillName && skill.skillName}
                            </li>
                          ))}
                        </ul>
                      )}
                      <div className="c-box">
                        {(candidateData.skills || []).map((skill, i) => (
                          <span className="tag-cu" key={i}>
                            {skill}
                            <CloseOutlined
                              style={{ cursor: "pointer", marginLeft: 5 }}
                              onClick={() => removeSkill(skill)}
                            />
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="pace count">
                    <label className="title-name ">Location *</label>
                    <div className="my-input">
                      <Input
                        onChange={searchLocation}
                        placeholder="Location"
                        name="searchedLocationText"
                        style={styles.input}
                        value={searchedLocationText}
                        id="autocompletecandidate"
                      />
                      <div className="c-box">
                        {locations.map((location, i) => (
                          <span className="tag-cu" key={i}>
                            {location}
                            <CloseOutlined
                              style={{ cursor: "pointer", marginLeft: 5 }}
                              onClick={() => removeLocation(location)}
                            />
                          </span>
                        ))}
                      </div>
                    </div>
                    <Checkbox
                      id="willingRelocate"
                      onChange={(e) => handleCheckbox(e)}
                      checked={candidateData.willingRelocate}
                    >
                      Willing to relocate
                      </Checkbox>
                    <Checkbox
                      id="willingTravelUs"
                      onChange={(e) => handleCheckbox(e)}
                      checked={candidateData.willingTravelUs}
                    >
                      Willing to travel within United States
                      </Checkbox>
                  </div>

                  <div className=" resume-33 pace ">
                    <label className="title-name">CV</label>

                    <label className="d5-name" htmlFor="f7e"></label>

                    <input
                      type="file"
                      accept="application/vnd.openxmlformats-officedocument.wordprocessing"
                      onChange={(evt) => uploadCV(evt)}
                      ref={(ref) => (fileInput = ref)}
                    />

                  </div>
                  {/* <div className=" resume-33 pace ">
                    <label className="title-name">Current File</label>
                    <span>{candidateData.cvFileName ? candidateData.cvFileName.split('/')[1] : ''}</span>
                  </div> */}
                  {/* </Flags> */}
                  <div className="w-12 pace ">
                    <label className="title-name">
                      {" "}
                      Work Authorization
                    </label>
                    <span>Please select your type of work authorization</span>
                    <div className="wrk-45">
                      <Dropdown overlay={menu}>
                        <a
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          {candidateData.workAuthorization
                            ? candidateData.workAuthorization
                            : "Select Work Authorization"}
                          <DownOutlined />
                        </a>
                      </Dropdown>
                    </div>
                  </div>

                  <div className="pace">
                    <label className="title-name">
                      Staffing Company
                    </label>
                    <Input
                      onChange={onChange}
                      value={candidateData.owningCompany}
                      placeholder="CloudHires"
                      name="owningCompany"
                      style={styles.input}
                    />
                  </div>

                  <div className="pre-45 pace">
                    <label className="title-name">Communication Preferences:</label>
                    <span className="prefer">
                      Please select your preferences
                    </span>
                    <div className="will">

                      <Checkbox id="allowSms" onChange={(e) => handleCheckbox(e)} checked={candidateData.allowSms}>Allow recruiters to SMS me
          </Checkbox>
                      <Checkbox id="allowEmail" onChange={(e) => handleCheckbox(e)} checked={candidateData.allowEmail}>Allow recruiters to Email me
          </Checkbox>
                    </div>
                  </div>

                  <div style={styles.algn_btn}>
                    <Button className="submit-btn" type="primary"></Button>
                    <Button
                      className="concan-btn"
                      type="primary"
                      onClick={createConsultant}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </Col>

              <Col
                lg={{ span: 8 }}
                md={{ span: 8 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="post-description">
                  <div className="img-de">
                    <img src={Light} />
                    <h2>Here's a tip!</h2>
                  </div>
                  <p>
                    Recruiters will search for your profile based on skills so make sure
                    to include the skills you want to be working on in your next job.
                    These should also match up with the skills you can demonstrate experience
                    in your last couple of jobs or projects.
                    .{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default protectedRoute(EditProfile);
