import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Card, Modal, Select, Input, message } from 'antd'
import './style.css'
import { DoubleRightOutlined, DoubleLeftOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { Popconfirm, Breadcrumb } from 'antd';
import Footer from "../footer"
import { API, graphqlOperation, Auth } from 'aws-amplify'
import * as queries from '../../graphql/queries'
import { deleteJobStatus } from '../../graphql/mutations'
import ReactHtmlParser from 'react-html-parser';
import history from '../../config/history'
import Column from 'antd/lib/table/Column';
import FooterComponent from '../HomePage/Footer';
import { createMessages } from '../../graphql/mutations'
import useDocumentTitle from "../../config/useDocumentTitle"
const { Option } = Select;
const { TextArea } = Input;

const MyJobs = () => {
  useDocumentTitle("My Jobs")
  const [jobs, setJobs] = useState([])
  const [show, setShow] = useState(false)
  const [data, setData] = useState('')
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState();
  const [previousTokens, setPreviousTokens] = useState([]);
  const [counter, setCounter] = useState({ total: 0, counter: 0 });
  const [trigger, setTrigger] = useState(null)
  const [jobstatus, setJobStatus] = useState([])
  const [emailmessage, setEmailmessage] = useState(false)
  const [msgData, setMsgData] = useState({
    to: '',
    from: '',
    message: '',
    type: 'email',
    subject: '',
    phone: '',
    templateName: ''
  })

  // const [visibleMessageBox, setvisibleMessageBox] = useState(true)
  const [messagesDataAPI, setmessagesDataAPI] = useState([])
  const getJobs = async () => {
    try {
      const email = await loadSessionData()
      // console.log('email', email)
      const jobStatus = await API.graphql(graphqlOperation(queries.listJobStatuses, {
        filter: {
          and: [{
            candidateId: {
              eq: email
            },
            status: {
              ne: 'Not Hired'
            }
          }]
        },
      }))

      setJobStatus(jobStatus.data.listJobStatuses.items)
      // console.log('jobStatus', jobStatus.data.listJobStatuses)
      if (jobStatus.data.listJobStatuses) {
        const items = jobStatus.data.listJobStatuses.items
        // console.log('items', items)
        let orCondition = []
        items.forEach((item) => {
          if (items.status !== "Not Hired") {
            orCondition.push({ id: { eq: item.jobId } })
          }
        })
        let obj = {
          filter: { or: orCondition },
          limit: 6,
          nextToken
        };

        let jobsData = await API.graphql(
          graphqlOperation(queries.searchJobs, obj)
        );

        jobsData.data.searchJobs.items.forEach((el) => {
          const getStatus = jobStatus.data.listJobStatuses.items.filter(data => data.jobId === el.id)
          el.status = getStatus[0].status
        })


        if (jobsData.data.searchJobs.total > 6) {
          setNextNextToken(jobsData.data.searchJobs.nextToken);
        }
        setCounter({
          counter: counter.counter + jobsData.data.searchJobs.items.length,
          total: jobsData.data.searchJobs.total,
        });

        // console.log('jobsData', jobsData)
        if (jobsData.data.searchJobs) {
          setJobs(jobsData.data.searchJobs.items)
        }


        setTrigger(true)
      }

    }
    catch (e) {
      setTrigger(true)
    }
  }


  const loadSessionData = async () => {
    const userData = await Auth.currentAuthenticatedUser({
      bypassCache: false,
    });
    return userData.attributes["email"];
  };

  const updateTokens = () => {
    // console.log("current tokens", counter.counter, counter.total);
    if (counter.counter < counter.total) {
      setPreviousTokens([...previousTokens, nextToken]);
      setNextToken(nextNextToken);
      setNextNextToken(undefined);
      setTrigger(null)
    }
  };

  const prevToken = () => {
    let prevTokens = previousTokens;
    // console.log("previ", prevTokens);
    if (prevTokens.length) {
      let lastVal = prevTokens.pop();
      // setPreviousTokens(...prevTokens)
      setNextToken(lastVal);
      setCounter({
        ...counter,
        counter: counter.counter - (6 + jobs.length),
      });
      setTrigger(null)
    }
  };

  const handleModal = (desc) => {
    setData(desc)
    setShow(true)
  }

  const handleRemoveJob = async (id) => {
    const email = await loadSessionData()
    const getStatus = jobstatus.filter(el => el.jobId === id && el.candidateId === email)
    if (getStatus.length) {
      // console.log('getStatus', getStatus[0])
      const deletedJob = await API.graphql(graphqlOperation(deleteJobStatus, {
        input: { id: getStatus[0].id }
      }))
      // console.log('deletedJob', deletedJob)
      if (deletedJob.data.deleteJobStatus) {
        resetCounter()
        message.success('Applied job removed successfully.')
        setTrigger(null)
      }
    }
  }

  const resetCounter = () => {
    setNextNextToken(undefined);
    setNextToken(undefined);
    setPreviousTokens([]);
    setCounter({ counter: 0, total: 0 });
  };

  useEffect(() => {
    // getJobs()
    if (trigger === null) {
      getJobs();
    }
    getMessages()
  }, [trigger])


  const confirm = (e) => {
    handleRemoveJob(e)
  }

  const cancel = (e) => {

  }
  const handleOpenModal = async ({ to, phone, jobTitle }) => {
    const email = await loadSessionData()
    setMsgData({ ...msgData, to, from: email, phone, subject: jobTitle, templateName: jobTitle })
    setEmailmessage(true)
    
  }

  const handleMsgType = (data) => {
    setMsgData({ ...msgData, type: data })
  }

  const closeModal = () => {
    setEmailmessage(false)
    setMsgData({
      to: '',
      from: '',
      message: '',
      type: 'email',
      subject: ''
    })
  }
  let orCondition01 = []
  let obj01 = {
    filter: { or: orCondition01 },
    limit: 6,
    nextToken,
    filter: null,
    limit: 2000,
    nextToken: null
  };
  const handleSendMsg = async () => {
    try {
      // console.log('data', msgData)
      if (!msgData.type || msgData.type === "Select Message Type")
        return message.error('Select message type.')

      if (msgData.type === "email" && !msgData.subject)
        return message.error('Enter the subject.')

      if (!msgData.message)
        return message.error('Enter the message.')


      const savedData = await API.graphql(graphqlOperation(createMessages, { input: { ...msgData } }));
      // console.log('savedData', savedData)
      if (savedData.data.createMessages) {
        message.success('Message sent successfully!')
        setEmailmessage(false)
        getMessages()
        setMsgData({
          to: '',
          from: '',
          message: '',
          type: 'Select Message Type',
          subject: '',
          templateName: ''
        })
      }
    }
    catch (e) {
      console.log(e)
    }
  }
  const getMessages = async () => {
    let messageData = await API.graphql(
      graphqlOperation(queries.listMessages, obj01)
    );
    let messageBoxData = messageData.data.listMessages.items
    setmessagesDataAPI(messageBoxData)
    console.log(messagesDataAPI, "all messages of jobs")
  }
  return (
    <div>
      <React.Fragment>

        <div className="Postjob cus-33 joblist-34">
          <div className="container">
            <Row gutter={[32, 32]} style={{ flexFlow: "column" }}>

              <Breadcrumb className="set-space" separator=">" style={{ padding: "0px 15px" }}>
                <Breadcrumb.Item className="pointer" onClick={() => history.push('/searchjob')}> Jobs</Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => history.push('/joblist')}><span className="color-dark-blue">My Jobs</span></Breadcrumb.Item>
              </Breadcrumb>
              <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                <div style={{marginBottom:"10%"}}>

                  <p>Jobs that you have recently applied to</p>
                </div>
              </Col>
              {/* <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                <div>
                  <h4>My Jobs</h4>
                  <p>Jobs that you have recently applied to</p>
                </div>
              </Col>
              <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                <div>
                  <h4>My Jobs</h4>
                  <p>Jobs that you have recently applied to</p>
                </div>
              </Col> */}
            </Row>

            <Row gutter={[32, 32]} >


              {jobs.map((job, i) => {
                   var messageVisible = true
                   messagesDataAPI.map((message, index) => {
                     if(job.jobTitle === message.templateName) {
                       messageVisible = false
                       console.log("match at index", index)
                     }
                   })
                return <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }} key={i}>
                  <div className="designation dms">
                    <div className="candidate-22">
                      <h4>{job.jobTitle}</h4>
                      <span> ${job.minRate} - ${job.offeredRate}</span>
                    </div>
                      {
                        job.jobTitle.length<33 ?
                        <div className="space-br">
                          <br />
                        </div>:
                        null
                      }

                    {/* <div className="candidate-22">
                      <h5>{job.companyName}</h5>
                    </div> */}
                    <EnvironmentOutlined /> {job.locations.map((el, i) => <span key={i} className="state-33">{el}</span>)}
                    <p><span>Status: {job.status}</span></p>
                    {/* <p> <Button type="link" className="ered" style={{ padding: 0 }} onClick={() => handleModal(job.jobDescription)}>Job Description</Button></p> */}
                    <p> 
                      <div className="design-sp detail-66 dss-2 ">
                        {job.skills.map((skill, i) => <span key={i}>{skill}</span>)}
                        {/* {console.log("The length of the jobs skills are", job.skills.length)} */}
                      </div>
                      {job.skills.length<4? <br /> : null}
                    </p>

                    <div style={{display: "flex", justifyContent: "space-between"}}>

                    { (job.status === "Applied" || job.status === "Match") ? 
                      <Popconfirm className="doc-3"
                        title="Are you sure you want to withdraw your application?"
                        onConfirm={() => confirm(job.id)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >   
                      <Button type="link" className="ered" style={{ padding: 0 }}>Withdraw Application</Button></Popconfirm> : null
                    }

                    <Button type="link" className="ered" style={{ padding: 0 }} onClick={() => handleModal(job.jobDescription)}>Job Description</Button>
                    

                  </div>
                  {
                      messageVisible ?
                      <div style={{display: "flex", justifyContent: "space-between"}}>
                      <div></div>
                      <Button className="msgbtn" onClick={() => handleOpenModal({ to: job.recruiterId, phone: job.phone, jobTitle: job.jobTitle })}
                      style={{
                        borderRadius: "4px",
                        backgroundColor: "#074369",
                        color: "white",
                        fontWeight: "bold",
                        position: "relative",
                        right: "3%"
                      }}
                      >Message</Button>
                      </div>
                       :
                      null
                    }
                  </div>
                </Col>
              })}




              {/* 
              <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>


              </Col>
              <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>


              </Col>
 */}


            </Row>

            {
              jobs.length !== 0 && <div className="page-33 fitl-5 chgd">
                {/* <Pagination size="small" total={4} showSizeChanger /> */}

                <button className={`linkbtn ${previousTokens.length ? 'activepagination' : ''}`} onClick={prevToken}> <DoubleLeftOutlined /> Previous </button>
                <button className={`linkbtn ${counter.counter < counter.total ? 'activepagination' : ''}`} onClick={updateTokens}> Next <DoubleRightOutlined /></button>
              </div>
            }

          </div>

        </div>
        <Modal className="job-d"
          title="Job description"
          visible={show}
          onOk={() => { setShow(false) }}
          onCancel={() => { setShow(false) }}
          footer={null}
        >
          {ReactHtmlParser(data)}
        </Modal>

        <Modal
        visible={emailmessage}
        // onOk={this.handleOk}
        className={"email-34"}
        onCancel={() => closeModal()}
        onOk={() => handleSendMsg()}
        style={{fontWeight: "500"}}
      >

        <div className="">
          {/* <div className="form-control">
           
          </div> */}
          <Row >
            <Col span={12} >
              <Select
                className="form-control"
                showSearch
                style={{ width: 200 }}
                placeholder="Select"
                onChange={handleMsgType}
                value={msgData.type}
              >
                <Option value="email">Email</Option>
                {/* <Option value="message">Message</Option> */}

              </Select>
            </Col>
            <Col span={12} >
              {msgData.type === "email" ? <div className="form-control">
              <Input placeholder="Enter subject" defaultValue={`Question about my job application for ${msgData.subject}`} type="text" name="subject" onChange={(e) => setMsgData({ ...msgData, subject: e.target.value })} style={{fontWeight: "500"}}/>
              
                
              </div> : null}


            </Col>

            <Col span={24}>
              {/* <div className="form-control">
                <Input type="text" name="message" placeholder="Enter message" onChange={(e) => setMsgData({ ...msgData, message: e.target.value })} />
              </div> */}
              <div className="form-control">
                <TextArea rows={4} name="message" placeholder="Enter message" onChange={(e) => setMsgData({ ...msgData, message: e.target.value })} value={msgData.message} />
              </div>

            </Col>

          </Row>
        </div>
      </Modal>

        {/* <Footer /> */}
        <FooterComponent />
      </React.Fragment>
    </div>


  );

}

export default MyJobs