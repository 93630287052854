import React, {useState} from "react";
// import { Link } from "react-router-dom";
import { Menu, Button } from "antd";
import Icon, { MenuOutlined } from "@ant-design/icons";
import { Row, Col, Drawer } from 'antd';
import 'antd/dist/antd.css';
import { Auth } from "aws-amplify";
import './style.css'
import history from '../../../config/history'
import logo1 from '../../../assets/1.jpg'
import logo2 from '../../../assets/2.jpg'
// import { ReactComponent as LOGO } from './img/logo.svg';
const SignHeader = ({ setUser }) => {

  
    
    
  
            const [visible, setVisible] = useState(false);
            const showDrawer = () => {
              setVisible(true);
            };
            const onClose = () => {
              setVisible(false);
            }
        return (
                <div className="navbar">
                    <Row type="flex">
                        <Col xs={15} md={15} lg={15} xl={15}>
                            <div  onClick={() => history.push('/')}className="header-logo">
                                <img src={require("../../../assets/logo.svg")}/>
                            </div>
                        </Col>
                        <Col xs={0} sm={0} md={0} lg={3} xl={3} >
                        <a href="https://recruiter.contractjobs.tech/">
                            <span className="navbar-item">Recruiter</span>
                            </a>
                        </Col>
                        <Col xs={0} sm={0} md={0} lg={2} xl={2}>
                            <span className="navbar-item" onClick={() => setUser('auth')}>Login</span>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={0} xl={0}>
                            {/* <span className="navbar-item--active">Sign up</span> */}
                            <button className="navbar-btn" type="primary" onClick={showDrawer}>
                            <MenuOutlined style={{color: "#074369"}}/>
                            </button>
                        </Col>
                    </Row>
                    <Drawer
                    placement="right"
                    closable={false}
                    onClose={onClose}
                    visible={visible} >
                    <ul className="navbar-list">
                    <a href="https://recruiter.contractjobs.tech/">
                        <li>Recruiter</li>
                        </a>
                        <li onClick={() => setUser('auth')}>Login</li>
                    </ul>
                    </Drawer>
    
    </div>
    
  );
;

}
export default SignHeader
