import React from "react";
import { Row, Col, Button } from "antd";
import { styles } from "../../styles";
import './style.css'
import Group2 from '../../assets/Group 52.png'
import Group4 from '../../assets/Group 90.png'
import Coin from '../../assets/Frame.png'
import Coinmob from '../../assets/Frame-mob.png'
import Footer from "./Footer"
import history from '../../config/history'
import Header from "./Header";
import Features from './Features';
import ITC1 from './ITC1';
import ReasonSection from './ReasonSection';
import CustReviews from "./Reviews";
import Map from "./Map";
const HomePage = ({ setUser, authState, user }) => {

  const handleGetStarted = () => {
    if (authState === "signIn" && user !== "auth") {
      history.push('/searchjob')
    }
    else {
      setUser('auth')
    }
  }


  return (
    <>
     
          
            <Header handleGetStarted={handleGetStarted}/>
            <Features handleGetStarted={handleGetStarted}/>
            <ITC1 handleGetStarted={handleGetStarted}/>
            <ReasonSection handleGetStarted={handleGetStarted}/>
            <CustReviews handleGetStarted={handleGetStarted}/>
            <Map handleGetStarted={handleGetStarted}/>
      <Footer />
    </>

  );
};

export default HomePage;
