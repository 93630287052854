import React, { useState, useEffect } from 'react'
import { styles } from "../../styles";
import { Row, Col } from "antd";
import Footer from '../HomePage/Footer';
import queryString from 'query-string'
import { updateUnverifiedUsers } from '../../graphql/mutations';
import { searchJobss, searchConsultants } from '../../graphql/queries'
import { API } from "aws-amplify";
import Loader from "../Loader"
const EmailVerify = ({ setUser, setAuthUser }) => {

    const [isError, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        updateStatus();
    }, [])

    const setUserAuth = () => {
        setAuthUser('signup')
    }

    const updateStatus = async () => {
        setLoading(true);
        const parsed = queryString.parse(window.location.search);
        try {
            const data = await API.graphql({
                query: updateUnverifiedUsers,
                variables: { input: { id: parsed.id, status: 'verified' } },
                authMode: 'AWS_IAM'
            });
            setLoading(false);
            if (data && data.errors && data.errors.length > 0) {
                setError(true)
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    return (
        <div>
            {loading && <Loader />}
            {!loading && <div className=" job-det contract-12 Postjob search-121">
                <div style={styles.container}>
                    <div style={styles.edcontainer} className="d-flex justify-content-center">
                        <Row gutter={[64, 64]} className="dh41">
                            <Col>
                                <div className="jo-ti">
                                    {(!isError) && <p style={styles.success_div}>Thank you for verifying your email. Your job application has been submitted successfully. You can <a onClick={() => setUserAuth()}>create</a> an account to track your application status.</p>}
                                    {isError && <p style={styles.success_div}>Verification link has expired. Please reapply again.</p>}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>}
            {!loading && <Footer />}
        </div>
    )

}
export default EmailVerify