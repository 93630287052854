import React from 'react'

function Footer(){
    return (
        <footer className="footer">
                <img src={require("../../assets/logo.svg")}  alt="logo" className="footer-img"/>
                <p className="footer-text">ContractJobs.Tech. All Rights Reserved.</p>
               <a href="/privacyPolicy.htm" target="blank">

                <p className="footer-word" href="/privacyPolicy.htm" >
                Privacy Policy
                </p>
               </a>

        </footer>
    )
}

export default Footer;
