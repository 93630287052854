import React, { useEffect, useState } from "react";
import { Row, Col, Checkbox } from "antd";
import "./style.css";
import { message } from "antd";
import "./style.css";
import Footer from "../footer";
import axios from 'axios'
import queryString from 'query-string'


const Unsubscribe = () => {
  const [subData, setSubData] = useState({
    allowSms: '',
    allowEmail: ''
  })
  const [isUpdate, setIsUpdate] = useState(false)

  useEffect(() => {
    getUserData()
  }, [])

  const getUserData = async () => {
    try {
      const parsed = queryString.parse(window.location.search);
      if (parsed.email && parsed.hex) {
        const env = window.location.host === ("www.contractjobs.tech" || "contractjobs.tech") ? "prod" : "dev"

        const response = await axios.get(`https://dc2yfln7lg.execute-api.us-east-1.amazonaws.com/Stage/preferences?email=${parsed.email}&hex=${parsed.hex}&env=${env}`, {
          headers: {
            'x-api-key': 'Vm1qMlR8Y86jqM51XfCdU8SNFW1CiBYz8aExY78w'
          }
        })
        if (response.data.data.length) {
          console.log(response)
          setSubData({ ...response.data.data[0] })
        }
      }
    }
    catch (e) {

    }
  }

  const handleSubData = async () => {
    try {
      const parsed = queryString.parse(window.location.search);
      if (parsed.email && parsed.hex) {
        const env = window.location.host === ("www.contractjobs.tech" || "contractjobs.tech") ? "prod" : "dev"

        const response = await axios.post('https://dc2yfln7lg.execute-api.us-east-1.amazonaws.com/Stage/preferences', {
          ...subData,
          email: parsed.email,
          hex: parsed.hex,
          env
        }, {
          headers: {
            'x-api-key': 'Vm1qMlR8Y86jqM51XfCdU8SNFW1CiBYz8aExY78w'
          }
        })
        console.log('response', response)
        message.success('Subscribtions Successfully updated.')
        setIsUpdate(true);
      }
    }
    catch (e) {

    }
  }

  const handleCheckbox = (e) => {
    setSubData({ ...subData, [e.target.id]: e.target.checked })
  }

  return (
    <React.Fragment>
      <div className="Postjob cus-33 jop-5">
        <div className="unsubscribe">
          <div className="container">
            {!isUpdate && <Row>
              <Col span={24}>
              </Col>

              <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}  >

                <div className="subscription">
                  <h2>Subscription</h2>
                  <div className="subs-check">
                    <Checkbox id="allowSms" onChange={(e) => handleCheckbox(e)} checked={subData.allowSms}>Subscribe to SMS
                    </Checkbox>
                    <Checkbox id="allowEmail" onChange={(e) => handleCheckbox(e)} checked={subData.allowEmail}>Subscribe to Email
                    </Checkbox>

                  </div>

                </div>
                <button className="button-des" onClick={handleSubData}>Update</button>

              </Col>
            </Row>}
            {isUpdate && <Row>
              <Col span={24}>
                <span>You have been unsubscribed from all our notifications</span>
              </Col>
            </Row>}
          </div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
};
export default Unsubscribe;


