import React, { useState, useEffect } from "react";
import { Row, Col, Button, Card, Modal, Select, Input, message } from "antd";
import "./style.css";
import { DoubleRightOutlined, DoubleLeftOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { Popconfirm, Breadcrumb } from "antd";
import Footer from "../footer";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { listJobStatuses, getJobs, listUnverifiedUserss, searchJobs } from "../../graphql/queries";
import * as queries from "../../graphql/queries";
import { deleteJobStatus } from "../../graphql/mutations";
import ReactHtmlParser from "react-html-parser";
import history from "../../config/history";
import Column from "antd/lib/table/Column";
import FooterComponent from "../HomePage/Footer";
import { createMessages } from "../../graphql/mutations";

const { Option } = Select;
const { TextArea } = Input;

const Joblist = () => {
  const [jobs, setJobs] = useState([]);
  const [show, setShow] = useState(false);
  const [data, setData] = useState("");
  const [trigger, setTrigger] = useState(null);
  const [nextToken, setNextToken] = useState(undefined);
  const [isNext, setIsNext] = useState(false);

  const getJobData = async () => {
    const filter = { and: [{ status: { ne: "Archive" } }] };
    const userData = await Auth.currentUserInfo();
    const variablesObj = {
      filter,
      limit: 100,
      sort: {
        direction: "desc",
        field: "updatedAt",
      },
    };
    if (nextToken) {
      variablesObj.nextToken = nextToken;
    }
    const requestObj = {
      query: searchJobs,
      variables: variablesObj
    }
    if (!userData) {
      requestObj.authMode = 'AWS_IAM'
    } 
    let jobsData = await API.graphql(requestObj);
    setJobs([...jobs, ...jobsData.data.searchJobs.items]);
    if (jobsData.data.searchJobs.nextToken) {
      if (jobsData.data.searchJobs.total < 100) {
        setIsNext(false);
      } else {
        setIsNext(true);
      }
      setNextToken(jobsData.data.searchJobs.nextToken);
    } else {
      setIsNext(false);
      setNextToken("");
    }

  };

  const handleModal = (desc) => {
    setData(desc);
    setShow(true);
  };

  useEffect(() => {
    getJobData();
  }, []);

  const goToDetails = (id) => {
    history.push('/jobdetails?id=' + id)
  }

  return (
    <div>
      <React.Fragment>
        <div className="Postjob cus-33 joblist-34">
          <div className="container">
            <Row gutter={[32, 32]}>
              {jobs.map((job, i) => {
                return (
                  <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }} key={i}>
                    <div className="designation dms">
                      <div className="candidate-22">
                        <h4>{job.jobTitle}</h4>
                        <span>
                          {" "}
                          ${job.minRate} - ${job.offeredRate}
                        </span>
                      </div>
                      {(job.jobTitle || "").length < 33 ? (
                        <div className="space-br">
                          <br />
                        </div>
                      ) : null}
                      <EnvironmentOutlined />{" "}
                      {(job.locations || []).map((el, i) => (
                        <span key={i} className="state-33">
                          {el}
                        </span>
                      ))}
                      <p>
                        <div className="design-sp detail-66 dss-2 ">
                          {(job.skills || []).map((skill, i) => (
                            <span key={i}>{skill}</span>
                          ))}
                        </div>
                        {(job.skills || []).length < 4 ? <br /> : null}
                      </p>
                      <div style={{ display: "flex", gap:'10px', justifyContent: 'flex-end' }}>
                        <Button type="link" className="ered" style={{ padding: 0 }} onClick={() => handleModal(job.jobDescription)}>
                          Job Description
                        </Button>
                        <Button type="link" className="ered" style={{ padding: 0 }} onClick={() => goToDetails(job.id)}>
                          Apply Now
                        </Button>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
            {((nextToken && isNext)) && (
                <div className="load-more-wrapper">
                  <div className="massage-load-more pointer " onClick={() => getJobData()}>
                    Load More{" "}
                  </div>
                </div>
              )}  
            
          </div>
        </div>
        <Modal
          className="job-d"
          title="Job description"
          visible={show}
          onOk={() => {
            setShow(false);
          }}
          onCancel={() => {
            setShow(false);
          }}
          footer={null}
        >
          {ReactHtmlParser(data)}
        </Modal>

        {/* <Footer /> */}
        <FooterComponent />
      </React.Fragment>
    </div>
  );
};

export default Joblist;
