import React, { useEffect, useState } from "react";
import logo from "./assets/1.jpg";
import Amplify, { Auth, API, graphqlOperation, Hub } from "aws-amplify";
import { message } from "antd";
import {
  AmplifyAuthenticator,
  AmplifySignUp,
  AmplifySignIn,
  AmplifyConfirmSignUp,
  AmplifyForgotPassword
} from "@aws-amplify/ui-react";
import Router from "./config/router";
import awsconfig from "./aws-exports";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import history from "./config/history";
import { getConsultant, listJobStatuses } from "./graphql/queries";
import { updateJobStatus, createJobStatus } from "./graphql/mutations"
import HomePage from './components/HomePage'
import Header from './components/HomePage/header/index';
import JobDetails from "./components/JobDetails";
import JobDetailsSuccess from "./components/JobDetailsSuccess";
import EmailVerify from "./components/EmailVerify"


const aws_appsync_graphqlEndpoint = window.location.host === ("www.contractjobs.tech" || "contractjobs.tech") ? "https://26wkutyfvred7jiyacefh4m6fi.appsync-api.us-east-1.amazonaws.com/graphql" : "https://pf74b2trtfapnb24c45xtexgve.appsync-api.us-east-1.amazonaws.com/graphql"
const s3URL = window.location.host === ("www.contractjobs.tech" || "contractjobs.tech") ? "amplify-jobs-appf3a77939fb3641208c6c8bbd870d692app-test" : "amplify-jobs-appf3a77939fb3641208c6c8bbd870d692app-stest"
const myAppConfig = {
  ...awsconfig,
  aws_appsync_graphqlEndpoint,
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_user_files_s3_bucket: s3URL,
  aws_user_files_s3_bucket_region: "us-east-1",
};
// amplify-jobs-appf3a77939fb3641208c6c8bbd870d692app-test
Amplify.configure(myAppConfig);


function App() {
  const [initialAuthState, setInitialAuthState] = React.useState("signin");
  const [authState, setAuthState] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [profileSet, setProfileSet] = useState(null);
  const urlPath = history.location.pathname + history.location.search;
  useEffect(() => {
    if(history.location.pathname==="/jobdetails"){
      localStorage.setItem('jobdetails-login', true);
    }
    checkReload();
    Hub.listen('auth', (data) => {
      const { payload } = data;
      const eventName = payload.event
      if (eventName === "signIn") {
        getCandidateData();
        setUser(payload.data);
        setAuthState(eventName)
        const myAppConfig = {
          ...awsconfig,
          graphql_headers: async () => {
            const currentSession = await Auth.currentSession();
            return { Authorization: currentSession.getIdToken().getJwtToken() };
          }
        };
        Amplify.configure(myAppConfig);
      }
      else if (eventName === "signOut") {
        setUser('')
        setAuthState(eventName)
      }
    })
  }, []);

  const setAuthUser = (state) => {
    if (state) {
      setInitialAuthState(state);
    }
    setUser('auth')
  }

  const checkReload = async () => {
    const userData = await Auth.currentUserInfo();
    if (!userData) {
      console.log(window.location.pathname)
      if (window.location.pathname !== "/" && window.location.pathname !== "/subscription-update" && window.location.pathname !== "/jobdetails" && window.location.pathname !== "/jobdetails-success" &&  window.location.pathname !== "/email-verify" &&  window.location.pathname !== "/jobs") {
        setUser('auth')
      }
      else if (window.location.pathname !== "/subscription-update") {
        setUser('')
      } else {
        setUser(false)
        setAuthState(false)
      }
    } else {
      setUser(userData)
      setAuthState("signIn")
      const myAppConfig = {
        ...awsconfig,
        graphql_headers: async () => {
          const currentSession = await Auth.currentSession();
          return { Authorization: currentSession.getIdToken().getJwtToken() };
        }
      };
      Amplify.configure(myAppConfig);
    }
  }

  const getCandidateData = async () => {
    const email = await loadSessionData();
    const candidateData = await API.graphql(
      graphqlOperation(getConsultant, { id: email })
    );
    let data = localStorage.getItem('candidateData');
    if (data) {
      data = JSON.parse(data);
      await updateJobs(data.candidateData, data.jobData)
      localStorage.removeItem('candidateData')
    }
    if (candidateData.data.getConsultant) {
      if (!localStorage.getItem("counter") && window.location.pathname === "/") {
        localStorage.setItem("counter", 1);
        history.push("/searchjob");
      }
      setProfileSet(true);
    } 
    else if(localStorage.getItem('jobdetails-login')){
      history.push(urlPath);
      localStorage.removeItem('jobdetails-login');
    } else {
      setProfileSet(false);
      history.push("/editprofile");
    }
  };

  const updateJobs = async (candidateData, jobData) => {
    const email = await loadSessionData()
    const jobStatus = await API.graphql(graphqlOperation(listJobStatuses, {
      filter: {
        and: [{
          candidateId: {
            eq: email
          },
          jobId: {
            eq: jobData.id
          },
          status: {
            ne: 'Not Hired'
          }
        }]
      },
    }))
    if (jobStatus.data.listJobStatuses) {
      if (jobStatus.data.listJobStatuses.items.length) {
          const item = jobStatus.data.listJobStatuses.items[0]
          if (item.status === 'Contacted') {
            await updateApplication(jobData.id, email, item.id, jobData)
          } else {
            await updateApplication(jobData.id, email, item.id, jobData)
          }
      } else {
          await setApplication(jobData.id, email, jobData)
      }
    } else {
        await setApplication(jobData.id, email, jobData)
    }
  }

  const updateApplication = async (jobId, email, id, jobData) => {
    try {
        const data = await API.graphql(graphqlOperation(updateJobStatus, {
            input: {
                id,
                jobId,
                candidateId: email,
                status: "Applied",
                reasons: [],
                owningCompany: jobData.companyName
            }
        }))
        if (data.data.updateJobStatus) {
            message.success("You have successfully applied for this job.");
        }
    }
    catch (e) {}
  }

  const setApplication = async (jobId, email, jobData) => {
    try {
        const data = await API.graphql(graphqlOperation(createJobStatus, {
            input: {
                jobId,
                candidateId: email,
                status: "Applied",
                reasons: [],
                owningCompany: jobData.companyName
            }
        }))
        if (data.data.createJobStatus) {
            message.success("You have successfully applied for this job.");
        }
    }
    catch (e) {}
  }



  const loadSessionData = async () => {
    const userData = await Auth.currentAuthenticatedUser({
      bypassCache: false,
    });
    return userData.attributes["email"];
  };

  const MyTheme = {
    SignInButton: { 'display': 'none' },
    googleSignInButton: { 'backgroundColor': 'red', 'borderColor': 'red' }
  }

  return (
    
    <div className="App">
      {user === "auth" ? <div className="sign-in">
        <AmplifyAuthenticator theme={MyTheme} initialAuthState={initialAuthState}>
          <AmplifySignUp
            slot="sign-up"
            usernameAlias="email"
            formFields={[
              {
                type: "email",
                label: "Email Address *",
                placeholder: "Email",
                required: true,
              },
              {
                type: "password",
                label: "Password *",
                placeholder: "Password",
                required: true,
              },
              {
                type: "phone_number",
                label: "Phone Number *",
                placeholder: "(555) 555-1212",
                required: true,
              },
            ]}
          />
          <AmplifySignIn slot="sign-in" usernameAlias="email" />
          <AmplifyConfirmSignUp slot="confirm-sign-up" usernameAlias="email" />
          <AmplifyForgotPassword slot="forgot-password" usernameAlias="email"></AmplifyForgotPassword>
        </AmplifyAuthenticator>
      </div> : 
        user !== "auth" && user !== '' && user !== 'skel' ? <Router profileSet={profileSet} setUser={setUser} authState={authState} user={user} setAuthUser={setAuthUser}/> :
        <Router profileSet={profileSet} setUser={setUser} authState={authState} user={user} setAuthUser={setAuthUser}/>
      }
      {/* {!user ?  <>
        <Router profileSet={profileSet} setUser={setUser} authState={authState} user={user} />
      </> : <>
      
      </>} */}
    </div>
  );
}

export default App;
