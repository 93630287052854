import React from 'react'
import { Row, Col } from 'antd'



const Footer = () => {
    return (

        <footer>
            <Row>
                <Col span={24}>
                    <div className="ftext">
                    2020 ContractJobs.Tech, Inc. All Rights Reserved.
                    </div>
                </Col>

            </Row>

        </footer>


    )
}

export default Footer