/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createConsultant = /* GraphQL */ `
  mutation CreateConsultant(
    $input: CreateConsultantInput!
    $condition: ModelConsultantConditionInput
  ) {
    createConsultant(input: $input, condition: $condition) {
      locations
      coordinates {
        lat
        lon
      }
      clientId
      contractEndDate
      email
      id
      name
      phone
      skills
      verified
      owningCompany
      desiredPerHour
      lastProjectRandR
      currentRate
      willingRelocate
      willingTravelUs
      cvFileName
      stringSkills
      workAuthorization
      contactForHigherRate
      locationsString
      resumeSkills
      createdAt
      updatedAt
      allowSms
      allowEmail
      source
      owner
      endDate
      pdfFileName
      matchedSkills
      matchedSkillsString
      workStart
      title
      origin
      allTitles
      countries
      recentSkill
      allSkill
      recentTitles
      totalYearsOfExperience
      mostRecentExperienceSummary
      certifications
      education
      isResumeParserProceed
      isActive
      messageStatus
      messageReply
      allowAllNotification
      allowNewJobNotification
      appliedCompany
      appliedJob
    }
  }
`;
export const updateConsultant = /* GraphQL */ `
  mutation UpdateConsultant(
    $input: UpdateConsultantInput!
    $condition: ModelConsultantConditionInput
  ) {
    updateConsultant(input: $input, condition: $condition) {
      locations
      coordinates {
        lat
        lon
      }
      clientId
      contractEndDate
      email
      id
      name
      phone
      skills
      verified
      owningCompany
      desiredPerHour
      lastProjectRandR
      currentRate
      willingRelocate
      willingTravelUs
      cvFileName
      stringSkills
      workAuthorization
      contactForHigherRate
      locationsString
      resumeSkills
      createdAt
      updatedAt
      allowSms
      allowEmail
      source
      owner
      endDate
      pdfFileName
      matchedSkills
      matchedSkillsString
      workStart
      title
      origin
      allTitles
      countries
      recentSkill
      allSkill
      recentTitles
      totalYearsOfExperience
      mostRecentExperienceSummary
      certifications
      education
      isResumeParserProceed
      isActive
      messageStatus
      messageReply
      allowAllNotification
      allowNewJobNotification
      appliedCompany
      appliedJob
    }
  }
`;
export const deleteConsultant = /* GraphQL */ `
  mutation DeleteConsultant(
    $input: DeleteConsultantInput!
    $condition: ModelConsultantConditionInput
  ) {
    deleteConsultant(input: $input, condition: $condition) {
      locations
      coordinates {
        lat
        lon
      }
      clientId
      contractEndDate
      email
      id
      name
      phone
      skills
      verified
      owningCompany
      desiredPerHour
      lastProjectRandR
      currentRate
      willingRelocate
      willingTravelUs
      cvFileName
      stringSkills
      workAuthorization
      contactForHigherRate
      locationsString
      resumeSkills
      createdAt
      updatedAt
      allowSms
      allowEmail
      source
      owner
      endDate
      pdfFileName
      matchedSkills
      matchedSkillsString
      workStart
      title
      origin
      allTitles
      countries
      recentSkill
      allSkill
      recentTitles
      totalYearsOfExperience
      mostRecentExperienceSummary
      certifications
      education
      isResumeParserProceed
      isActive
      messageStatus
      messageReply
      allowAllNotification
      allowNewJobNotification
      appliedCompany
      appliedJob
    }
  }
`;
export const createSkills = /* GraphQL */ `
  mutation CreateSkills(
    $input: CreateSkillsInput!
    $condition: ModelSkillsConditionInput
  ) {
    createSkills(input: $input, condition: $condition) {
      skillName
      createdAt
      updatedAt
      id
    }
  }
`;
export const updateSkills = /* GraphQL */ `
  mutation UpdateSkills(
    $input: UpdateSkillsInput!
    $condition: ModelSkillsConditionInput
  ) {
    updateSkills(input: $input, condition: $condition) {
      skillName
      createdAt
      updatedAt
      id
    }
  }
`;
export const deleteSkills = /* GraphQL */ `
  mutation DeleteSkills(
    $input: DeleteSkillsInput!
    $condition: ModelSkillsConditionInput
  ) {
    deleteSkills(input: $input, condition: $condition) {
      skillName
      createdAt
      updatedAt
      id
    }
  }
`;
export const createJobs = /* GraphQL */ `
  mutation CreateJobs(
    $input: CreateJobsInput!
    $condition: ModelJobsConditionInput
  ) {
    createJobs(input: $input, condition: $condition) {
      id
      jobTitle
      jobDescription
      skills
      skillsComprehend
      locations
      coordinates {
        lat
        lon
      }
      offeredRate
      contractLength
      remote
      emailMatchingCandidates
      createdDate
      updatedDate
      companyName
      stringSkills
      skillsComprehendSkills
      locationsString
      minRate
      name
      updatedAt
      recruiterId
      phone
      status
      createdAt
      owner
      notifyRecruitersEmails
      minWorkExperience
      workAuthorization
      jobRoleTitles
      certificates
      jobRoleTitlesCondition
      certificatesCondition
      candidateLastMonth
      candidateLastDate
      isJobRateCondition
      willingRelocate
      willingTravelUs
      skillsCondition
      skillsType
      resumeText
      resumeTextCondition
      jobRoleTitlesType
      availability
      emailMatchingRecruiter
      isIncludeRateFilter
    }
  }
`;
export const updateJobs = /* GraphQL */ `
  mutation UpdateJobs(
    $input: UpdateJobsInput!
    $condition: ModelJobsConditionInput
  ) {
    updateJobs(input: $input, condition: $condition) {
      id
      jobTitle
      jobDescription
      skills
      skillsComprehend
      locations
      coordinates {
        lat
        lon
      }
      offeredRate
      contractLength
      remote
      emailMatchingCandidates
      createdDate
      updatedDate
      companyName
      stringSkills
      skillsComprehendSkills
      locationsString
      minRate
      name
      updatedAt
      recruiterId
      phone
      status
      createdAt
      owner
      notifyRecruitersEmails
      minWorkExperience
      workAuthorization
      jobRoleTitles
      certificates
      jobRoleTitlesCondition
      certificatesCondition
      candidateLastMonth
      candidateLastDate
      isJobRateCondition
      willingRelocate
      willingTravelUs
      skillsCondition
      skillsType
      resumeText
      resumeTextCondition
      jobRoleTitlesType
      availability
      emailMatchingRecruiter
      isIncludeRateFilter
    }
  }
`;
export const deleteJobs = /* GraphQL */ `
  mutation DeleteJobs(
    $input: DeleteJobsInput!
    $condition: ModelJobsConditionInput
  ) {
    deleteJobs(input: $input, condition: $condition) {
      id
      jobTitle
      jobDescription
      skills
      skillsComprehend
      locations
      coordinates {
        lat
        lon
      }
      offeredRate
      contractLength
      remote
      emailMatchingCandidates
      createdDate
      updatedDate
      companyName
      stringSkills
      skillsComprehendSkills
      locationsString
      minRate
      name
      updatedAt
      recruiterId
      phone
      status
      createdAt
      owner
      notifyRecruitersEmails
      minWorkExperience
      workAuthorization
      jobRoleTitles
      certificates
      jobRoleTitlesCondition
      certificatesCondition
      candidateLastMonth
      candidateLastDate
      isJobRateCondition
      willingRelocate
      willingTravelUs
      skillsCondition
      skillsType
      resumeText
      resumeTextCondition
      jobRoleTitlesType
      availability
      emailMatchingRecruiter
      isIncludeRateFilter
    }
  }
`;
export const createJobStatus = /* GraphQL */ `
  mutation CreateJobStatus(
    $input: CreateJobStatusInput!
    $condition: ModelJobStatusConditionInput
  ) {
    createJobStatus(input: $input, condition: $condition) {
      id
      jobId
      candidateId
      status
      reasons
      createdAt
      owningCompany
      owner
      recruiterEmail
      updatedAt
      candidate {
        locations
        coordinates {
          lat
          lon
        }
        clientId
        contractEndDate
        email
        id
        name
        phone
        skills
        verified
        owningCompany
        desiredPerHour
        lastProjectRandR
        currentRate
        willingRelocate
        willingTravelUs
        cvFileName
        stringSkills
        workAuthorization
        contactForHigherRate
        locationsString
        resumeSkills
        createdAt
        updatedAt
        allowSms
        allowEmail
        source
        owner
        endDate
        pdfFileName
        matchedSkills
        matchedSkillsString
        workStart
        title
        origin
        allTitles
        countries
        recentSkill
        allSkill
        recentTitles
        totalYearsOfExperience
        mostRecentExperienceSummary
        certifications
        education
        isResumeParserProceed
        isActive
        messageStatus
        messageReply
        allowAllNotification
        allowNewJobNotification
        appliedCompany
        appliedJob
      }
      job {
        id
        jobTitle
        jobDescription
        skills
        skillsComprehend
        locations
        coordinates {
          lat
          lon
        }
        offeredRate
        contractLength
        remote
        emailMatchingCandidates
        createdDate
        updatedDate
        companyName
        stringSkills
        skillsComprehendSkills
        locationsString
        minRate
        name
        updatedAt
        recruiterId
        phone
        status
        createdAt
        owner
        notifyRecruitersEmails
        minWorkExperience
        workAuthorization
        jobRoleTitles
        certificates
        jobRoleTitlesCondition
        certificatesCondition
        candidateLastMonth
        candidateLastDate
        isJobRateCondition
        willingRelocate
        willingTravelUs
        skillsCondition
        skillsType
        resumeText
        resumeTextCondition
        jobRoleTitlesType
        availability
        emailMatchingRecruiter
        isIncludeRateFilter
      }
      from
      isUserContacted
      applied
      contacted
      candidateRecruiterEmail
    }
  }
`;
export const updateJobStatus = /* GraphQL */ `
  mutation UpdateJobStatus(
    $input: UpdateJobStatusInput!
    $condition: ModelJobStatusConditionInput
  ) {
    updateJobStatus(input: $input, condition: $condition) {
      id
      jobId
      candidateId
      status
      reasons
      createdAt
      owningCompany
      owner
      recruiterEmail
      updatedAt
      candidate {
        locations
        coordinates {
          lat
          lon
        }
        clientId
        contractEndDate
        email
        id
        name
        phone
        skills
        verified
        owningCompany
        desiredPerHour
        lastProjectRandR
        currentRate
        willingRelocate
        willingTravelUs
        cvFileName
        stringSkills
        workAuthorization
        contactForHigherRate
        locationsString
        resumeSkills
        createdAt
        updatedAt
        allowSms
        allowEmail
        source
        owner
        endDate
        pdfFileName
        matchedSkills
        matchedSkillsString
        workStart
        title
        origin
        allTitles
        countries
        recentSkill
        allSkill
        recentTitles
        totalYearsOfExperience
        mostRecentExperienceSummary
        certifications
        education
        isResumeParserProceed
        isActive
        messageStatus
        messageReply
        allowAllNotification
        allowNewJobNotification
        appliedCompany
        appliedJob
      }
      job {
        id
        jobTitle
        jobDescription
        skills
        skillsComprehend
        locations
        coordinates {
          lat
          lon
        }
        offeredRate
        contractLength
        remote
        emailMatchingCandidates
        createdDate
        updatedDate
        companyName
        stringSkills
        skillsComprehendSkills
        locationsString
        minRate
        name
        updatedAt
        recruiterId
        phone
        status
        createdAt
        owner
        notifyRecruitersEmails
        minWorkExperience
        workAuthorization
        jobRoleTitles
        certificates
        jobRoleTitlesCondition
        certificatesCondition
        candidateLastMonth
        candidateLastDate
        isJobRateCondition
        willingRelocate
        willingTravelUs
        skillsCondition
        skillsType
        resumeText
        resumeTextCondition
        jobRoleTitlesType
        availability
        emailMatchingRecruiter
        isIncludeRateFilter
      }
      from
      isUserContacted
      applied
      contacted
      candidateRecruiterEmail
    }
  }
`;
export const deleteJobStatus = /* GraphQL */ `
  mutation DeleteJobStatus(
    $input: DeleteJobStatusInput!
    $condition: ModelJobStatusConditionInput
  ) {
    deleteJobStatus(input: $input, condition: $condition) {
      id
      jobId
      candidateId
      status
      reasons
      createdAt
      owningCompany
      owner
      recruiterEmail
      updatedAt
      candidate {
        locations
        coordinates {
          lat
          lon
        }
        clientId
        contractEndDate
        email
        id
        name
        phone
        skills
        verified
        owningCompany
        desiredPerHour
        lastProjectRandR
        currentRate
        willingRelocate
        willingTravelUs
        cvFileName
        stringSkills
        workAuthorization
        contactForHigherRate
        locationsString
        resumeSkills
        createdAt
        updatedAt
        allowSms
        allowEmail
        source
        owner
        endDate
        pdfFileName
        matchedSkills
        matchedSkillsString
        workStart
        title
        origin
        allTitles
        countries
        recentSkill
        allSkill
        recentTitles
        totalYearsOfExperience
        mostRecentExperienceSummary
        certifications
        education
        isResumeParserProceed
        isActive
        messageStatus
        messageReply
        allowAllNotification
        allowNewJobNotification
        appliedCompany
        appliedJob
      }
      job {
        id
        jobTitle
        jobDescription
        skills
        skillsComprehend
        locations
        coordinates {
          lat
          lon
        }
        offeredRate
        contractLength
        remote
        emailMatchingCandidates
        createdDate
        updatedDate
        companyName
        stringSkills
        skillsComprehendSkills
        locationsString
        minRate
        name
        updatedAt
        recruiterId
        phone
        status
        createdAt
        owner
        notifyRecruitersEmails
        minWorkExperience
        workAuthorization
        jobRoleTitles
        certificates
        jobRoleTitlesCondition
        certificatesCondition
        candidateLastMonth
        candidateLastDate
        isJobRateCondition
        willingRelocate
        willingTravelUs
        skillsCondition
        skillsType
        resumeText
        resumeTextCondition
        jobRoleTitlesType
        availability
        emailMatchingRecruiter
        isIncludeRateFilter
      }
      from
      isUserContacted
      applied
      contacted
      candidateRecruiterEmail
    }
  }
`;
export const createMessageUsers = /* GraphQL */ `
  mutation CreateMessageUsers(
    $input: CreateMessageUsersInput!
    $condition: ModelMessageUsersConditionInput
  ) {
    createMessageUsers(input: $input, condition: $condition) {
      id
      fromEmail
      toEmail
      owner
      owningCompany
      recruiterEmail
      candidateEmail
      recruiterName
      candidate {
        locations
        coordinates {
          lat
          lon
        }
        clientId
        contractEndDate
        email
        id
        name
        phone
        skills
        verified
        owningCompany
        desiredPerHour
        lastProjectRandR
        currentRate
        willingRelocate
        willingTravelUs
        cvFileName
        stringSkills
        workAuthorization
        contactForHigherRate
        locationsString
        resumeSkills
        createdAt
        updatedAt
        allowSms
        allowEmail
        source
        owner
        endDate
        pdfFileName
        matchedSkills
        matchedSkillsString
        workStart
        title
        origin
        allTitles
        countries
        recentSkill
        allSkill
        recentTitles
        totalYearsOfExperience
        mostRecentExperienceSummary
        certifications
        education
        isResumeParserProceed
        isActive
        messageStatus
        messageReply
        allowAllNotification
        allowNewJobNotification
        appliedCompany
        appliedJob
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMessageUsers = /* GraphQL */ `
  mutation UpdateMessageUsers(
    $input: UpdateMessageUsersInput!
    $condition: ModelMessageUsersConditionInput
  ) {
    updateMessageUsers(input: $input, condition: $condition) {
      id
      fromEmail
      toEmail
      owner
      owningCompany
      recruiterEmail
      candidateEmail
      recruiterName
      candidate {
        locations
        coordinates {
          lat
          lon
        }
        clientId
        contractEndDate
        email
        id
        name
        phone
        skills
        verified
        owningCompany
        desiredPerHour
        lastProjectRandR
        currentRate
        willingRelocate
        willingTravelUs
        cvFileName
        stringSkills
        workAuthorization
        contactForHigherRate
        locationsString
        resumeSkills
        createdAt
        updatedAt
        allowSms
        allowEmail
        source
        owner
        endDate
        pdfFileName
        matchedSkills
        matchedSkillsString
        workStart
        title
        origin
        allTitles
        countries
        recentSkill
        allSkill
        recentTitles
        totalYearsOfExperience
        mostRecentExperienceSummary
        certifications
        education
        isResumeParserProceed
        isActive
        messageStatus
        messageReply
        allowAllNotification
        allowNewJobNotification
        appliedCompany
        appliedJob
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessageUsers = /* GraphQL */ `
  mutation DeleteMessageUsers(
    $input: DeleteMessageUsersInput!
    $condition: ModelMessageUsersConditionInput
  ) {
    deleteMessageUsers(input: $input, condition: $condition) {
      id
      fromEmail
      toEmail
      owner
      owningCompany
      recruiterEmail
      candidateEmail
      recruiterName
      candidate {
        locations
        coordinates {
          lat
          lon
        }
        clientId
        contractEndDate
        email
        id
        name
        phone
        skills
        verified
        owningCompany
        desiredPerHour
        lastProjectRandR
        currentRate
        willingRelocate
        willingTravelUs
        cvFileName
        stringSkills
        workAuthorization
        contactForHigherRate
        locationsString
        resumeSkills
        createdAt
        updatedAt
        allowSms
        allowEmail
        source
        owner
        endDate
        pdfFileName
        matchedSkills
        matchedSkillsString
        workStart
        title
        origin
        allTitles
        countries
        recentSkill
        allSkill
        recentTitles
        totalYearsOfExperience
        mostRecentExperienceSummary
        certifications
        education
        isResumeParserProceed
        isActive
        messageStatus
        messageReply
        allowAllNotification
        allowNewJobNotification
        appliedCompany
        appliedJob
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMessages = /* GraphQL */ `
  mutation CreateMessages(
    $input: CreateMessagesInput!
    $condition: ModelMessagesConditionInput
  ) {
    createMessages(input: $input, condition: $condition) {
      id
      to
      from
      type
      subject
      message
      phone
      owner
      createdAt
      owningCompany
      templateName
      templateData
      cvFileName
      emailType
      updatedAt
      jobId
      candidateName
      ccEmail
    }
  }
`;
export const updateMessages = /* GraphQL */ `
  mutation UpdateMessages(
    $input: UpdateMessagesInput!
    $condition: ModelMessagesConditionInput
  ) {
    updateMessages(input: $input, condition: $condition) {
      id
      to
      from
      type
      subject
      message
      phone
      owner
      createdAt
      owningCompany
      templateName
      templateData
      cvFileName
      emailType
      updatedAt
      jobId
      candidateName
      ccEmail
    }
  }
`;
export const deleteMessages = /* GraphQL */ `
  mutation DeleteMessages(
    $input: DeleteMessagesInput!
    $condition: ModelMessagesConditionInput
  ) {
    deleteMessages(input: $input, condition: $condition) {
      id
      to
      from
      type
      subject
      message
      phone
      owner
      createdAt
      owningCompany
      templateName
      templateData
      cvFileName
      emailType
      updatedAt
      jobId
      candidateName
      ccEmail
    }
  }
`;
export const createConsultantResume = /* GraphQL */ `
  mutation CreateConsultantResume(
    $input: CreateConsultantResumeInput!
    $condition: ModelConsultantResumeConditionInput
  ) {
    createConsultantResume(input: $input, condition: $condition) {
      id
      resumeSummary
      resumeEducation
      parsedSkills
      lastProjectRandR
      skills
      createdAt
      updatedAt
    }
  }
`;
export const updateConsultantResume = /* GraphQL */ `
  mutation UpdateConsultantResume(
    $input: UpdateConsultantResumeInput!
    $condition: ModelConsultantResumeConditionInput
  ) {
    updateConsultantResume(input: $input, condition: $condition) {
      id
      resumeSummary
      resumeEducation
      parsedSkills
      lastProjectRandR
      skills
      createdAt
      updatedAt
    }
  }
`;
export const deleteConsultantResume = /* GraphQL */ `
  mutation DeleteConsultantResume(
    $input: DeleteConsultantResumeInput!
    $condition: ModelConsultantResumeConditionInput
  ) {
    deleteConsultantResume(input: $input, condition: $condition) {
      id
      resumeSummary
      resumeEducation
      parsedSkills
      lastProjectRandR
      skills
      createdAt
      updatedAt
    }
  }
`;
export const createConsultantLocation = /* GraphQL */ `
  mutation CreateConsultantLocation(
    $input: CreateConsultantLocationInput!
    $condition: ModelConsultantLocationConditionInput
  ) {
    createConsultantLocation(input: $input, condition: $condition) {
      id
      email
      location {
        lat
        lon
      }
      owningCompany
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateConsultantLocation = /* GraphQL */ `
  mutation UpdateConsultantLocation(
    $input: UpdateConsultantLocationInput!
    $condition: ModelConsultantLocationConditionInput
  ) {
    updateConsultantLocation(input: $input, condition: $condition) {
      id
      email
      location {
        lat
        lon
      }
      owningCompany
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteConsultantLocation = /* GraphQL */ `
  mutation DeleteConsultantLocation(
    $input: DeleteConsultantLocationInput!
    $condition: ModelConsultantLocationConditionInput
  ) {
    deleteConsultantLocation(input: $input, condition: $condition) {
      id
      email
      location {
        lat
        lon
      }
      owningCompany
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createConsultantNotes = /* GraphQL */ `
  mutation CreateConsultantNotes(
    $input: CreateConsultantNotesInput!
    $condition: ModelConsultantNotesConditionInput
  ) {
    createConsultantNotes(input: $input, condition: $condition) {
      id
      candidateEmail
      owningCompany
      recruiterEmail
      recruiterName
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateConsultantNotes = /* GraphQL */ `
  mutation UpdateConsultantNotes(
    $input: UpdateConsultantNotesInput!
    $condition: ModelConsultantNotesConditionInput
  ) {
    updateConsultantNotes(input: $input, condition: $condition) {
      id
      candidateEmail
      owningCompany
      recruiterEmail
      recruiterName
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteConsultantNotes = /* GraphQL */ `
  mutation DeleteConsultantNotes(
    $input: DeleteConsultantNotesInput!
    $condition: ModelConsultantNotesConditionInput
  ) {
    deleteConsultantNotes(input: $input, condition: $condition) {
      id
      candidateEmail
      owningCompany
      recruiterEmail
      recruiterName
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUnverifiedUsers = /* GraphQL */ `
  mutation CreateUnverifiedUsers(
    $input: CreateUnverifiedUsersInput!
    $condition: ModelUnverifiedUsersConditionInput
  ) {
    createUnverifiedUsers(input: $input, condition: $condition) {
      id
      allowSms
      allowEmail
      contractEndDate
      currentRate
      cvFileName
      desiredPerHour
      email
      lastProjectRandR
      locations
      locationsString
      name
      phone
      skills
      stringSkills
      willingRelocate
      willingTravelUs
      workAuthorization
      owningCompany
      owner
      createdAt
      status
      jobId
      isApplyAsRecruiter
      recruiterEmail
      recruiterName
      recruiterCompanyName
      coordinates {
        lat
        lon
      }
      updatedAt
    }
  }
`;
export const updateUnverifiedUsers = /* GraphQL */ `
  mutation UpdateUnverifiedUsers(
    $input: UpdateUnverifiedUsersInput!
    $condition: ModelUnverifiedUsersConditionInput
  ) {
    updateUnverifiedUsers(input: $input, condition: $condition) {
      id
      allowSms
      allowEmail
      contractEndDate
      currentRate
      cvFileName
      desiredPerHour
      email
      lastProjectRandR
      locations
      locationsString
      name
      phone
      skills
      stringSkills
      willingRelocate
      willingTravelUs
      workAuthorization
      owningCompany
      owner
      createdAt
      status
      jobId
      isApplyAsRecruiter
      recruiterEmail
      recruiterName
      recruiterCompanyName
      coordinates {
        lat
        lon
      }
      updatedAt
    }
  }
`;
export const deleteUnverifiedUsers = /* GraphQL */ `
  mutation DeleteUnverifiedUsers(
    $input: DeleteUnverifiedUsersInput!
    $condition: ModelUnverifiedUsersConditionInput
  ) {
    deleteUnverifiedUsers(input: $input, condition: $condition) {
      id
      allowSms
      allowEmail
      contractEndDate
      currentRate
      cvFileName
      desiredPerHour
      email
      lastProjectRandR
      locations
      locationsString
      name
      phone
      skills
      stringSkills
      willingRelocate
      willingTravelUs
      workAuthorization
      owningCompany
      owner
      createdAt
      status
      jobId
      isApplyAsRecruiter
      recruiterEmail
      recruiterName
      recruiterCompanyName
      coordinates {
        lat
        lon
      }
      updatedAt
    }
  }
`;
export const createConsultantMessage = /* GraphQL */ `
  mutation CreateConsultantMessage(
    $input: CreateConsultantMessageInput!
    $condition: ModelConsultantMessageConditionInput
  ) {
    createConsultantMessage(input: $input, condition: $condition) {
      email
      uniqueId
      timestamp
      from
      messageText
      phone
      jobId
      interviewDate
      interviewTime
      intentName
      sessionId
      candidateName
      messageId
      status
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateConsultantMessage = /* GraphQL */ `
  mutation UpdateConsultantMessage(
    $input: UpdateConsultantMessageInput!
    $condition: ModelConsultantMessageConditionInput
  ) {
    updateConsultantMessage(input: $input, condition: $condition) {
      email
      uniqueId
      timestamp
      from
      messageText
      phone
      jobId
      interviewDate
      interviewTime
      intentName
      sessionId
      candidateName
      messageId
      status
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteConsultantMessage = /* GraphQL */ `
  mutation DeleteConsultantMessage(
    $input: DeleteConsultantMessageInput!
    $condition: ModelConsultantMessageConditionInput
  ) {
    deleteConsultantMessage(input: $input, condition: $condition) {
      email
      uniqueId
      timestamp
      from
      messageText
      phone
      jobId
      interviewDate
      interviewTime
      intentName
      sessionId
      candidateName
      messageId
      status
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createConsultantMessageUsers = /* GraphQL */ `
  mutation CreateConsultantMessageUsers(
    $input: CreateConsultantMessageUsersInput!
    $condition: ModelConsultantMessageUsersConditionInput
  ) {
    createConsultantMessageUsers(input: $input, condition: $condition) {
      email
      candidateName
      timestamp
      isReply
      createdAt
      updatedAt
      id
      owner
    }
  }
`;
export const updateConsultantMessageUsers = /* GraphQL */ `
  mutation UpdateConsultantMessageUsers(
    $input: UpdateConsultantMessageUsersInput!
    $condition: ModelConsultantMessageUsersConditionInput
  ) {
    updateConsultantMessageUsers(input: $input, condition: $condition) {
      email
      candidateName
      timestamp
      isReply
      createdAt
      updatedAt
      id
      owner
    }
  }
`;
export const deleteConsultantMessageUsers = /* GraphQL */ `
  mutation DeleteConsultantMessageUsers(
    $input: DeleteConsultantMessageUsersInput!
    $condition: ModelConsultantMessageUsersConditionInput
  ) {
    deleteConsultantMessageUsers(input: $input, condition: $condition) {
      email
      candidateName
      timestamp
      isReply
      createdAt
      updatedAt
      id
      owner
    }
  }
`;
export const createConsultantResumeData = /* GraphQL */ `
  mutation CreateConsultantResumeData(
    $input: CreateConsultantResumeDataInput!
    $condition: ModelConsultantResumeDataConditionInput
  ) {
    createConsultantResumeData(input: $input, condition: $condition) {
      email
      resumeData
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateConsultantResumeData = /* GraphQL */ `
  mutation UpdateConsultantResumeData(
    $input: UpdateConsultantResumeDataInput!
    $condition: ModelConsultantResumeDataConditionInput
  ) {
    updateConsultantResumeData(input: $input, condition: $condition) {
      email
      resumeData
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteConsultantResumeData = /* GraphQL */ `
  mutation DeleteConsultantResumeData(
    $input: DeleteConsultantResumeDataInput!
    $condition: ModelConsultantResumeDataConditionInput
  ) {
    deleteConsultantResumeData(input: $input, condition: $condition) {
      email
      resumeData
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createRecruiters = /* GraphQL */ `
  mutation CreateRecruiters(
    $input: CreateRecruitersInput!
    $condition: ModelRecruitersConditionInput
  ) {
    createRecruiters(input: $input, condition: $condition) {
      id
      email
      name
      company
      phone
      allowSms
      allowEmail
      allowJobAlert
      owningCompany
      skills
      stringSkills
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateRecruiters = /* GraphQL */ `
  mutation UpdateRecruiters(
    $input: UpdateRecruitersInput!
    $condition: ModelRecruitersConditionInput
  ) {
    updateRecruiters(input: $input, condition: $condition) {
      id
      email
      name
      company
      phone
      allowSms
      allowEmail
      allowJobAlert
      owningCompany
      skills
      stringSkills
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteRecruiters = /* GraphQL */ `
  mutation DeleteRecruiters(
    $input: DeleteRecruitersInput!
    $condition: ModelRecruitersConditionInput
  ) {
    deleteRecruiters(input: $input, condition: $condition) {
      id
      email
      name
      company
      phone
      allowSms
      allowEmail
      allowJobAlert
      owningCompany
      skills
      stringSkills
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createApplyToReqEmail = /* GraphQL */ `
  mutation CreateApplyToReqEmail(
    $input: CreateApplyToReqEmailInput!
    $condition: ModelApplyToReqEmailConditionInput
  ) {
    createApplyToReqEmail(input: $input, condition: $condition) {
      candidateId
      jobId
      owningCompany
      owner
      status
      candidate {
        locations
        coordinates {
          lat
          lon
        }
        clientId
        contractEndDate
        email
        id
        name
        phone
        skills
        verified
        owningCompany
        desiredPerHour
        lastProjectRandR
        currentRate
        willingRelocate
        willingTravelUs
        cvFileName
        stringSkills
        workAuthorization
        contactForHigherRate
        locationsString
        resumeSkills
        createdAt
        updatedAt
        allowSms
        allowEmail
        source
        owner
        endDate
        pdfFileName
        matchedSkills
        matchedSkillsString
        workStart
        title
        origin
        allTitles
        countries
        recentSkill
        allSkill
        recentTitles
        totalYearsOfExperience
        mostRecentExperienceSummary
        certifications
        education
        isResumeParserProceed
        isActive
        messageStatus
        messageReply
        allowAllNotification
        allowNewJobNotification
        appliedCompany
        appliedJob
      }
      job {
        id
        jobTitle
        jobDescription
        skills
        skillsComprehend
        locations
        coordinates {
          lat
          lon
        }
        offeredRate
        contractLength
        remote
        emailMatchingCandidates
        createdDate
        updatedDate
        companyName
        stringSkills
        skillsComprehendSkills
        locationsString
        minRate
        name
        updatedAt
        recruiterId
        phone
        status
        createdAt
        owner
        notifyRecruitersEmails
        minWorkExperience
        workAuthorization
        jobRoleTitles
        certificates
        jobRoleTitlesCondition
        certificatesCondition
        candidateLastMonth
        candidateLastDate
        isJobRateCondition
        willingRelocate
        willingTravelUs
        skillsCondition
        skillsType
        resumeText
        resumeTextCondition
        jobRoleTitlesType
        availability
        emailMatchingRecruiter
        isIncludeRateFilter
      }
      createdAt
      updatedAt
      applied
      contacted
      type
      id
    }
  }
`;
export const updateApplyToReqEmail = /* GraphQL */ `
  mutation UpdateApplyToReqEmail(
    $input: UpdateApplyToReqEmailInput!
    $condition: ModelApplyToReqEmailConditionInput
  ) {
    updateApplyToReqEmail(input: $input, condition: $condition) {
      candidateId
      jobId
      owningCompany
      owner
      status
      candidate {
        locations
        coordinates {
          lat
          lon
        }
        clientId
        contractEndDate
        email
        id
        name
        phone
        skills
        verified
        owningCompany
        desiredPerHour
        lastProjectRandR
        currentRate
        willingRelocate
        willingTravelUs
        cvFileName
        stringSkills
        workAuthorization
        contactForHigherRate
        locationsString
        resumeSkills
        createdAt
        updatedAt
        allowSms
        allowEmail
        source
        owner
        endDate
        pdfFileName
        matchedSkills
        matchedSkillsString
        workStart
        title
        origin
        allTitles
        countries
        recentSkill
        allSkill
        recentTitles
        totalYearsOfExperience
        mostRecentExperienceSummary
        certifications
        education
        isResumeParserProceed
        isActive
        messageStatus
        messageReply
        allowAllNotification
        allowNewJobNotification
        appliedCompany
        appliedJob
      }
      job {
        id
        jobTitle
        jobDescription
        skills
        skillsComprehend
        locations
        coordinates {
          lat
          lon
        }
        offeredRate
        contractLength
        remote
        emailMatchingCandidates
        createdDate
        updatedDate
        companyName
        stringSkills
        skillsComprehendSkills
        locationsString
        minRate
        name
        updatedAt
        recruiterId
        phone
        status
        createdAt
        owner
        notifyRecruitersEmails
        minWorkExperience
        workAuthorization
        jobRoleTitles
        certificates
        jobRoleTitlesCondition
        certificatesCondition
        candidateLastMonth
        candidateLastDate
        isJobRateCondition
        willingRelocate
        willingTravelUs
        skillsCondition
        skillsType
        resumeText
        resumeTextCondition
        jobRoleTitlesType
        availability
        emailMatchingRecruiter
        isIncludeRateFilter
      }
      createdAt
      updatedAt
      applied
      contacted
      type
      id
    }
  }
`;
export const deleteApplyToReqEmail = /* GraphQL */ `
  mutation DeleteApplyToReqEmail(
    $input: DeleteApplyToReqEmailInput!
    $condition: ModelApplyToReqEmailConditionInput
  ) {
    deleteApplyToReqEmail(input: $input, condition: $condition) {
      candidateId
      jobId
      owningCompany
      owner
      status
      candidate {
        locations
        coordinates {
          lat
          lon
        }
        clientId
        contractEndDate
        email
        id
        name
        phone
        skills
        verified
        owningCompany
        desiredPerHour
        lastProjectRandR
        currentRate
        willingRelocate
        willingTravelUs
        cvFileName
        stringSkills
        workAuthorization
        contactForHigherRate
        locationsString
        resumeSkills
        createdAt
        updatedAt
        allowSms
        allowEmail
        source
        owner
        endDate
        pdfFileName
        matchedSkills
        matchedSkillsString
        workStart
        title
        origin
        allTitles
        countries
        recentSkill
        allSkill
        recentTitles
        totalYearsOfExperience
        mostRecentExperienceSummary
        certifications
        education
        isResumeParserProceed
        isActive
        messageStatus
        messageReply
        allowAllNotification
        allowNewJobNotification
        appliedCompany
        appliedJob
      }
      job {
        id
        jobTitle
        jobDescription
        skills
        skillsComprehend
        locations
        coordinates {
          lat
          lon
        }
        offeredRate
        contractLength
        remote
        emailMatchingCandidates
        createdDate
        updatedDate
        companyName
        stringSkills
        skillsComprehendSkills
        locationsString
        minRate
        name
        updatedAt
        recruiterId
        phone
        status
        createdAt
        owner
        notifyRecruitersEmails
        minWorkExperience
        workAuthorization
        jobRoleTitles
        certificates
        jobRoleTitlesCondition
        certificatesCondition
        candidateLastMonth
        candidateLastDate
        isJobRateCondition
        willingRelocate
        willingTravelUs
        skillsCondition
        skillsType
        resumeText
        resumeTextCondition
        jobRoleTitlesType
        availability
        emailMatchingRecruiter
        isIncludeRateFilter
      }
      createdAt
      updatedAt
      applied
      contacted
      type
      id
    }
  }
`;
