/* src/Nav.js */
import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import Icon, { MenuOutlined } from "@ant-design/icons";
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Auth } from "aws-amplify";
import './style.css'
import history from '../../config/history'
import logo1 from '../../assets/1.jpg'
import logo2 from '../../assets/2.jpg'

const Nav = ({ current, profileSet, setUser }) => {
  const signOut = async () => {
    setUser('skel')
    await Auth.signOut();

    localStorage.removeItem("counter")
    history.push('/')

  };
  return (
    <div className="header">
      <div className="first-section-header" onClick={() => history.push('/')} style={{ 'cursor': 'pointer' }}>
        {/* <img src={logo1} alt="logo" /> */}
        {/* <img src={logo2} alt="logo" className="header-logo"/> */}
        <img src={require("../../assets/logo.svg")} className="header-logo"/>
      </div>
      <Menu   selectedKeys={[current]} mode="horizontal" icon={<MenuOutlined />}>

        <Menu.Item key="searchjob">
          <Link to="/searchjob">
            <Icon type="search" />
            Jobs
          </Link>
        </Menu.Item>
        <Menu.Item key="editprofile">
          {/* <Link to={profileSet ? "/profile" : "/editprofile"}> */}
          <Link to="/joblist">

          
            <Icon type="edit" />
            {/* My Profile */} My Jobs
          </Link>
        </Menu.Item>

       

        <Menu.Item>
<Link to="/profile">
            {/* <Avatar size="large" icon={<UserOutlined />} /> */}
            <span style={{ paddingLeft: "5%", display: "inline-block"}}>My Profile</span>
</Link>
        </Menu.Item>
        {/* <MenuOutlined className="mobile"/> */}
         <Menu.Item key="sign-out" className="signout">
          <button className="primary dd-12" onClick={signOut}>
            Sign Out
          </button>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default Nav;
