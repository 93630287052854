import parse from "html-react-parser";
import React, { useState, useEffect } from "react";
import { Row, Col, Button, Card, Modal, Select, Input, message } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import "./style.css";
import ReactHtmlParser from "react-html-parser";
import { Auth, API, graphqlOperation } from 'aws-amplify'
import { createMessages } from '../../../../graphql/mutations'
import history from '../../../../config/history'
import Building from "../../../../assets/building.png";
import Location from "../../../../assets/location.png";

const { Option } = Select;
const { TextArea } = Input;


const JobResultList = ({ searchResult, applyJob, emailmessage, setEmailmessage, msgData, setMsgData, handleSendMsg }) => {

  // Creating the function to calculate the time when job was posted
  function calcTime(createdDate){
      var d1 = new Date(); //"now"
      var d2= new Date(createdDate);
      var timeDiff = Math.abs(d1-d2);			//in milliseconds
      var secDiff = timeDiff / 1000;                //in s
      var minDiff = timeDiff / 60 / 1000;           //in minutes
      var hDiff = timeDiff / 3600 / 1000;           //in hours  
      var daysDiff = hDiff/24                       //in days
      var monthsDiff = daysDiff/30                  //in months
      var yearsDiff = monthsDiff/12                 //in years
      let timeValue;
      let timeString; 
      if(secDiff<=10){
        timeValue= Math.round(secDiff);
        timeString= " seconds";
      }
      else if(minDiff<60){
        timeValue= Math.round(minDiff);
        timeString= " minutes";
      }
      else if(hDiff<24){
        timeValue= Math.round(hDiff);
        timeString= " hours";
      }
      else if(daysDiff<30){
        timeValue= Math.round(daysDiff);
        timeString= " days";
      }
      else if(monthsDiff<12){
        timeValue= Math.round(monthsDiff);
        timeString= " months";
      }
      else{
        timeValue= Math.round(yearsDiff);
        timeString= " years";
      }

      if(timeValue===1){
        return (timeValue + timeString.slice(0,timeString.length-1));
      }
      return (timeValue+ timeString);
  }


  const [show, setShow] = useState(false)
  const [isApplyDisabled, setApplyDisable] = useState('')
  const [data, setData] = useState('')


  const handleModal = (desc) => {
    setData(desc)
    setShow(true)
  }

  const handleMsgType = (data) => {
    setMsgData({ ...msgData, type: data })
  }


  const handleOpenModal = async ({ to, phone, jobTitle }) => {
    const email = await loadSessionData()
    setMsgData({ ...msgData, to, from: email, phone, subject: jobTitle })
    setEmailmessage(true)
  }
  const loadSessionData = async () => {
    const userData = await Auth.currentAuthenticatedUser({
      bypassCache: false,
    });
    return userData.attributes["email"];
  };

  const closeModal = () => {
    setEmailmessage(false)
    setMsgData({
      to: '',
      from: '',
      message: '',
      type: 'email',
      subject: ''
    })
  }

  // Object Method
  // setting up an object for read more functionality
  let readMoreObject= {};
  searchResult.map((el)=>{
    let element= el.id;
    readMoreObject= {...readMoreObject,
       [element] : false
    };
  })

  const [readMore, setReadMore] = useState({...readMoreObject});
  const changeReadmoreVisibility = (elementID) =>{
    const obj = {...readMore, [elementID]:!readMore[elementID]};
    setReadMore(obj);
  }

  return (
    <div>
      <div className="jobprofile">
      
        {searchResult.map((el, i) => {

          return (
            <div style={{marginTop: "5%"}}>
            <Card className="pts-5" key={i} bordered={false} style={{borderRadius: "10px", cursor: "pointer"}} onClick={() => history.push(`/jobdetails?id=${el.id}`)}>
              <div className="profile">
                <div className="j-34">
                  <h4 style={{fontSize: "20px"}}>{el.jobTitle}</h4>
                  <h4>
                    <span className="per-23 job-rate" style={{fontSize: "16px", fontWeight: "bold"}}>
                        ${el.minRate} - ${el.offeredRate} / Hr
                    </span>
                  </h4>

                </div>

                <div className="town-23 town23--desktop">
                <span className="address-45">
                    <p>
                      <span className="per-23">
                        <span className="jik">  {el.name}</span> <span className="jik"> <img src={Building} alt="buiding" /> {el.companyName} </span>
                      </span>
                    </p>
                  </span>
                  <span className="address-45 location">
                    <p className="location-duration testing">
                      {" "}
                      {el.locations.map((el1, i) => (
                        <span key={i} className="location-text"> 
                          <img src= {Location} alt="location" style={{ marginRight: "1%"}}/>
                          {el.remote && <span>Remote (USA), </span>} {el1}</span>
                      ))}
                      
                      <span className="duration">Duration: {el.contractLength}</span>

                    </p>
                  </span>
                </div>

                {/* trying here for mobile */}

                <div className="town-23 town23--mobile">
                <span className="address-45">
                    <p>
                       <span className="per-23"> {/*companyName */}
                        <span className="jik"> <img src={Building} alt="buiding" /> {el.companyName} </span>
                      </span>
                      <span>                    {/*companyLocation*/}
                        {" "}
                        {el.locations.map((el, i) => (
                          <span key={i} className="location-text"> 
                            <img src= {Location} alt="location" style={{ marginRight: "1%"}}/>
                          {el}</span>
                        ))}
                      </span>
                    </p>
                  </span>
                  <span className="address-45 location">
                    <p className="location-duration">
                      {/* {" "}
                      {el.locations.map((el, i) => (
                        <span key={i} className="location-text"> 
                          <img src= {Location} alt="location" style={{ marginRight: "1%"}}/>
                         {el}</span>
                      ))} */}
                      
                      <span className="duration">Duration: {el.contractLength}</span>
                      <span className="posted-time">Posted {calcTime(el.createdDate)} ago</span>
                    </p>
                  </span>
                </div>
                
                {/* trying here for mobile */}


                
                <Row>
                  <Col
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    className=""
                  >
                    {/* <p> <Button type="link" className="ered" style={{ padding: 0, color: "#666666" }} onClick={() => handleModal(el.jobDescription)}>Job Description </Button> </p> */}
                    <p className="job-desc-para">
                      <div>
                      {readMore[el.id]?
                        parse(el.jobDescription):
                        parse((el.jobDescription).slice(0,250))
                      }
                      {
                        readMore[el.id]?
                        <span className="btn-read-more" onClick={(event)=>{
                          event.stopPropagation();
                          changeReadmoreVisibility(el.id)}}
                          > See less</span>:
                        <span className="btn-read-more" onClick={(event)=>{
                          event.stopPropagation();
                          changeReadmoreVisibility(el.id)}}
                          > See more</span>
                      }
                      </div>
                    </p>
                    <div className="design-sp detail-66">
                      {el.skills.map((skill, i) => (
                        <span key={i}>{skill}</span>
                      ))}
                    </div>
                    


                  </Col>

                  <Col lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }} className="joining">
                    <br />
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div style={{marginTop: "20px"}}>
                          <span className="posted-time posted-time--desktop">Posted {calcTime(el.createdDate)} ago</span>
                        </div>
                        <div className="cl-btn">
                        <Button className="msgbtn" onClick={() => history.push(`/jobdetails?id=${el.id}`)} style={{position: el.status ? "relative": null, right: el.status ? "6%": null}}>Details</Button>
                          {!el.status ? <Button className="savebtn" disabled={isApplyDisabled === el.id} onClick={(event) => {
                            setApplyDisable(el.id);
                            event.stopPropagation();
                            applyJob({ jobId: el.id, recruiterId: el.recruiterId, companyName: el.companyName, jobData: el })}
                            }>Apply Now</Button> : <span className="savebtn app" onClick={(event)=>{event.stopPropagation();}}>Applied</span>}
                          <Button className="msgbtn" onClick={(event) => {
                            event.stopPropagation();
                          handleOpenModal({ to: el.recruiterId, phone: el.phone, jobTitle: el.jobTitle })}
                          }>Message</Button>
                        </div>
                    </div>
                  </Col>

                </Row>
                


              </div>
            </Card>
        </div>

          );
        })}
      </div>
      <Modal
        title="Job description"
        visible={show}
        onOk={() => { setShow(false) }}
        onCancel={() => { setShow(false) }}
        footer={null}
      >
        {ReactHtmlParser(data)}
      </Modal>

      <Modal
        visible={emailmessage}
        // onOk={this.handleOk}
        className={"email-34"}
        onCancel={() => closeModal()}
        onOk={() => handleSendMsg()}
        style={{fontWeight: "500"}}
      >

        <div className="">
          {/* <div className="form-control">
           
          </div> */}
          <Row >
            <Col span={12} >
              <Select
                className="form-control"
                showSearch
                style={{ width: 200 }}
                placeholder="Select"
                onChange={handleMsgType}
                value={msgData.type}
              >
                <Option value="email">Email</Option>
                {/* <Option value="message">Message</Option> */}

              </Select>
            </Col>
            <Col span={12} >
              {msgData.type === "email" ? <div className="form-control">
                <Input placeholder="Enter subject" type="text" name="subject" onChange={(e) => setMsgData({ ...msgData, subject: e.target.value })} value={msgData.subject} style={{fontWeight: "500"}}/>
              </div> : null}


            </Col>

            <Col span={24}>
              {/* <div className="form-control">
                <Input type="text" name="message" placeholder="Enter message" onChange={(e) => setMsgData({ ...msgData, message: e.target.value })} />
              </div> */}
              <div className="form-control">
                <TextArea rows={4} name="message" placeholder="Enter message" onChange={(e) => setMsgData({ ...msgData, message: e.target.value })} value={msgData.message} />
              </div>

            </Col>

          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default JobResultList;
