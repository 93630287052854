import React from 'react';
import 'antd/dist/antd.css';
import { Avatar } from 'antd';
import { Row, Col } from 'antd';

const arr = [
    {
        img: "./img/C3.png",
        name: "Rakesh",
        designation: "Java Developer",
        company: "",
        feedback:"I was notified of an opening with higher rate. I applied and got a blazing fast response from the recruiter. I switched jobs and am now making $15/hr more!"
    },
    {
        img: "./img/C2.png",
        name: "Preeti",
        designation: "HR Manager",
        company: "CloudHires",
        feedback:" We have been using the platform to fill our open needs and I like how the website allows us to zero in on good candidates. The rate filter is so very helpful!"
    },
    {
        img: "./img/C1.png",
        name: "Sandra",
        designation: "UX Designer",
        company: "",
        feedback:"I love the messaging feature. I was able to interact seamlessly with the recruiter who are very active and responsive. The entire process from search to hire was so fast!!"
    }
];

function Stars(){
    return (
        <div className="stars">
            <img src="./img/star.png" alt="img"/>
            <img src="./img/star.png" alt="img"/>
            <img src="./img/star.png" alt="img"/>
            <img src="./img/star.png" alt="img"/>
            <img src="./img/starhalf.png" alt="img"/>
        </div>
    );
}


function createCard(arrItem){
    return (
        <Col sm={8} md={8} lg={8}>
                    <div className="card">
                        <Avatar size={55}  src={arrItem.img} />
                        <h1 className="card-title">{arrItem.name}</h1>
                        <p className="card-subtitle">{arrItem.designation}</p>
                        <p className="card-company">{arrItem.company}</p>
                        <Stars />
                        <p className="card-text">{arrItem.feedback}</p>
                    </div>
        </Col>
    );
}

function CustReviews(){
    return (
        <section className="reviews">
            <h5 className="reviews-heading">What our customers say</h5>
            <div className="site-card-border-less-wrapper">
            <div className="site-card-wrapper">
            <Row type="flex" >
                {
                    arr.map(createCard)
                }
            </Row>
            </div>
            </div>
        </section>
    )
}

export default CustReviews;
