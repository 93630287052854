import React, { useState, useEffect } from 'react'
import JobInfo from './jobinfo'
import ProfileForm from './profileform'
import { Auth, graphqlOperation, API } from 'aws-amplify'
import queryString from 'query-string'
import { listJobStatuses, getJobs, listUnverifiedUserss } from '../../graphql/queries'
import { styles } from "../../styles";
import { EnvironmentOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import { Row, Col } from "antd";
import history from "../../config/history";
import Footer from '../HomePage/Footer';
import Loader from '../Loader'
import useDocumentTitle from "../../config/useDocumentTitle"
const JobDetails = ({ setAuthUser }) => {
    useDocumentTitle("Job Details")
    const [render, setRender] = useState(null)
    const [jobData, setJobData] = useState({ jobTitle: '', jobDescription: '', skills: [], locations: [], rate: '', contractLength: '', companyName: '', minWorkExperience: '', workAuthorization : [] })
    const [isWithoutLogin, setIsWithoutLogin] = useState(false)
    const [loading, setLoading] = useState(false);
    const [showInformation, setShowInformation] = useState(false);
    const [showApplyAsRecruiter, setShowApplyAsRecruiter] = useState(false);

    useEffect(() => {
        getJobData();
        checkJobStatus();
    }, [])

    const setUserAuth = () => {
        setAuthUser('signup')
    }

    const submit = (isApplyAsRecruiter) => {
        setShowInformation(true);
        setShowApplyAsRecruiter(isApplyAsRecruiter)
    }


    const getJobData = async () => {
        try {
            setLoading(true)
            const parsed = queryString.parse(window.location.search);
            const userData = await Auth.currentUserInfo();
            if (parsed.id) {
                let jobData;
                if (userData) {
                    jobData = await API.graphql(graphqlOperation(getJobs, {
                        id: parsed.id,
                    }))
                } else {
                    jobData = await API.graphql({
                        query: getJobs,
                        variables: {
                            id: parsed.id,
                        },
                        authMode: 'AWS_IAM'
                    });
                    setIsWithoutLogin(true);
                }
                setLoading(false)
                if (jobData.data.getJobs) {
                    const { jobTitle, jobDescription, skills, locations, remote, minRate, offeredRate, contractLength, companyName, minWorkExperience, workAuthorization } = jobData.data.getJobs
                    let rate = `$${minRate} - $${offeredRate}`
                    setJobData({ jobTitle, jobDescription, skills, locations, remote, rate, contractLength, companyName, minWorkExperience, workAuthorization })
                    window.gtag("event", "job_details_page_view", {
                        jobTitle
                    })
                }
            }
        }
        catch (e) { setLoading(false); }
    }

    const loadSessionData = async () => {
        const userData = await Auth.currentAuthenticatedUser({
            bypassCache: false,
        });
        return userData.attributes["email"];
    };

    const checkJobStatus = async () => {
        const email = await loadSessionData();
        const parsed = queryString.parse(window.location.search);

        if (parsed.id) {
            const jobStatus = await API.graphql(graphqlOperation(listJobStatuses, {
                filter: {
                    and: [{
                        candidateId: {
                            eq: email,
                        }
                    },
                    {
                        jobId: {
                            eq: parsed.id,
                        }
                    }
                    ]
                },
            }))
            console.log('jobStatus', jobStatus)

            if (jobStatus.data.listJobStatuses) {
                if (jobStatus.data.listJobStatuses.items.length) {
                    if (jobStatus.data.listJobStatuses.items[0].status === "Applied") {
                        setRender(false)
                    }
                    else {
                        setRender(true)
                    }
                }
                else {
                    setRender(true)
                }
            }
            else {
                setRender(true)
            }
        }
    }

    const redirectToDetails = () => {
        const parsed = queryString.parse(window.location.search);

        if (parsed.id) {
            history.push('/jobdetails?id=' + parsed.id)
        }
    }

    return (
        <div>
            {/* <JobInfo />
            {render && render !== null ? <ProfileForm setRender={setRender} /> : null} */}
            {loading && <Loader />}
            {!loading && <div className=" job-det contract-12 Postjob search-121">
                <div style={styles.container} className="jobds-67">
                    <div>
                        {!isWithoutLogin && <Breadcrumb className="set-space" separator=">">
                            <Breadcrumb.Item className="pointer" onClick={() => history.push('/searchjob')}>Jobs</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => redirectToDetails()}>Job Details</Breadcrumb.Item>
                        </Breadcrumb>}
                    </div>
                </div>

                <div style={styles.container}>
                    <div style={styles.edcontainer}>
                        <Row gutter={[64, 64]} className="dh41 job-content-main">
                            <Col
                                lg={{ span: 14 }}
                                md={{ span: 14 }}
                                sm={{ span: 24 }}
                                xs={{ span: 24 }}
                                className="job-content"
                            >
                                <div className="jo-ti">
                                    <h2 >{jobData.jobTitle}</h2>
                                    {/* <p>{jobData.jobTitle}</p> */}
                                </div>
                                <JobInfo jobData={jobData} />
                            </Col>
                            {(showInformation) && <Col
                                lg={{ span: 10 }}
                                md={{ span: 10 }}
                                sm={{ span: 24 }}
                                xs={{ span: 24 }}
                                className="info-content"
                            >
                                <div>
                                    <h2 className="mb-3" >Apply For This Job</h2>
                                </div>
                                <div className="apply-info">
                                    <div>Thank you for submitting your application. A verification link has been sent to your email. Please click on the link to verify your email. You can also <a onClick={() => setUserAuth()}>register here</a> to get faster notification from the recruiter about your application.</div>
                                    {showApplyAsRecruiter && <div style={{ marginTop: '12px'}}>Thanks for applying as recruiter. Please register using the <a target='__blank' href="https://recruiter.contractjobs.tech/">recruiter</a> link above to maintain your hot list, market your candidate and post free jobs”</div>}
                                </div>
                            </Col>}
                            {!showInformation && <ProfileForm setRender={setRender} isWithoutLogin={isWithoutLogin} jobData={jobData} submit={submit}/>}

                        </Row>
                    </div>
                </div>
            </div>}
            {!loading && <Footer />}
        </div>
    )

}
export default JobDetails