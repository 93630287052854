/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const nearbyLocations = /* GraphQL */ `
  query NearbyLocations(
    $location: inputLocation!
    $m: Int
    $limit: Int
    $nextToken: String
  ) {
    nearbyLocations(
      location: $location
      m: $m
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        location {
          lat
          lon
        }
        owningCompany
        createdAt
        updatedAt
        owner
      }
      total
      nextToken
    }
  }
`;
export const searchJobsNearbyLocations = /* GraphQL */ `
  query SearchJobsNearbyLocations(
    $coordinates: inputLocation!
    $m: Int
    $limit: Int
    $nextToken: String
  ) {
    searchJobsNearbyLocations(
      coordinates: $coordinates
      m: $m
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobTitle
        jobDescription
        skills
        skillsComprehend
        locations
        coordinates {
          lat
          lon
        }
        offeredRate
        contractLength
        remote
        emailMatchingCandidates
        createdDate
        updatedDate
        companyName
        stringSkills
        skillsComprehendSkills
        locationsString
        minRate
        name
        updatedAt
        recruiterId
        phone
        status
        createdAt
        owner
        notifyRecruitersEmails
        minWorkExperience
        workAuthorization
        jobRoleTitles
        certificates
        jobRoleTitlesCondition
        certificatesCondition
        candidateLastMonth
        candidateLastDate
        isJobRateCondition
        willingRelocate
        willingTravelUs
        skillsCondition
        skillsType
        resumeText
        resumeTextCondition
        jobRoleTitlesType
        availability
        emailMatchingRecruiter
        isIncludeRateFilter
      }
      total
      nextToken
    }
  }
`;
export const searchConsultantNearbyLocations = /* GraphQL */ `
  query SearchConsultantNearbyLocations(
    $coordinates: inputLocation!
    $m: Int
    $limit: Int
    $nextToken: String
  ) {
    searchConsultantNearbyLocations(
      coordinates: $coordinates
      m: $m
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        locations
        coordinates {
          lat
          lon
        }
        clientId
        contractEndDate
        email
        id
        name
        phone
        skills
        verified
        owningCompany
        desiredPerHour
        lastProjectRandR
        currentRate
        willingRelocate
        willingTravelUs
        cvFileName
        stringSkills
        workAuthorization
        contactForHigherRate
        locationsString
        resumeSkills
        createdAt
        updatedAt
        allowSms
        allowEmail
        source
        owner
        endDate
        pdfFileName
        matchedSkills
        matchedSkillsString
        workStart
        title
        origin
        allTitles
        countries
        recentSkill
        allSkill
        recentTitles
        totalYearsOfExperience
        mostRecentExperienceSummary
        certifications
        education
        isResumeParserProceed
        isActive
        messageStatus
        messageReply
        allowAllNotification
        allowNewJobNotification
        appliedCompany
        appliedJob
      }
      total
      nextToken
    }
  }
`;
export const getS3Url = /* GraphQL */ `
  query GetS3Url($fileName: String!, $fileType: String!, $s3URL: String!) {
    getS3Url(fileName: $fileName, fileType: $fileType, s3URL: $s3URL) {
      fileName
      fileType
      s3URL
    }
  }
`;
export const searchConsultantMessage = /* GraphQL */ `
  query SearchConsultantMessage(
    $email: String!
    $limit: Int
    $nextToken: String
  ) {
    SearchConsultantMessage(
      email: $email
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        uniqueId
        timestamp
        from
        messageText
        phone
        jobId
        interviewDate
        interviewTime
        intentName
        sessionId
        candidateName
        messageId
        status
        id
        createdAt
        updatedAt
        owner
      }
      total
      nextToken
    }
  }
`;
export const searchConsultantMessageByJob = /* GraphQL */ `
  query SearchConsultantMessageByJob(
    $jobId: String!
    $limit: Int
    $nextToken: String
  ) {
    SearchConsultantMessageByJob(
      jobId: $jobId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        uniqueId
        timestamp
        from
        messageText
        phone
        jobId
        interviewDate
        interviewTime
        intentName
        sessionId
        candidateName
        messageId
        status
        id
        createdAt
        updatedAt
        owner
      }
      total
      nextToken
    }
  }
`;
export const searchConsultantEmailMessage = /* GraphQL */ `
  query SearchConsultantEmailMessage(
    $to: String!
    $limit: Int
    $nextToken: String
  ) {
    SearchConsultantEmailMessage(
      to: $to
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        to
        from
        type
        subject
        message
        phone
        owner
        createdAt
        owningCompany
        templateName
        templateData
        cvFileName
        emailType
        updatedAt
        jobId
        candidateName
        ccEmail
      }
      total
      nextToken
    }
  }
`;
export const searchJobsByCoordinates = /* GraphQL */ `
  query SearchJobsByCoordinates(
    $query: inputQuery!
    $limit: Int
    $nextToken: String
  ) {
    searchJobsByCoordinates(
      query: $query
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobTitle
        jobDescription
        skills
        skillsComprehend
        locations
        coordinates {
          lat
          lon
        }
        offeredRate
        contractLength
        remote
        emailMatchingCandidates
        createdDate
        updatedDate
        companyName
        stringSkills
        skillsComprehendSkills
        locationsString
        minRate
        name
        updatedAt
        recruiterId
        phone
        status
        createdAt
        owner
        notifyRecruitersEmails
        minWorkExperience
        workAuthorization
        jobRoleTitles
        certificates
        jobRoleTitlesCondition
        certificatesCondition
        candidateLastMonth
        candidateLastDate
        isJobRateCondition
        willingRelocate
        willingTravelUs
        skillsCondition
        skillsType
        resumeText
        resumeTextCondition
        jobRoleTitlesType
        availability
        emailMatchingRecruiter
        isIncludeRateFilter
      }
      total
      nextToken
    }
  }
`;
export const getConsultant = /* GraphQL */ `
  query GetConsultant($id: ID!) {
    getConsultant(id: $id) {
      locations
      coordinates {
        lat
        lon
      }
      clientId
      contractEndDate
      email
      id
      name
      phone
      skills
      verified
      owningCompany
      desiredPerHour
      lastProjectRandR
      currentRate
      willingRelocate
      willingTravelUs
      cvFileName
      stringSkills
      workAuthorization
      contactForHigherRate
      locationsString
      resumeSkills
      createdAt
      updatedAt
      allowSms
      allowEmail
      source
      owner
      endDate
      pdfFileName
      matchedSkills
      matchedSkillsString
      workStart
      title
      origin
      allTitles
      countries
      recentSkill
      allSkill
      recentTitles
      totalYearsOfExperience
      mostRecentExperienceSummary
      certifications
      education
      isResumeParserProceed
      isActive
      messageStatus
      messageReply
      allowAllNotification
      allowNewJobNotification
      appliedCompany
      appliedJob
    }
  }
`;
export const listConsultants = /* GraphQL */ `
  query ListConsultants(
    $filter: ModelConsultantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsultants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        locations
        coordinates {
          lat
          lon
        }
        clientId
        contractEndDate
        email
        id
        name
        phone
        skills
        verified
        owningCompany
        desiredPerHour
        lastProjectRandR
        currentRate
        willingRelocate
        willingTravelUs
        cvFileName
        stringSkills
        workAuthorization
        contactForHigherRate
        locationsString
        resumeSkills
        createdAt
        updatedAt
        allowSms
        allowEmail
        source
        owner
        endDate
        pdfFileName
        matchedSkills
        matchedSkillsString
        workStart
        title
        origin
        allTitles
        countries
        recentSkill
        allSkill
        recentTitles
        totalYearsOfExperience
        mostRecentExperienceSummary
        certifications
        education
        isResumeParserProceed
        isActive
        messageStatus
        messageReply
        allowAllNotification
        allowNewJobNotification
        appliedCompany
        appliedJob
      }
      nextToken
    }
  }
`;
export const bymessagestatus = /* GraphQL */ `
  query Bymessagestatus(
    $messageStatus: String!
    $sortDirection: ModelSortDirection
    $filter: ModelConsultantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bymessagestatus(
      messageStatus: $messageStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        locations
        coordinates {
          lat
          lon
        }
        clientId
        contractEndDate
        email
        id
        name
        phone
        skills
        verified
        owningCompany
        desiredPerHour
        lastProjectRandR
        currentRate
        willingRelocate
        willingTravelUs
        cvFileName
        stringSkills
        workAuthorization
        contactForHigherRate
        locationsString
        resumeSkills
        createdAt
        updatedAt
        allowSms
        allowEmail
        source
        owner
        endDate
        pdfFileName
        matchedSkills
        matchedSkillsString
        workStart
        title
        origin
        allTitles
        countries
        recentSkill
        allSkill
        recentTitles
        totalYearsOfExperience
        mostRecentExperienceSummary
        certifications
        education
        isResumeParserProceed
        isActive
        messageStatus
        messageReply
        allowAllNotification
        allowNewJobNotification
        appliedCompany
        appliedJob
      }
      nextToken
    }
  }
`;
export const bymessagereply = /* GraphQL */ `
  query Bymessagereply(
    $messageReply: String!
    $sortDirection: ModelSortDirection
    $filter: ModelConsultantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bymessagereply(
      messageReply: $messageReply
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        locations
        coordinates {
          lat
          lon
        }
        clientId
        contractEndDate
        email
        id
        name
        phone
        skills
        verified
        owningCompany
        desiredPerHour
        lastProjectRandR
        currentRate
        willingRelocate
        willingTravelUs
        cvFileName
        stringSkills
        workAuthorization
        contactForHigherRate
        locationsString
        resumeSkills
        createdAt
        updatedAt
        allowSms
        allowEmail
        source
        owner
        endDate
        pdfFileName
        matchedSkills
        matchedSkillsString
        workStart
        title
        origin
        allTitles
        countries
        recentSkill
        allSkill
        recentTitles
        totalYearsOfExperience
        mostRecentExperienceSummary
        certifications
        education
        isResumeParserProceed
        isActive
        messageStatus
        messageReply
        allowAllNotification
        allowNewJobNotification
        appliedCompany
        appliedJob
      }
      nextToken
    }
  }
`;
export const searchConsultants = /* GraphQL */ `
  query SearchConsultants(
    $filter: SearchableConsultantFilterInput
    $sort: [SearchableConsultantSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableConsultantAggregationInput]
  ) {
    searchConsultants(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        locations
        coordinates {
          lat
          lon
        }
        clientId
        contractEndDate
        email
        id
        name
        phone
        skills
        verified
        owningCompany
        desiredPerHour
        lastProjectRandR
        currentRate
        willingRelocate
        willingTravelUs
        cvFileName
        stringSkills
        workAuthorization
        contactForHigherRate
        locationsString
        resumeSkills
        createdAt
        updatedAt
        allowSms
        allowEmail
        source
        owner
        endDate
        pdfFileName
        matchedSkills
        matchedSkillsString
        workStart
        title
        origin
        allTitles
        countries
        recentSkill
        allSkill
        recentTitles
        totalYearsOfExperience
        mostRecentExperienceSummary
        certifications
        education
        isResumeParserProceed
        isActive
        messageStatus
        messageReply
        allowAllNotification
        allowNewJobNotification
        appliedCompany
        appliedJob
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getSkills = /* GraphQL */ `
  query GetSkills($id: ID!) {
    getSkills(id: $id) {
      skillName
      createdAt
      updatedAt
      id
    }
  }
`;
export const listSkills = /* GraphQL */ `
  query ListSkills(
    $filter: ModelSkillsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        skillName
        createdAt
        updatedAt
        id
      }
      nextToken
    }
  }
`;
export const searchSkills = /* GraphQL */ `
  query SearchSkills(
    $filter: SearchableSkillsFilterInput
    $sort: [SearchableSkillsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSkillsAggregationInput]
  ) {
    searchSkills(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        skillName
        createdAt
        updatedAt
        id
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getJobs = /* GraphQL */ `
  query GetJobs($id: ID!) {
    getJobs(id: $id) {
      id
      jobTitle
      jobDescription
      skills
      skillsComprehend
      locations
      coordinates {
        lat
        lon
      }
      offeredRate
      contractLength
      remote
      emailMatchingCandidates
      createdDate
      updatedDate
      companyName
      stringSkills
      skillsComprehendSkills
      locationsString
      minRate
      name
      updatedAt
      recruiterId
      phone
      status
      createdAt
      owner
      notifyRecruitersEmails
      minWorkExperience
      workAuthorization
      jobRoleTitles
      certificates
      jobRoleTitlesCondition
      certificatesCondition
      candidateLastMonth
      candidateLastDate
      isJobRateCondition
      willingRelocate
      willingTravelUs
      skillsCondition
      skillsType
      resumeText
      resumeTextCondition
      jobRoleTitlesType
      availability
      emailMatchingRecruiter
      isIncludeRateFilter
    }
  }
`;
export const listJobs = /* GraphQL */ `
  query ListJobs(
    $filter: ModelJobsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        jobTitle
        jobDescription
        skills
        skillsComprehend
        locations
        coordinates {
          lat
          lon
        }
        offeredRate
        contractLength
        remote
        emailMatchingCandidates
        createdDate
        updatedDate
        companyName
        stringSkills
        skillsComprehendSkills
        locationsString
        minRate
        name
        updatedAt
        recruiterId
        phone
        status
        createdAt
        owner
        notifyRecruitersEmails
        minWorkExperience
        workAuthorization
        jobRoleTitles
        certificates
        jobRoleTitlesCondition
        certificatesCondition
        candidateLastMonth
        candidateLastDate
        isJobRateCondition
        willingRelocate
        willingTravelUs
        skillsCondition
        skillsType
        resumeText
        resumeTextCondition
        jobRoleTitlesType
        availability
        emailMatchingRecruiter
        isIncludeRateFilter
      }
      nextToken
    }
  }
`;
export const searchJobs = /* GraphQL */ `
  query SearchJobs(
    $filter: SearchableJobsFilterInput
    $sort: [SearchableJobsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableJobsAggregationInput]
  ) {
    searchJobs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        jobTitle
        jobDescription
        skills
        skillsComprehend
        locations
        coordinates {
          lat
          lon
        }
        offeredRate
        contractLength
        remote
        emailMatchingCandidates
        createdDate
        updatedDate
        companyName
        stringSkills
        skillsComprehendSkills
        locationsString
        minRate
        name
        updatedAt
        recruiterId
        phone
        status
        createdAt
        owner
        notifyRecruitersEmails
        minWorkExperience
        workAuthorization
        jobRoleTitles
        certificates
        jobRoleTitlesCondition
        certificatesCondition
        candidateLastMonth
        candidateLastDate
        isJobRateCondition
        willingRelocate
        willingTravelUs
        skillsCondition
        skillsType
        resumeText
        resumeTextCondition
        jobRoleTitlesType
        availability
        emailMatchingRecruiter
        isIncludeRateFilter
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getJobStatus = /* GraphQL */ `
  query GetJobStatus($id: ID!) {
    getJobStatus(id: $id) {
      id
      jobId
      candidateId
      status
      reasons
      createdAt
      owningCompany
      owner
      recruiterEmail
      updatedAt
      candidate {
        locations
        coordinates {
          lat
          lon
        }
        clientId
        contractEndDate
        email
        id
        name
        phone
        skills
        verified
        owningCompany
        desiredPerHour
        lastProjectRandR
        currentRate
        willingRelocate
        willingTravelUs
        cvFileName
        stringSkills
        workAuthorization
        contactForHigherRate
        locationsString
        resumeSkills
        createdAt
        updatedAt
        allowSms
        allowEmail
        source
        owner
        endDate
        pdfFileName
        matchedSkills
        matchedSkillsString
        workStart
        title
        origin
        allTitles
        countries
        recentSkill
        allSkill
        recentTitles
        totalYearsOfExperience
        mostRecentExperienceSummary
        certifications
        education
        isResumeParserProceed
        isActive
        messageStatus
        messageReply
        allowAllNotification
        allowNewJobNotification
        appliedCompany
        appliedJob
      }
      job {
        id
        jobTitle
        jobDescription
        skills
        skillsComprehend
        locations
        coordinates {
          lat
          lon
        }
        offeredRate
        contractLength
        remote
        emailMatchingCandidates
        createdDate
        updatedDate
        companyName
        stringSkills
        skillsComprehendSkills
        locationsString
        minRate
        name
        updatedAt
        recruiterId
        phone
        status
        createdAt
        owner
        notifyRecruitersEmails
        minWorkExperience
        workAuthorization
        jobRoleTitles
        certificates
        jobRoleTitlesCondition
        certificatesCondition
        candidateLastMonth
        candidateLastDate
        isJobRateCondition
        willingRelocate
        willingTravelUs
        skillsCondition
        skillsType
        resumeText
        resumeTextCondition
        jobRoleTitlesType
        availability
        emailMatchingRecruiter
        isIncludeRateFilter
      }
      from
      isUserContacted
      applied
      contacted
      candidateRecruiterEmail
    }
  }
`;
export const listJobStatuses = /* GraphQL */ `
  query ListJobStatuses(
    $filter: ModelJobStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobStatuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        jobId
        candidateId
        status
        reasons
        createdAt
        owningCompany
        owner
        recruiterEmail
        updatedAt
        candidate {
          locations
          coordinates {
            lat
            lon
          }
          clientId
          contractEndDate
          email
          id
          name
          phone
          skills
          verified
          owningCompany
          desiredPerHour
          lastProjectRandR
          currentRate
          willingRelocate
          willingTravelUs
          cvFileName
          stringSkills
          workAuthorization
          contactForHigherRate
          locationsString
          resumeSkills
          createdAt
          updatedAt
          allowSms
          allowEmail
          source
          owner
          endDate
          pdfFileName
          matchedSkills
          matchedSkillsString
          workStart
          title
          origin
          allTitles
          countries
          recentSkill
          allSkill
          recentTitles
          totalYearsOfExperience
          mostRecentExperienceSummary
          certifications
          education
          isResumeParserProceed
          isActive
          messageStatus
          messageReply
          allowAllNotification
          allowNewJobNotification
          appliedCompany
          appliedJob
        }
        job {
          id
          jobTitle
          jobDescription
          skills
          skillsComprehend
          locations
          coordinates {
            lat
            lon
          }
          offeredRate
          contractLength
          remote
          emailMatchingCandidates
          createdDate
          updatedDate
          companyName
          stringSkills
          skillsComprehendSkills
          locationsString
          minRate
          name
          updatedAt
          recruiterId
          phone
          status
          createdAt
          owner
          notifyRecruitersEmails
          minWorkExperience
          workAuthorization
          jobRoleTitles
          certificates
          jobRoleTitlesCondition
          certificatesCondition
          candidateLastMonth
          candidateLastDate
          isJobRateCondition
          willingRelocate
          willingTravelUs
          skillsCondition
          skillsType
          resumeText
          resumeTextCondition
          jobRoleTitlesType
          availability
          emailMatchingRecruiter
          isIncludeRateFilter
        }
        from
        isUserContacted
        applied
        contacted
        candidateRecruiterEmail
      }
      nextToken
    }
  }
`;
export const searchJobStatuses = /* GraphQL */ `
  query SearchJobStatuses(
    $filter: SearchablejobStatusFilterInput
    $sort: [SearchablejobStatusSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablejobStatusAggregationInput]
  ) {
    searchJobStatuses(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        jobId
        candidateId
        status
        reasons
        createdAt
        owningCompany
        owner
        recruiterEmail
        updatedAt
        candidate {
          locations
          coordinates {
            lat
            lon
          }
          clientId
          contractEndDate
          email
          id
          name
          phone
          skills
          verified
          owningCompany
          desiredPerHour
          lastProjectRandR
          currentRate
          willingRelocate
          willingTravelUs
          cvFileName
          stringSkills
          workAuthorization
          contactForHigherRate
          locationsString
          resumeSkills
          createdAt
          updatedAt
          allowSms
          allowEmail
          source
          owner
          endDate
          pdfFileName
          matchedSkills
          matchedSkillsString
          workStart
          title
          origin
          allTitles
          countries
          recentSkill
          allSkill
          recentTitles
          totalYearsOfExperience
          mostRecentExperienceSummary
          certifications
          education
          isResumeParserProceed
          isActive
          messageStatus
          messageReply
          allowAllNotification
          allowNewJobNotification
          appliedCompany
          appliedJob
        }
        job {
          id
          jobTitle
          jobDescription
          skills
          skillsComprehend
          locations
          coordinates {
            lat
            lon
          }
          offeredRate
          contractLength
          remote
          emailMatchingCandidates
          createdDate
          updatedDate
          companyName
          stringSkills
          skillsComprehendSkills
          locationsString
          minRate
          name
          updatedAt
          recruiterId
          phone
          status
          createdAt
          owner
          notifyRecruitersEmails
          minWorkExperience
          workAuthorization
          jobRoleTitles
          certificates
          jobRoleTitlesCondition
          certificatesCondition
          candidateLastMonth
          candidateLastDate
          isJobRateCondition
          willingRelocate
          willingTravelUs
          skillsCondition
          skillsType
          resumeText
          resumeTextCondition
          jobRoleTitlesType
          availability
          emailMatchingRecruiter
          isIncludeRateFilter
        }
        from
        isUserContacted
        applied
        contacted
        candidateRecruiterEmail
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getMessageUsers = /* GraphQL */ `
  query GetMessageUsers($id: ID!) {
    getMessageUsers(id: $id) {
      id
      fromEmail
      toEmail
      owner
      owningCompany
      recruiterEmail
      candidateEmail
      recruiterName
      candidate {
        locations
        coordinates {
          lat
          lon
        }
        clientId
        contractEndDate
        email
        id
        name
        phone
        skills
        verified
        owningCompany
        desiredPerHour
        lastProjectRandR
        currentRate
        willingRelocate
        willingTravelUs
        cvFileName
        stringSkills
        workAuthorization
        contactForHigherRate
        locationsString
        resumeSkills
        createdAt
        updatedAt
        allowSms
        allowEmail
        source
        owner
        endDate
        pdfFileName
        matchedSkills
        matchedSkillsString
        workStart
        title
        origin
        allTitles
        countries
        recentSkill
        allSkill
        recentTitles
        totalYearsOfExperience
        mostRecentExperienceSummary
        certifications
        education
        isResumeParserProceed
        isActive
        messageStatus
        messageReply
        allowAllNotification
        allowNewJobNotification
        appliedCompany
        appliedJob
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMessageUsers = /* GraphQL */ `
  query ListMessageUsers(
    $filter: ModelMessageUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fromEmail
        toEmail
        owner
        owningCompany
        recruiterEmail
        candidateEmail
        recruiterName
        candidate {
          locations
          coordinates {
            lat
            lon
          }
          clientId
          contractEndDate
          email
          id
          name
          phone
          skills
          verified
          owningCompany
          desiredPerHour
          lastProjectRandR
          currentRate
          willingRelocate
          willingTravelUs
          cvFileName
          stringSkills
          workAuthorization
          contactForHigherRate
          locationsString
          resumeSkills
          createdAt
          updatedAt
          allowSms
          allowEmail
          source
          owner
          endDate
          pdfFileName
          matchedSkills
          matchedSkillsString
          workStart
          title
          origin
          allTitles
          countries
          recentSkill
          allSkill
          recentTitles
          totalYearsOfExperience
          mostRecentExperienceSummary
          certifications
          education
          isResumeParserProceed
          isActive
          messageStatus
          messageReply
          allowAllNotification
          allowNewJobNotification
          appliedCompany
          appliedJob
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchMessageUsers = /* GraphQL */ `
  query SearchMessageUsers(
    $filter: SearchablemessageUsersFilterInput
    $sort: [SearchablemessageUsersSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablemessageUsersAggregationInput]
  ) {
    searchMessageUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        fromEmail
        toEmail
        owner
        owningCompany
        recruiterEmail
        candidateEmail
        recruiterName
        candidate {
          locations
          coordinates {
            lat
            lon
          }
          clientId
          contractEndDate
          email
          id
          name
          phone
          skills
          verified
          owningCompany
          desiredPerHour
          lastProjectRandR
          currentRate
          willingRelocate
          willingTravelUs
          cvFileName
          stringSkills
          workAuthorization
          contactForHigherRate
          locationsString
          resumeSkills
          createdAt
          updatedAt
          allowSms
          allowEmail
          source
          owner
          endDate
          pdfFileName
          matchedSkills
          matchedSkillsString
          workStart
          title
          origin
          allTitles
          countries
          recentSkill
          allSkill
          recentTitles
          totalYearsOfExperience
          mostRecentExperienceSummary
          certifications
          education
          isResumeParserProceed
          isActive
          messageStatus
          messageReply
          allowAllNotification
          allowNewJobNotification
          appliedCompany
          appliedJob
        }
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getMessages = /* GraphQL */ `
  query GetMessages($id: ID!) {
    getMessages(id: $id) {
      id
      to
      from
      type
      subject
      message
      phone
      owner
      createdAt
      owningCompany
      templateName
      templateData
      cvFileName
      emailType
      updatedAt
      jobId
      candidateName
      ccEmail
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        to
        from
        type
        subject
        message
        phone
        owner
        createdAt
        owningCompany
        templateName
        templateData
        cvFileName
        emailType
        updatedAt
        jobId
        candidateName
        ccEmail
      }
      nextToken
    }
  }
`;
export const searchMessages = /* GraphQL */ `
  query SearchMessages(
    $filter: SearchablemessagesFilterInput
    $sort: [SearchablemessagesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablemessagesAggregationInput]
  ) {
    searchMessages(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        to
        from
        type
        subject
        message
        phone
        owner
        createdAt
        owningCompany
        templateName
        templateData
        cvFileName
        emailType
        updatedAt
        jobId
        candidateName
        ccEmail
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getConsultantResume = /* GraphQL */ `
  query GetConsultantResume($id: ID!) {
    getConsultantResume(id: $id) {
      id
      resumeSummary
      resumeEducation
      parsedSkills
      lastProjectRandR
      skills
      createdAt
      updatedAt
    }
  }
`;
export const listConsultantResumes = /* GraphQL */ `
  query ListConsultantResumes(
    $filter: ModelConsultantResumeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsultantResumes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resumeSummary
        resumeEducation
        parsedSkills
        lastProjectRandR
        skills
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchConsultantResumes = /* GraphQL */ `
  query SearchConsultantResumes(
    $filter: SearchableconsultantResumeFilterInput
    $sort: [SearchableconsultantResumeSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableconsultantResumeAggregationInput]
  ) {
    searchConsultantResumes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        resumeSummary
        resumeEducation
        parsedSkills
        lastProjectRandR
        skills
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getConsultantLocation = /* GraphQL */ `
  query GetConsultantLocation($id: ID!) {
    getConsultantLocation(id: $id) {
      id
      email
      location {
        lat
        lon
      }
      owningCompany
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listConsultantLocations = /* GraphQL */ `
  query ListConsultantLocations(
    $filter: ModelConsultantLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsultantLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        location {
          lat
          lon
        }
        owningCompany
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const searchConsultantLocations = /* GraphQL */ `
  query SearchConsultantLocations(
    $filter: SearchableconsultantLocationFilterInput
    $sort: [SearchableconsultantLocationSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableconsultantLocationAggregationInput]
  ) {
    searchConsultantLocations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        email
        location {
          lat
          lon
        }
        owningCompany
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getConsultantNotes = /* GraphQL */ `
  query GetConsultantNotes($id: ID!) {
    getConsultantNotes(id: $id) {
      id
      candidateEmail
      owningCompany
      recruiterEmail
      recruiterName
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listConsultantNotes = /* GraphQL */ `
  query ListConsultantNotes(
    $filter: ModelConsultantNotesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsultantNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        candidateEmail
        owningCompany
        recruiterEmail
        recruiterName
        note
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const searchConsultantNotes = /* GraphQL */ `
  query SearchConsultantNotes(
    $filter: SearchableconsultantNotesFilterInput
    $sort: [SearchableconsultantNotesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableconsultantNotesAggregationInput]
  ) {
    searchConsultantNotes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        candidateEmail
        owningCompany
        recruiterEmail
        recruiterName
        note
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getUnverifiedUsers = /* GraphQL */ `
  query GetUnverifiedUsers($id: ID!) {
    getUnverifiedUsers(id: $id) {
      id
      allowSms
      allowEmail
      contractEndDate
      currentRate
      cvFileName
      desiredPerHour
      email
      lastProjectRandR
      locations
      locationsString
      name
      phone
      skills
      stringSkills
      willingRelocate
      willingTravelUs
      workAuthorization
      owningCompany
      owner
      createdAt
      status
      jobId
      isApplyAsRecruiter
      recruiterEmail
      recruiterName
      recruiterCompanyName
      coordinates {
        lat
        lon
      }
      updatedAt
    }
  }
`;
export const listUnverifiedUsers = /* GraphQL */ `
  query ListUnverifiedUsers(
    $filter: ModelUnverifiedUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUnverifiedUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        allowSms
        allowEmail
        contractEndDate
        currentRate
        cvFileName
        desiredPerHour
        email
        lastProjectRandR
        locations
        locationsString
        name
        phone
        skills
        stringSkills
        willingRelocate
        willingTravelUs
        workAuthorization
        owningCompany
        owner
        createdAt
        status
        jobId
        isApplyAsRecruiter
        recruiterEmail
        recruiterName
        recruiterCompanyName
        coordinates {
          lat
          lon
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchUnverifiedUsers = /* GraphQL */ `
  query SearchUnverifiedUsers(
    $filter: SearchableUnverifiedUsersFilterInput
    $sort: [SearchableUnverifiedUsersSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUnverifiedUsersAggregationInput]
  ) {
    searchUnverifiedUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        allowSms
        allowEmail
        contractEndDate
        currentRate
        cvFileName
        desiredPerHour
        email
        lastProjectRandR
        locations
        locationsString
        name
        phone
        skills
        stringSkills
        willingRelocate
        willingTravelUs
        workAuthorization
        owningCompany
        owner
        createdAt
        status
        jobId
        isApplyAsRecruiter
        recruiterEmail
        recruiterName
        recruiterCompanyName
        coordinates {
          lat
          lon
        }
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getConsultantMessage = /* GraphQL */ `
  query GetConsultantMessage($id: ID!) {
    getConsultantMessage(id: $id) {
      email
      uniqueId
      timestamp
      from
      messageText
      phone
      jobId
      interviewDate
      interviewTime
      intentName
      sessionId
      candidateName
      messageId
      status
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listConsultantMessages = /* GraphQL */ `
  query ListConsultantMessages(
    $filter: ModelConsultantMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsultantMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        uniqueId
        timestamp
        from
        messageText
        phone
        jobId
        interviewDate
        interviewTime
        intentName
        sessionId
        candidateName
        messageId
        status
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const byjobId = /* GraphQL */ `
  query ByjobId(
    $jobId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelConsultantMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byjobId(
      jobId: $jobId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        uniqueId
        timestamp
        from
        messageText
        phone
        jobId
        interviewDate
        interviewTime
        intentName
        sessionId
        candidateName
        messageId
        status
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const bymessageId = /* GraphQL */ `
  query BymessageId(
    $messageId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelConsultantMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bymessageId(
      messageId: $messageId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        uniqueId
        timestamp
        from
        messageText
        phone
        jobId
        interviewDate
        interviewTime
        intentName
        sessionId
        candidateName
        messageId
        status
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getConsultantMessageUsers = /* GraphQL */ `
  query GetConsultantMessageUsers($id: ID!) {
    getConsultantMessageUsers(id: $id) {
      email
      candidateName
      timestamp
      isReply
      createdAt
      updatedAt
      id
      owner
    }
  }
`;
export const listConsultantMessageUsers = /* GraphQL */ `
  query ListConsultantMessageUsers(
    $filter: ModelConsultantMessageUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsultantMessageUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        candidateName
        timestamp
        isReply
        createdAt
        updatedAt
        id
        owner
      }
      nextToken
    }
  }
`;
export const searchConsultantMessageUsers = /* GraphQL */ `
  query SearchConsultantMessageUsers(
    $filter: SearchableConsultantMessageUsersFilterInput
    $sort: [SearchableConsultantMessageUsersSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableConsultantMessageUsersAggregationInput]
  ) {
    searchConsultantMessageUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        email
        candidateName
        timestamp
        isReply
        createdAt
        updatedAt
        id
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getConsultantResumeData = /* GraphQL */ `
  query GetConsultantResumeData($id: ID!) {
    getConsultantResumeData(id: $id) {
      email
      resumeData
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listConsultantResumeData = /* GraphQL */ `
  query ListConsultantResumeData(
    $filter: ModelConsultantResumeDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsultantResumeData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        resumeData
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getRecruiters = /* GraphQL */ `
  query GetRecruiters($id: ID!) {
    getRecruiters(id: $id) {
      id
      email
      name
      company
      phone
      allowSms
      allowEmail
      allowJobAlert
      owningCompany
      skills
      stringSkills
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listRecruiters = /* GraphQL */ `
  query ListRecruiters(
    $filter: ModelRecruitersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecruiters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        name
        company
        phone
        allowSms
        allowEmail
        allowJobAlert
        owningCompany
        skills
        stringSkills
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const searchRecruiters = /* GraphQL */ `
  query SearchRecruiters(
    $filter: SearchableRecruitersFilterInput
    $sort: [SearchableRecruitersSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableRecruitersAggregationInput]
  ) {
    searchRecruiters(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        email
        name
        company
        phone
        allowSms
        allowEmail
        allowJobAlert
        owningCompany
        skills
        stringSkills
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getApplyToReqEmail = /* GraphQL */ `
  query GetApplyToReqEmail($id: ID!) {
    getApplyToReqEmail(id: $id) {
      candidateId
      jobId
      owningCompany
      owner
      status
      candidate {
        locations
        coordinates {
          lat
          lon
        }
        clientId
        contractEndDate
        email
        id
        name
        phone
        skills
        verified
        owningCompany
        desiredPerHour
        lastProjectRandR
        currentRate
        willingRelocate
        willingTravelUs
        cvFileName
        stringSkills
        workAuthorization
        contactForHigherRate
        locationsString
        resumeSkills
        createdAt
        updatedAt
        allowSms
        allowEmail
        source
        owner
        endDate
        pdfFileName
        matchedSkills
        matchedSkillsString
        workStart
        title
        origin
        allTitles
        countries
        recentSkill
        allSkill
        recentTitles
        totalYearsOfExperience
        mostRecentExperienceSummary
        certifications
        education
        isResumeParserProceed
        isActive
        messageStatus
        messageReply
        allowAllNotification
        allowNewJobNotification
        appliedCompany
        appliedJob
      }
      job {
        id
        jobTitle
        jobDescription
        skills
        skillsComprehend
        locations
        coordinates {
          lat
          lon
        }
        offeredRate
        contractLength
        remote
        emailMatchingCandidates
        createdDate
        updatedDate
        companyName
        stringSkills
        skillsComprehendSkills
        locationsString
        minRate
        name
        updatedAt
        recruiterId
        phone
        status
        createdAt
        owner
        notifyRecruitersEmails
        minWorkExperience
        workAuthorization
        jobRoleTitles
        certificates
        jobRoleTitlesCondition
        certificatesCondition
        candidateLastMonth
        candidateLastDate
        isJobRateCondition
        willingRelocate
        willingTravelUs
        skillsCondition
        skillsType
        resumeText
        resumeTextCondition
        jobRoleTitlesType
        availability
        emailMatchingRecruiter
        isIncludeRateFilter
      }
      createdAt
      updatedAt
      applied
      contacted
      type
      id
    }
  }
`;
export const listApplyToReqEmails = /* GraphQL */ `
  query ListApplyToReqEmails(
    $filter: ModelApplyToReqEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplyToReqEmails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        candidateId
        jobId
        owningCompany
        owner
        status
        candidate {
          locations
          coordinates {
            lat
            lon
          }
          clientId
          contractEndDate
          email
          id
          name
          phone
          skills
          verified
          owningCompany
          desiredPerHour
          lastProjectRandR
          currentRate
          willingRelocate
          willingTravelUs
          cvFileName
          stringSkills
          workAuthorization
          contactForHigherRate
          locationsString
          resumeSkills
          createdAt
          updatedAt
          allowSms
          allowEmail
          source
          owner
          endDate
          pdfFileName
          matchedSkills
          matchedSkillsString
          workStart
          title
          origin
          allTitles
          countries
          recentSkill
          allSkill
          recentTitles
          totalYearsOfExperience
          mostRecentExperienceSummary
          certifications
          education
          isResumeParserProceed
          isActive
          messageStatus
          messageReply
          allowAllNotification
          allowNewJobNotification
          appliedCompany
          appliedJob
        }
        job {
          id
          jobTitle
          jobDescription
          skills
          skillsComprehend
          locations
          coordinates {
            lat
            lon
          }
          offeredRate
          contractLength
          remote
          emailMatchingCandidates
          createdDate
          updatedDate
          companyName
          stringSkills
          skillsComprehendSkills
          locationsString
          minRate
          name
          updatedAt
          recruiterId
          phone
          status
          createdAt
          owner
          notifyRecruitersEmails
          minWorkExperience
          workAuthorization
          jobRoleTitles
          certificates
          jobRoleTitlesCondition
          certificatesCondition
          candidateLastMonth
          candidateLastDate
          isJobRateCondition
          willingRelocate
          willingTravelUs
          skillsCondition
          skillsType
          resumeText
          resumeTextCondition
          jobRoleTitlesType
          availability
          emailMatchingRecruiter
          isIncludeRateFilter
        }
        createdAt
        updatedAt
        applied
        contacted
        type
        id
      }
      nextToken
    }
  }
`;
export const searchApplyToReqEmails = /* GraphQL */ `
  query SearchApplyToReqEmails(
    $filter: SearchableApplyToReqEmailFilterInput
    $sort: [SearchableApplyToReqEmailSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableApplyToReqEmailAggregationInput]
  ) {
    searchApplyToReqEmails(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        candidateId
        jobId
        owningCompany
        owner
        status
        candidate {
          locations
          coordinates {
            lat
            lon
          }
          clientId
          contractEndDate
          email
          id
          name
          phone
          skills
          verified
          owningCompany
          desiredPerHour
          lastProjectRandR
          currentRate
          willingRelocate
          willingTravelUs
          cvFileName
          stringSkills
          workAuthorization
          contactForHigherRate
          locationsString
          resumeSkills
          createdAt
          updatedAt
          allowSms
          allowEmail
          source
          owner
          endDate
          pdfFileName
          matchedSkills
          matchedSkillsString
          workStart
          title
          origin
          allTitles
          countries
          recentSkill
          allSkill
          recentTitles
          totalYearsOfExperience
          mostRecentExperienceSummary
          certifications
          education
          isResumeParserProceed
          isActive
          messageStatus
          messageReply
          allowAllNotification
          allowNewJobNotification
          appliedCompany
          appliedJob
        }
        job {
          id
          jobTitle
          jobDescription
          skills
          skillsComprehend
          locations
          coordinates {
            lat
            lon
          }
          offeredRate
          contractLength
          remote
          emailMatchingCandidates
          createdDate
          updatedDate
          companyName
          stringSkills
          skillsComprehendSkills
          locationsString
          minRate
          name
          updatedAt
          recruiterId
          phone
          status
          createdAt
          owner
          notifyRecruitersEmails
          minWorkExperience
          workAuthorization
          jobRoleTitles
          certificates
          jobRoleTitlesCondition
          certificatesCondition
          candidateLastMonth
          candidateLastDate
          isJobRateCondition
          willingRelocate
          willingTravelUs
          skillsCondition
          skillsType
          resumeText
          resumeTextCondition
          jobRoleTitlesType
          availability
          emailMatchingRecruiter
          isIncludeRateFilter
        }
        createdAt
        updatedAt
        applied
        contacted
        type
        id
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
