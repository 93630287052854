import React, { useState, useEffect } from "react";
import protectedRoute from "../../../config/ProtectedRoute";
import { Input, Button, Row, Col, Breadcrumb, message, Checkbox } from "antd";

import {
  SearchOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
} from "@ant-design/icons";
import SearchResultList from "./JobResultList";
import { API, graphqlOperation, Auth } from "aws-amplify";
import * as queries from "../../../graphql/queries";
import JobFilters, {InputLocation} from "./JobFilters";
import { styles } from "../../../styles";
import { CloseOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import "../style.css";
import history from "../../../config/history";
import { createJobStatus, createMessages, updateConsultant } from '../../../graphql/mutations'
import Footer from "../../HomePage/Footer";

import Filter from "../../../assets/filter.png";
import useDocumentTitle from '../../../config/useDocumentTitle'
/* global google */
const { Search } = Input;

function useWindowSize(){

  const [size, setSize]= useState([window.innerHeight, window.innerWidth]);
  useEffect( ()=> {
    
    const handleResize = () => {
      setSize([window.innerHeight, window.innerWidth]);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, []);
  return size;
}

const SearchJobs = () => {
  useDocumentTitle("Search Jobs")
  const [height, width] = useWindowSize();
  console.log("width type is ", typeof(width));
  let autocomplete;
  const initialState = { rates: [0, 200], skills: [] };

  const [searchTxt, setSearchTxt] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [filter, setFilter] = useState(true);
  const [tags, setTag] = useState(initialState);
  const [typedLocation, setTypdLoctn] = useState("");
  const [filterRm, setFilterRm] = useState(false);
  const [searchedLocationText, setSearchedLctnText] = useState("");
  const [contractLength, setContractLength] = useState("");
  const [remoteOpt, setRemoteOpt] = useState(false);
  const [filterShow, setFilterShow]= useState(false);

  const [locations, setLocations] = useState([]);
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState();
  const [previousTokens, setPreviousTokens] = useState([]);
  const [counter, setCounter] = useState({ total: 0, counter: 0 });
  const [jobStatus, setJobStatus] = useState({ applied: [] })
  const [msgData, setMsgData] = useState({
    to: '',
    from: '',
    message: '',
    type: 'email',
    subject: '',
    phone: ''
  })
  const [emailmessage, setEmailmessage] = useState(false)


  useEffect(() => {
    getLocation();
    checkForCrumbData();
    if (filterRm === true) {
      console.log('in filter true', filterRm)
      searchHandler("filter");
    }
    if (filterRm === false) {
      checkFiltersStorage();
    } else if (filterRm === null) {
      // console.log('in filter null', filterRm)
      if (tags.skills.length) {
        searchHandler("text");
      }
      else {
        setFilterRm('notset')
      }
    }
  }, [filterRm]);


  const getAppliedJobs = async () => {
    try {
      const email = await loadSessionData()
      const jobStatus = await API.graphql(graphqlOperation(queries.listJobStatuses, {
        filter: {
          and: [{
            candidateId: {
              eq: email,
            },
          }]
        },
      }))
      // console.log('jobStatus', jobStatus.listJobStatuss)
      if (jobStatus.data.listJobStatuses) {
        const items = jobStatus.data.listJobStatuses.items
        // console.log('items', items)
        // const inReview = items.filter(el => el.status === "In Review")
        // console.log('inReview', inReview)
        // const applied = items.filter(el => el.status === "Applied")
        // const notHired = items.filter(el => el.status === "Not Hired")
        setJobStatus({ ...jobStatus, items })
      }
    }
    catch (e) {

    }
  }

  const checkForCrumbData = () => {
    if (localStorage.getItem("fromCandidate")) {
      localStorage.removeItem("fromCandidate");
      if (localStorage.getItem("breadcrumb")) {
        const data = JSON.parse(localStorage.getItem("breadcrumb"));
        const { tags, locations } = data;
        setTag(tags);
        setLocations(locations);
        setFilterRm(null);
        localStorage.removeItem("breadcrumb");
      }
    }
  };

  const loadSessionData = async () => {
    const userData = await Auth.currentAuthenticatedUser({
      bypassCache: false,
    });
    return userData.attributes["email"];
  };

  const checkFiltersStorage = async () => {
    getAppliedJobs();
    if (tags.skills.length === 0) {
      const email = await loadSessionData();
      const candidateData = await API.graphql(
        graphqlOperation(queries.getConsultant, { id: email })
      );
      if (candidateData.data.getConsultant) {
        // console.log('candidateData.data.getConsultant.skills', candidateData.data.getConsultant.skills)
        if (candidateData.data.getConsultant.skills && candidateData.data.getConsultant.skills.length) {
          setTag({ ...tags, skills: [...candidateData.data.getConsultant.skills] })
        }
        setFilterRm(null)
      }
      else {
        message.error("Please complete your profile.");
      }
    }
  };

  const updateTokens = () => {
    // console.log("current tokens", counter.counter, counter.total);
    if (counter.counter < counter.total) {
      setPreviousTokens([...previousTokens, nextToken]);
      setNextToken(nextNextToken);
      setNextNextToken(undefined);
      // console.log('tags', tags, 'init', initialState)
      if ((tags.rates[0] !== 0 || tags.rates[1] !== 200) || contractLength || locations.length) {
        setFilterRm(true)
      }
      else if (tags.skills !== initialState.skills) {
        setFilterRm(null);
      }
    }

    //   else if (pagination === "prev") {
    // let prevTokens = previousTokens
    // let lastVal = prevTokens.pop();
    // setPreviousTokens(...prevTokens)
    // setNextToken(lastVal)
    // token = lastVal
    //
  };

  const prevToken = () => {
    let prevTokens = previousTokens;
    // console.log("previ", prevTokens);
    if (prevTokens.length) {
      let lastVal = prevTokens.pop();
      // setPreviousTokens(...prevTokens)
      setNextToken(lastVal);
      setCounter({
        ...counter,
        counter: counter.counter - (10 + searchResult.length),
      });
      if ((tags.rates[0] !== 0 || tags.rates[1] !== 200) || contractLength || locations.length) {
        setFilterRm(true)
      }
      else if (tags.skills !== initialState.skills) {
        setFilterRm(null);
      }
    }
  };



  const searchHandler = async (type) => {
    console.log("console_searchHandler",type)
    if (contractLength || locations.length || tags.skills.length || tags.remote === "remote") {
      // console.log('sd', tags.skills, initialState.skills)

      // console.log('sdsd', 2)
      let orCondition = [];
      let andConditions = [];
      let orLocations = []
      let orRates = []

      if (tags.skills.length) {
        tags.skills.forEach((skill) => {
          // if(skill != "remote") {
            orCondition.push({
              stringSkills: {
                match: skill.toString()
              },
            },
              {
                jobTitle: {
                  match: skill.toString()
                },
              },
              {
                jobDescription: {
                  match: skill.toString()
                },
              }
            );
          // }
        });
      }
      console.log("console_type",type)
      if (type === "filter") {
        console.log("console_tags",tags.remote)
        if (tags.rates.length || tags.locations.length || tags.remote==="remote") {
          if (tags.rates.length) {
            // (MIN > SMNI AND < SMAX) OR (MIN < SMIN and MAX > SMAX) OR (MAX > SMIN and < SMAX)
            if (tags.rates[0] !== 200 || tags.rates[1] !== 200) {
              orRates.push({
                and: [
                  {
                    minRate: {
                      gte: tags.rates[0],
                    },
                  },
                  {
                    minRate: {
                      lte: tags.rates[1],
                    },
                  }
                ]
              })
              orRates.push({
                and: [
                  {
                    minRate: {
                      lte: tags.rates[0],
                    },
                  },
                  {
                    offeredRate: {
                      gte: tags.rates[1],
                    },
                  }
                ]
              })

              orRates.push({
                and: [
                  {
                    offeredRate: {
                      gte: tags.rates[0],
                    },
                  },
                  {
                    offeredRate: {
                      lte: tags.rates[1],
                    },
                  }
                ]
              })
            } else if (tags.rates[0] === 200 && tags.rates[1] === 200) {
              andConditions.push({
                offeredRate: {
                  gte: tags.rates[0],
                },
              });
            }
          }

          if (locations.length) {
            locations.forEach((location) => {
              orLocations.push({ locationsString: { match: location.location.toString() } })
              orLocations.push({ locationsString: { match: location.long_name.toString() } })
              orLocations.push({ locationsString: { match: location.short_name.toString() } })
              orLocations.push({ locationsString: { match: "UnitedStates" } })
              orLocations.push({ locationsString: { match: "USA" } })
            })

          }
          if (contractLength) {
            andConditions.push({ contractLength: { eq: contractLength } });
          }
         
          if (remoteOpt) {
            if (locations.length) {
              console.log("console_remoteOpt_inQuery",locations.length)
              orLocations.push({ remote: { eq: remoteOpt } });
            } else {
              andConditions.push({ remote: { eq: remoteOpt } });
            }
          }
        } else {
          setSearchResult([]);
        }
      }
      // console.log('sds2222', type)
      try {
        if (orCondition.length) {
          andConditions.push({ or: orCondition })
        }
        if (orLocations.length) {
          andConditions.push({ or: orLocations })
        }

        // console.log('orRates', orRates)
        if (orRates.length) {
          andConditions.push({ or: orRates })
        }

        andConditions.push({ status: { ne: 'Archive' } })


        const email = await loadSessionData()
        const jobStatus = await API.graphql(graphqlOperation(queries.listJobStatuses, {
          filter: {
            and: [{
              candidateId: {
                eq: email,
              }
            }]
          },
        }))
        // console.log('jobStatus', jobStatus.data.listJobStatuss)
        if (jobStatus.data.listJobStatuses) {
          const items = jobStatus.data.listJobStatuses.items
          items.forEach((item) => {
            // console.log('item', item)
            if (item.status === "Not Hired" || item.status === "In Review") {
              andConditions.push({ id: { ne: item.jobId } })
            }
          })
        }

        let obj = {
          filter: { and: andConditions },
          limit: 10,
          nextToken
        };


        console.log("andConditions", andConditions);

        let consultantsData = await API.graphql(
          graphqlOperation(queries.searchJobs, obj)
        );

        let items = consultantsData.data.searchJobs.items
        // console.log('items', items)
        items.forEach((el) => {
          const getStatus = jobStatus.data.listJobStatuses.items.filter(data => data.jobId === el.id)
          // console.log('getStatus', getStatus)
          if (getStatus.length) {
            el.status = getStatus[0].status
          }
        })

        // console.log("datqa", items);
        if (consultantsData.data.searchJobs.total > 10) {
          setNextNextToken(consultantsData.data.searchJobs.nextToken);
        }

        setFilterRm('notset');
        let candidates = consultantsData.data.searchJobs.items;
        // console.log('cou', counter, 'candidates.length', candidates.length)
        setCounter({
          counter: counter.counter + candidates.length,
          total: consultantsData.data.searchJobs.total,
        });

        setSearchResult(candidates);
      } catch (e) {
        setFilterRm('notset');
      }
    }
  };

  const addLocation = (location) => {
    if (location) {
      setLocations([...locations, location]);
      setTypdLoctn("");
    }
  };

  const removeLocation = (location) => {
    // console.log("loca", location);
    if (location) {
      const updatedLocations = locations.filter((el) => el.location !== location);
      setLocations([...updatedLocations]);
      resetCounter()
      setFilterRm(true)
    }
  };

  const removeTag = (tag, type, values) => {
    let oldTags = tags[type];
    const newTags = [];
    newTags["values"] = oldTags.values.filter(
      (el) => el[0] !== values[0] && el[1] !== values[1]
    );
    newTags.names = oldTags.names.filter((el) => el !== tag);
    setTag({ ...tags, [type]: newTags });
    resetCounter()
    setFilterRm(true);
  };

  const clearFilters = () => {
    console.log('clearing filters.')
    setTag({ ...initialState, skills: tags.skills });
    setFilter(!filter)
    setSearchTxt("")
    setTypdLoctn("")
    setSearchedLctnText("")
    setContractLength("")
    resetCounter()
    console.log('clearing filters.')
    setFilterRm(null)
    setLocations([])
  };

  const searchLocation = async (e) => {
    const value = e.target.value;
    setSearchedLctnText(value);
  };

  const getLocation = async () => {
    autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autolocation"),
      { types: ["(regions)"] }
    );

    autocomplete.addListener("place_changed", handlePlaceChanged);
  };
  const handlePlaceChanged = () => {
    const location = autocomplete.getPlace().formatted_address;
    const names = autocomplete.getPlace().address_components[0]
    setSearchedLctnText(location);
    let currentLocations = locations
    const checkLocation = currentLocations.filter((el) => el.location === location);
    if (!checkLocation.length) {
      currentLocations.push({ location, long_name: names.long_name, short_name: names.short_name });
      setLocations([...currentLocations]);
    }
  };

  const addSkill = () => {
    console.log("console_search", searchTxt,tags);
    if (searchTxt) {
      const checkSkill = tags.skills.filter((skill) => skill === searchTxt);
      if (checkSkill.length === 0) {
        setSearchTxt("");
        resetCounter();
        setTag({ ...tags, skills: [...tags.skills, searchTxt] });
        setFilterRm(null);
      }
    }
  };
  const handleCheckbox = (e) => {
    console.log("console_remoteOp",e.target.checked)
    setRemoteOpt(e.target.checked);
    if(e.target.checked) {
      setTag({ ...tags, remote: e.target.id });
    }
    //  else {
    //   var array = tags;
    //   var index = array.indexOf(e.target.id)
    //   if (index !== -1) {
    //     array.splice(index, 1);
    //     setTag({ ...tags, skills: array });
    //   }
    // }
  };
  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      addSkill();
    }
  };

  const removeSkill = (skill) => {
    const checkSkill = tags.skills.filter((el) => el !== skill);
    console.log('checkSkill', checkSkill,skill)
    if (checkSkill.length === 0 || skill === "remote") {
      setRemoteOpt(false);
    }
    if (checkSkill.length === 0) {
      setSearchResult([])
    }
    setTag({ ...tags, skills: [...checkSkill] });
    resetCounter()
    setFilterRm(null)
  };

  const handleRates = (value) => {
    // console.log("value", value);
    setTag({ ...tags, rates: value });
  };

  const resetCounter = () => {
    setNextNextToken(undefined);
    setNextToken(undefined);
    setPreviousTokens([]);
    setCounter({ counter: 0, total: 0 });
  };

  // const setCandidateData = (data) => {
  //   localStorage.setItem(
  //     "breadcrumb",
  //     JSON.stringify({
  //       tags,
  //       searchedLocationText,
  //       jobData,
  //       locations,
  //     })
  //   );
  //   history.push(`/viewcandidate?id=${data.id}&&jobid=${jobData.id}`);
  // };

  const setContractLen = (data) => {
    setContractLength(data.key);
  };

  const removeContract = () => {
    setContractLength("")
    resetCounter()
    setFilterRm(true)
  }

  const clearRates = () => {
    setTag({ ...tags, rates: [0, 200] })
    resetCounter()
    setFilterRm(true)
  }

  const applyFilters = () => {
    console.log("console_tags_infilter",tags)
    setFilter(!filter)
    resetCounter()
    setFilterRm(true)
  }

  const applyJob = async ({ jobId, recruiterId, companyName, jobData }) => {
    try {
      console.log("Job Applied!");
      const email = await loadSessionData();
      console.log('re', recruiterId)


      // checking if the candidate has completed his profile
      const candidateData = await API.graphql(
        graphqlOperation(queries.getConsultant, { id: email })
      );
      let candidate;
      if (!candidateData.data.getConsultant) {
        return message.error("Please complete your profile.");
      }
      candidate = candidateData.data.getConsultant
      let today = new Date();
      let year = today.getFullYear();
      let status = 'Match'
      let reasons = [];
      const data = await API.graphql(graphqlOperation(createJobStatus, {
        input: {
          jobId,
          candidateId: email,
          status,
          reasons: reasons,
          recruiterEmail: recruiterId,
          owningCompany: companyName,
          applied: 'yes',
          contacted: 'no'
        }
      }))
      // console.log('data12', data)
      if (data.data.createJobStatus) {
        try {

          // let message = `You have a new candidate to review for your open ${jobTitle} <br/> Name: ${data.name} <br/> Email: ${data.email} <br/> Phone: ${data.phone} <br/> Desired rate: ${data.desiredPerHour} <br/> Location: ${data.locations.toString()} <br/> https://stest.contractjobs.tech/viewcandidate?id=${data.email} <br/> Thanks, <br/> ContractJobs.Tech`
          // subject: `New Candidate - ${jobTitle} - ${data.name}`

        }
        catch (e) { }
        message.success("You have successfully applied for this job.");
        getAppliedJobs();
        if (candidate && candidate.appliedCompany && candidate.appliedCompany.length) {
          candidate.appliedCompany.push(jobData.companyName)
        } else {
            candidate.appliedCompany = [jobData.companyName]
        }
        if (candidate && candidate.appliedJob && candidate.appliedJob.length) {
          candidate.appliedJob.push(jobData.id)
        } else {
            candidate.appliedJob = [jobData.id]
        }
        const data = await API.graphql(
          graphqlOperation(updateConsultant, { input: candidate })
        );
        if ((tags.rates[0] !== 0 || tags.rates[1] !== 200) || contractLength || locations.length) {
          setFilterRm(true)
        }
        else if (tags.skills !== initialState.skills) {
          setFilterRm(null);
        }
      }
    }
    catch (e) {
      message.error("Cannot apply for this job.");
    }
  }

  const handleSendMsg = async () => {
    try {
      // console.log('data', msgData)
      if (!msgData.type || msgData.type === "Select Message Type")
        return message.error('Select message type.')

      if (msgData.type === "email" && !msgData.subject)
        return message.error('Enter the subject.')

      if (!msgData.message)
        return message.error('Enter the message.')


      const savedData = await API.graphql(graphqlOperation(createMessages, { input: { ...msgData } }));
      // console.log('savedData', savedData)
      if (savedData.data.createMessages) {
        setEmailmessage(false)
        setMsgData({
          to: '',
          from: '',
          message: '',
          type: 'Select Message Type',
          subject: ''
        })
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  const handleJobList = async () => {
    history.push(`/joblist`)
  }

  const hideFilters = () => {
    // console.log('tags', tags.rates)
    if ((tags.rates[0] !== 0 || tags.rates[1] !== 200) || contractLength || locations.length) {
      setFilterRm(true)
    }
    else if (tags.skills.length) {
      setFilterRm(null);
    }
    setFilter(!filter)
  }


  return (
    <React.Fragment>
      <div style={styles.body} className="search-121">
        <div style={styles.container}>

        {!filterShow || (width>768)? 
          <div className="jobsearch">
            <Breadcrumb separator=">">
              <Breadcrumb.Item className="pointer" onClick={() => history.push("/")}>Home</Breadcrumb.Item>
              <Breadcrumb.Item className="pointer color-dark-blue" onClick={() => history.push("/searchjob")}>Jobs</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          :null}

          <Row gutter={32} className="dus-45">
            <Col
              lg={{ span: 7 }}
              md={{ span: 7 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <div className="recent-search">
              </div>

              <div className="jobs">
              {!filterShow || (width>768)? 
                <h1 style={{ fontWeight : "600"}} className="job-heading">Job Search</h1>
              :null}
                { filterShow || (width>768)? 
                <div className="jobs-Filters" style={{backgroundColor: "#fff", marginTop: filterShow ? "0": "13%"}}>
                <div className="filter-block--display" style={{}}>
                    <img src={Filter} alt="filter" />
                    <span style={{}} className="filter-block--text">Refine your search</span>
                </div>

                  <div className="filter-result-block">
                      <span onClick={ ()=>{
                        setFilterShow((prevValue)=>{
                          return setFilterShow(!prevValue);
                        });
                      }

                      }>
                          <CloseOutlined style={{color:"white", cursor: "pointer"}} />
                      </span>
                      <span className="filter-result-text">Filter results</span>
                  </div>

                  <p className="job--title" style={{paddingTop: "10%"}}>Keywords</p>
                  <div className="skit" style={{marginLeft: "2%", fontSize: "16px", color: "white", fontWeight: "bold"}}>
                  {/* {filter && <h4>Applied Filters</h4>} */}
                  {console.log("console_tagSkills",tags.skills)}
                  <div className="fer-5">
                    {tags.skills.map((el, i) => (
                      <span key={i} className="tag-cu" onClick={() => removeSkill(el)}>
                       <CloseOutlined className="pointer" style={{paddingRight:"4px"}} />  {el} 
                      </span>
                    ))}
                    {/* This is location display after searching in the searchbox */}
                    {/* {locations.length ? <>{locations.map((tag, i) => (
                      <div className="tag-cu" key={i}>
                        <CloseOutlined
                          style={{ cursor: "pointer", marginLeft: 5, paddingRight:"4px" }}
                          onClick={() => removeLocation(tag.location)}
                        />
                         {tag.location}
                      </div>
                    ))} </> : null} */}
                    {contractLength ? (
                      <span className="tag-cu">
                        <CloseOutlined
                          style={{ cursor: "pointer", paddingRight:"4px" }}
                          onClick={() => removeContract()}
                        />
                        {contractLength}
                      </span>
                    ) : null}
                    
                    {remoteOpt ? (
                      <span className="tag-cu">
                        <CloseOutlined
                          style={{ cursor: "pointer", paddingRight:"4px" }}
                          onClick={() => setRemoteOpt(false)}
                        />
                        {tags.remote}
                      </span>
                    ) : null}

                    {tags.rates[0] !== 0 || tags.rates[1] !== 200 ? (
                      <span className="tag-cu">
                        
                        <CloseOutlined
                          style={{ cursor: "pointer", paddingRight:"4px" }}
                          onClick={() => clearRates()}
                        />
                        {tags.rates[0]} - {tags.rates[1]}
                      </span>
                    ) : null}
                  </div>
                </div> 
                 <div
                  style={{
                    // visibility: filter ? "visible" : "hidden",
                    // height: !filter ? "10px" : "auto",
                  }}
                  className="visi-67"
                >

                  <InputLocation 
                    addLocation={addLocation}
                    searchLocation={searchLocation}
                    searchedLocationText={searchedLocationText}
                  />
                   <p className="job--title">
                     <Checkbox
                      id="remote"
                      onChange={(e) => handleCheckbox(e)}
                      checked={remoteOpt}
                    >
                      Remote
                    </Checkbox>
                  </p>
                  <div className="skit">
                      <div className="fer-5">
                          {console.log("Location console " + Object.values(locations) )}

                          {locations.length ? <>{locations.map((tag, i) => (
                                <span className="tag-cu" key={i}>
                                  <CloseOutlined
                                    style={{ cursor: "pointer", marginLeft: 5, paddingRight:"4px" }}
                                    onClick={() => removeLocation(tag.location)}
                                  />
                                  {tag.location}
                                </span>
                              ))} </> : null}
                        </div>
                    </div>

                  <JobFilters
                    show={filter}
                    tags={tags}
                    clearFilters={clearFilters}
                    typedLocation={typedLocation}
                    setTypdLoctn={setTypdLoctn}
                    setFilter={setFilter}
                    filter={filter}
                    removeTag={removeTag}
                    searchHandler={searchHandler}
                    setFilterRm={setFilterRm}
                    handleRates={handleRates}
                    setContractLen={setContractLen}
                    contractLength={contractLength}
                    applyFilters={applyFilters}
                    setFilterShow={setFilterShow}
                  />
                  <div>
                  
                  </div>
                </div>  
                  
                

                </div>
                  :null}
              </div>
            </Col>
            {!filterShow || (width>768)? 
            <Col
              lg={{ span: 17 }}
              md={{ span: 17 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              style= {{paddingLeft: "42px", paddingRight: "0px"}}
            >
              <div className="search-item">
                <div className="search-box">
                  <div className="search-input">
                    <Input
                      placeholder="search by keyword"
                      onChange={(e) => setSearchTxt(e.target.value)}
                      enterButton
                      value={searchTxt}
                      onKeyDown={handleKeyDown}
                      autoComplete="off"
                    />
                    <Button className="search" onClick={() => addSkill()} style={{height: "37px"}}>
                       Search
                    </Button>
                    <div onClick={
                      ()=>{
                        setFilterShow((prevValue)=>{
                          return setFilterShow(!prevValue);
                        })
                      }
                    }>
                        <img src={Filter} alt="filter img" className="filter-img"/>
                    </div>
                  </div>
                  
                </div>
                
              </div>

              <div className="skit skit--mobile">
                  {/* {filter && <h4>Applied Filters</h4>} */}
                  <div className="fer-5">
                    {tags.skills.map((el, i) => (
                      <span key={i} className="tag-cu" onClick={() => removeSkill(el)}>
                       <CloseOutlined className="pointer" style={{paddingRight:"4px"}} />  {el} 
                      </span>
                    ))}
                    {/* This is location display after searching in the searchbox */}
                    {/* {locations.length ? <>{locations.map((tag, i) => (
                      <div className="tag-cu" key={i}>
                        <CloseOutlined
                          style={{ cursor: "pointer", marginLeft: 5, paddingRight:"4px" }}
                          onClick={() => removeLocation(tag.location)}
                        />
                         {tag.location}
                      </div>
                    ))} </> : null} */}
                    {contractLength ? (
                      <span className="tag-cu">
                        <CloseOutlined
                          style={{ cursor: "pointer", paddingRight:"4px" }}
                          onClick={() => removeContract()}
                        />
                        {contractLength}
                      </span>
                    ) : null}

                    {tags.rates[0] !== 0 || tags.rates[1] !== 200 ? (
                      <span className="tag-cu">
                        
                        <CloseOutlined
                          style={{ cursor: "pointer", paddingRight:"4px" }}
                          onClick={() => clearRates()}
                        />
                        {tags.rates[0]} - {tags.rates[1]}
                      </span>
                    ) : null}
                  </div>
                </div> 

              {searchResult.length !== 0 && (
                <div className="visi-67">
                  <SearchResultList
                    searchResult={searchResult}
                    searchHandler={searchHandler}
                    updateTokens={updateTokens}
                    prevToken={prevToken}
                    applyJob={applyJob}
                    handleSendMsg={handleSendMsg}
                    emailmessage={emailmessage}
                    setEmailmessage={setEmailmessage}
                    msgData={msgData}
                    setMsgData={setMsgData}
                  />
                </div>
              )}
              {searchResult.length !== 0 && <div className="page-33 fitl-5">

                <button className={`linkbtn ${previousTokens.length ? 'activepagination' : ''}`} onClick={prevToken}> <DoubleLeftOutlined /> Previous </button>
                <button className={`linkbtn ${counter.counter < counter.total ? 'activepagination' : ''}`} onClick={updateTokens}> Next <DoubleRightOutlined /></button>
              </div>}

            </Col>
            :null}
          </Row>
        </div>
      </div>
      { !filterShow || (width>768)?
      <Footer />
      :null}
    </React.Fragment>
  );
};

  export default protectedRoute(SearchJobs);
