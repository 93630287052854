import React from 'react'
import { Spin } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import './style.css'

const antIcon = <LoadingOutlined style={{ fontSize: 100, color: '#1f6f99' }} spin />;

const Loader = () => {
    return (
        <div className='loading-icon'>
            <Spin indicator={antIcon} />
        </div>
    )
}

export default Loader