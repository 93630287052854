export const styles = {
  // changed bg
  body: { backgroundColor: "#EFF2F6", minHeight: "calc(100vh - 48px)" },
  container: { margin: `0px auto`, maxWidth: 1200 }, //padding: `25px 0px 50px`
  edcontainer: { marginBottom: `40px` },
  algn_btn: { display: "flex", justifyContent: `flex-end`, marginTop: `15px` },
  submit_algn_btn: { display: "flex", justifyContent: `flex-start`, marginTop: `30px` },
  input: { marginBottom: 10 },
  item: { textAlign: "left" },
  p: { color: "#1f6f99" },
  btm15: { marginBottom: "15px" },
  headding: {
    color: ` #222`,
    fontWeight: `700`,
    fontSize: `15px`,
    marginBottom: `15px`,
    display: `flex`,
  },
  margin25: {margin: `25px 0`},
  success_div: { marginLeft: '16px', marginTop: '20px'}
};

export const lstyle = {
  wrapper: {},
  headding: {
    marginTop: `5px !important`,
    marginBottom: `5px !important`,
    lineHeight: ` 23px`,
    fontSize: `16px`,
    fontWeight: `700`,
    color: `#222`,
  },
  sheadding: {},
  desc: {},
  tag: {
    backgroundColor: `rgb(217,217,217)`,
    padding: `2px 7px`,
    borderRadius: `3px`,
    fontSize: `13px`,
    cursor: `pointer`,
    marginRight: `10px`,
  },
};
