import React, { useState } from 'react'
import { styles } from "../../styles";
import { Row, Col } from "antd";
import Footer from '../HomePage/Footer';
const JobDetailsSuccess = ({ setUser }) => {

    const [render, setRender] = useState(null)

    return (
        <div>
            <div className=" job-det contract-12 Postjob search-121">
                <div style={styles.container}>
                    <div style={styles.edcontainer} className="d-flex justify-content-center">
                        <Row gutter={[64, 64]} className="dh41">
                            <Col>
                                <div className="jo-ti">
                                    <p style={styles.success_div}>Your resume has been successfully submitted, please check your email for verification</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )

}
export default JobDetailsSuccess