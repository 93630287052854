import React from "react";
import {
  Slider,
  Menu,
  Dropdown,
  Button,
  Row,
  Col,
  Input,
  Checkbox,
} from "antd";
import "./style.css";
import { styles } from "../../../../styles";
import { DownOutlined } from "@ant-design/icons";
import { CloseOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

const { Search } = Input;

const JobFilters = (props) => {
  const locateState = props.locateState;
  const setLocateState = props.setLocateState;
  const handleRates = props.handleRates;
  const tags = props.tags;
  const setContractLen = props.setContractLen;
  const contractLength = props.contractLength;

  const contractMenu = (
    <Menu onClick={setContractLen}>
      <Menu.Item key="3 Months">3 Months</Menu.Item>
      <Menu.Item key="6 Months">6 Months</Menu.Item>
      <Menu.Item key="1 Year">1 Year</Menu.Item>
      <Menu.Item key="2 Years">2 Years</Menu.Item>
      <Menu.Item key="2 Years +">2 Years +</Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="filters-result">
        <Row>
          <React.Fragment>
            <Row className="gud-34">
              <Col
                lg={{ span: 24 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                style={{
                  justifyContent: `space-between`,
                  display: `flex`,
                  flexFlow: `column`,
                }}
              >
              </Col>
              <Col
                lg={{ span: 24 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
              <p className="job--title">Rates</p>
                <div className="slider-auto">
                
                  <Slider
                    range
                    onChange={handleRates}
                    min={0}
                    max={200}
                    step={5}
                    value={tags.rates}
                  />
                  <div className="range-33">
                    <span> ${tags.rates[0]}</span>
                    <span style={{color: "#074369"}}> ${tags.rates[1]}</span>
                  </div>
                </div>
              </Col>
              <Col
                lg={{ span: 24 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <p className="job--title" style={{border: "none", marginBottom: "3%"}}>Contract duration</p>
                <div className="f42-contract">
                  <Dropdown overlay={contractMenu}>
                    <a href="www"
                      className="ant-dropdown-link adt-23"
                      onClick={(e) => e.preventDefault()}
                      style= {{display: "inline-block", width: "85%", position: "relative"}}
                    >
                      {contractLength ? contractLength : "Contract Length"}
                      <div className="dropdown-icon">
                      <DownOutlined />
                      </div>
                    </a>
                  </Dropdown>
                </div>

              </Col>
              <Col span={24}>
                <Row className="cuts-12">
                  <Col span={24} style={styles.margin25}>
                    <div style={{ display: "flex", justifyContent: "space-between" }} className="sert sert-desktop">
                      <Button
                        onClick={() => {
                          props.clearFilters();
                        }}
                        type="link"
                        style={{ padding: 0, paddingLeft: "6%" }}
                      >
                        <span className="clear-filter">Clear Filters</span>
                      </Button>
                      <div
                        className="filt-btn"
                        style={{ display: "flex", justifyContent: `space-between` }}
                      >
                        
                          <Button
                            type="primary"
                            onClick={() => {
                              props.applyFilters();
                            }}
                          >
                          <span className="apply-filter"> Apply Filters </span>
                            
                          </Button>
                        
                      </div>
                    </div>


                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row className="cuts-12">
                  <Col span={24} style={styles.margin25}>
                    <div className="sert sert-mobile" style={{display: "flex", justifyContent: "flex-end", marginRight:"10%"}}>
                      
                    <span className="filt-btn-mobile">
                          <Button
                            type="primary"
                            onClick={() => {
                              props.applyFilters();
                              props.setFilterShow((prevValue)=>{
                              return props.setFilterShow(!prevValue);
                              });
                              }
                            }
                          >
                          <span className="apply-filter-mobile"> Apply Filters </span>
                          </Button>
                      </span>

                      <Button
                        onClick={() => {
                          props.clearFilters();
                        }}
                        type="link"
                        style={{ padding: 0, paddingLeft: "6%" }}
                      >
                        <span className="clear-filter-mobile">Clear Filters</span>
                      </Button>
                      
                    </div>


                  </Col>
                </Row>
              </Col>
            </Row>
          </React.Fragment>
        </Row>
      </div>
    </>
  );
};




const InputLocation= (props)=> {
  const addLocation = props.addLocation;
  const searchLocation = props.searchLocation;
  const searchedLocationText = props.searchedLocationText;
  return( 
    <div className="df-4" >
        {" "}
        <p className="job--title">Location</p>
        <Search
        style={{padding: "10px"}}
          placeholder="Location"
          onSearch={(value) => {
            addLocation(value);
          }}
          value={searchedLocationText}
          onChange={(e) => searchLocation(e)}
          id="autolocation"
        />
    </div>
  );
}

console.log(InputLocation);


export default JobFilters;

export {InputLocation};