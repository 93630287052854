import React, { useState, useEffect } from "react";
import { Row, Col, Button, Checkbox } from "antd";
import { styles } from "../../styles";
import { Breadcrumb } from "antd";
import Light from "../../assets/702814.svg";
import { API, graphqlOperation, Auth, Storage } from "aws-amplify";
import { getConsultant } from "../../graphql/queries";
import "./style.css";
import history from "../../config/history"
import Footer from '../HomePage/Footer';
import useDocumentTitle from '../../config/useDocumentTitle'

const CandidateProfile = () => {
  useDocumentTitle("Profile")
  const [candidateData, setCandidateData] = useState({
    name: "",
    email: "",
    phone: "",
    desiredPerHour: "",
    contractEndDate: "",
    lastProjectRandR: "",
    skills: [],
    currentRate: 0,
    owningCompany: "",
    willingRelocate: false,
    willingTravelUs: false,
    cvFileName: "notset",
    stringSkills: "",
    workAuthorization: ""
  });
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    getCandidateData();
  }, []);

  const loadSessionData = async () => {
    const userData = await Auth.currentAuthenticatedUser({
      bypassCache: false,
    });
    return userData.attributes["email"];
  };

  const getCandidateData = async () => {
    const email = await loadSessionData();
    const candidateData = await API.graphql(
      graphqlOperation(getConsultant, { id: email })
    );
    if (candidateData.data.getConsultant) {
      let data = candidateData.data.getConsultant;
      // const fileData = await Storage.get(data.cvFileName, {
      //   contentType: "application/vnd.openxmlformats-officedocument.wordprocessing",
      // });
      setCandidateData({ ...data });
      setLocations(data.locations);
    }
  };

  return (
    <React.Fragment>
      <div className="contract-12 Postjob">
        <div style={styles.container}>
          <div style={styles.edcontainer}>
            <div className="contractor">
              <Breadcrumb className="set-space" separator=">">
                <Breadcrumb.Item className="pointer" onClick={() => history.push('/')}> Home</Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => history.push('/profile')}> <span className="color-dark-blue">View Profile</span></Breadcrumb.Item>
              </Breadcrumb>
              <h2>My Profile</h2>
            </div>
            <Row gutter={[64, 64]} className="dh41">
              <Col
                lg={{ span: 16 }}
                md={{ span: 16 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="candidate-form">
                  <div className="pace em-23">
                    <label className="title-name ">
                      Email
                    </label>
                    <span>{candidateData.email}</span>
                  </div>
                  <div className="pace em-23">
                    <label className="title-name ">
                      Name
                    </label>
                    <span>{candidateData.name}</span>
                  </div>



                  <div className="pace em-23">
                    <label className="title-name ">
                      Phone
                    </label>
                    <span>{candidateData.phone}</span>
                  </div>
                  <div className="pace em-23">
                    <label className="title-name ">
                      Desired $ Rate / Hr
                    </label>
                    <span>${candidateData.desiredPerHour} / Hr</span>
                  </div>
                  <div className="pace em-23">
                    <label className="title-name ">
                      Current Contract End Date:
                    </label>
                    <span>{candidateData.contractEndDate}</span>
                  </div>

                  <div className="pace em-23">
                    <label className="title-name ">Skills</label>
                    <div className="my-input">
                      <div className="profile-skill">
                        {(candidateData.skills || []).map((skill, i) => (
                          <span className="tag-cu" key={i}>{skill}</span>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="pace em-23">
                    <label className="title-name ">Location</label>
                    {locations.map((location, i) => (
                      <span key={i}>{location}</span>
                    ))}
                  </div>
                  <div className="pre-45 pace">
                    <label className="title-name">Location Preferences:</label>

                    <div className="will">
                      <Checkbox
                        id="willingRelocate"
                        checked={candidateData.willingRelocate}
                      >
                        Willing to relocate
                      </Checkbox>
                      <Checkbox
                        id="willingTravelUs"
                        checked={candidateData.willingTravelUs}
                      >
                        Willing to travel within United States
                      </Checkbox>


                    </div>
                  </div>

                  {/* <div className=" resume-33 pace ">
                    <label className="resume title-name">CV</label>
                    <a href={candidateData.fileData} target="_blank">
                      Link
                    </a>
                  </div> */}

                  <div className="w-12 pace em-23 ">
                    <label className="title-name">
                      {" "}
                      Work Authorization
                    </label>
                    <span>{candidateData.workAuthorization}</span>
                  </div>

                  <div className="pace em-23">
                    <label className="title-name">
                      Staffing Company
                    </label>
                    <span>{candidateData.owningCompany}</span>
                  </div>

                  <div className="pre-45 pace">
                    <label className="title-name">Communication Preferences:</label>

                    <div className="will">
                      {/* <Checkbox
                        id="willingRelocate"
                        checked={candidateData.willingRelocate}
                      >
                        Willing to relocate
                      </Checkbox>
                      <Checkbox
                        id="willingTravelUs"
                        checked={candidateData.willingTravelUs}
                      >
                        Willing to travel within United States
                      </Checkbox> */}

                      <Checkbox
                        id="allowSms"
                        checked={candidateData.allowSms}
                      >
                        Allow recruiters to SMS me
                      </Checkbox>

                      <Checkbox
                        id="allowEmail"
                        checked={candidateData.allowEmail}
                      >
                        Allow recruiters to Email me
                      </Checkbox>
                    </div>
                  </div>

                  <div style={styles.algn_btn}>
                    <Button
                      className="editbtn"
                      type="primary"
                      onClick={() => history.push("/editprofile")}
                    >
                      Edit
                    </Button>
                  </div>
                </div>
              </Col>

              <Col
                lg={{ span: 8 }}
                md={{ span: 8 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="post-description">
                  <div className="img-de">
                    <img src={Light} alt="tip-icon" />
                    <h2>Here's a tip!</h2>
                  </div>
                  <p>
                    Update your skills to ones you want to get hired for. Your last
                    few projects should reflect the work in these skills.
                    Be realistic with your rate. You can have more than one preferred
                    location where you are open to work.{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          {/* <List
            loading={state.loading}
            dataSource={state.consultants}
            renderItem={renderItem}
          /> */}
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default CandidateProfile;
