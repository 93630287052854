/* src/Router.js */
import React, { useState, useEffect } from "react";
import { Router, Switch, Route } from "react-router-dom";
import ReactGA from 'react-ga';
import Header from '../components/HomePage/header/index'
import history from "../config/history";
import SearchJob from "../components/SearchJob/Search";
import CandidateProfile from "../components/CandidateProfile";
import EditProfile from "../components/EditProfile";
import JobList from '../components/Joblist'
import MyJobs from "../components/MyJobs";
import HomePage from '../components/HomePage'
import Nav from '../components/Nav'
import JobDetails from "../components/JobDetails";
import Unsubscribe from "../components/Unsubscribe";
import JobDetailsSuccess from "../components/JobDetailsSuccess"
import EmailVerify from "../components/EmailVerify"


const RouterFile = ({ profileSet, setUser, authState, user, setAuthUser }) => {
  const [current, setCurrent] = useState("home");

  useEffect(() => {
    setRoute();
    console.log('authState', authState)
    window.addEventListener("hashchange", setRoute);
    return () => window.removeEventListener("hashchange", setRoute);
  }, []);

  const setRoute = () => {
    const location = window.location.href.split("/");
    const pathname = location[location.length - 1];
    setCurrent(pathname ? pathname : "home");
  };
  return (
    <Router history={history}>
      {authState === "signIn" && authState !== null ? <Nav current={current} profileSet={profileSet} setUser={setUser} authState={authState} user={user} /> : authState !== null ? <Header setUser={setUser} /> : <Header setUser={setUser} />}
      {console.log('authState', authState)}
      <Switch>
        <Route exact path="/subscription-update" component={Unsubscribe} />
        <Route exact path="/jobs" component={JobList} />
        <Route exact path="/" render={() => <HomePage setUser={setUser} authState={authState} user={user} />} />
        {authState === "signIn" ? <>

          <Route exact path="/searchjob" component={SearchJob} />
          <Route exact path="/profile" component={CandidateProfile} />
          <Route exact path="/joblist" component={MyJobs} />
          {/* <Route exact path="/skeleton" component={Skeleton} /> */}
          <Route exact path="/editprofile" render={() => <EditProfile />} />
          <Route exact path="/jobdetails-success" render={() => <JobDetailsSuccess />} />
          <Route exact path="/email-verify" render={() => <EmailVerify setUser={setUser}/>} />
          <Route exact path="/jobdetails" render={() => <JobDetails />} /></> : 
          <>
            <Route exact path="/jobdetails-success" render={() => <JobDetailsSuccess setUser={setUser} />} />
            <Route exact path="/jobdetails" render={() => <JobDetails setAuthUser={setAuthUser}/>} />
            <Route exact path="/email-verify" render={() => <EmailVerify setUser={setUser} setAuthUser={setAuthUser}/>} />
          </>}

      </Switch>
    </Router>
  );
};

export default RouterFile;
