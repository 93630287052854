import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import queryString from 'query-string'
import ReactHtmlParser from 'react-html-parser';

const JobInfo = ({ jobData }) => {

    return (
        <React.Fragment>
        <div className="job-info">

            <div className="space">
                <span className="title-h cus-2">Location</span>

                <div className="location">
                    {jobData.remote && <span>Remote (USA), </span>}{jobData.locations.map((location, i) => <span key={i}>{location}</span>)}
                </div>
            </div>
            
            <div className="space">
                <span className="title-h">Job Description</span>
                {ReactHtmlParser(jobData.jobDescription)}

            </div>
            <div className="space">
                <span className="title-h cus-2">Skills</span>

                <div className="skills">
                    {/* <span>skill</span> */}
                    {jobData.skills.map((skill, i) => <span key={i}>{skill}</span>)}
                </div>


                <div className="c-box">

                </div>
            </div>

            <div className="space">
                <div className="mngjobrate">
                    <span className="title-h cus-2">Rate</span>
                    <p>{jobData.rate}</p>

                </div>
            </div>
            <div className="space">
                <span className="title-h cus-2">Contract Length:</span>
                <p>{jobData.contractLength}</p>
            </div>
        </div>
        </React.Fragment>
    )
}
export default JobInfo